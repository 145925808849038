// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_acknowledgeAnnouncement = (path: TSpecPathAcknowledgeAnnouncement) => {
  let res = "/announcements/:id/acknowledge";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_acknowledgeAnnouncement = RequestHandler<TSpecPathAcknowledgeAnnouncement, any, TSpecBodyAcknowledgeAnnouncement, TSpecQueryAcknowledgeAnnouncement, {}>;

export interface TSpecPathAcknowledgeAnnouncement { id: string; }

export interface TSpecBodyAcknowledgeAnnouncement {}

export interface TSpecQueryAcknowledgeAnnouncement {
  AppVersion?: string;
  AppUser?: string;
}
