<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ compensationField ? 'Update Column' : 'Create Column' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Column Title"
          formControlName="name"
          labelPlacement="floating"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="compensationField"
        color="danger"
        expand="block"
        fill="outline"
        appHaptics
        (click)="delete()"
        class="ion-no-margin"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
        (click)="save()"
        class="ion-no-margin"
      >
        {{ compensationField ? 'Update' : 'Create' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
