// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTaskFolders = () => "/task-folders";

export type TSpecHandler_getTaskFolders = RequestHandler<TSpecPathGetTaskFolders, any, TSpecBodyGetTaskFolders, TSpecQueryGetTaskFolders, {}>;

export interface TSpecPathGetTaskFolders {  }

export interface TSpecBodyGetTaskFolders {}

export interface TSpecQueryGetTaskFolders {
  AppVersion?: string;
  AppUser?: string;
}
