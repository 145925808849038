// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getGlobalSettings = () => "/global-settings";

export type TSpecHandler_getGlobalSettings = RequestHandler<TSpecPathGetGlobalSettings, any, TSpecBodyGetGlobalSettings, TSpecQueryGetGlobalSettings, {}>;

export interface TSpecPathGetGlobalSettings {  }

export interface TSpecBodyGetGlobalSettings {}

export interface TSpecQueryGetGlobalSettings {
  AppVersion?: string;
  AppUser?: string;
}
