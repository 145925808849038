// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_stopTaskPerformance = (path: TSpecPathStopTaskPerformance) => {
  let res = "/task-performance/:id/stop";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_stopTaskPerformance = RequestHandler<TSpecPathStopTaskPerformance, any, TSpecBodyStopTaskPerformance, TSpecQueryStopTaskPerformance, {}>;

export interface TSpecPathStopTaskPerformance { id: string; }

export interface TSpecBodyStopTaskPerformance {
  taskAppearTime: string;
  quantity?: number;
  completeTime?: string;
}

export interface TSpecQueryStopTaskPerformance {
  AppVersion?: string;
  AppUser?: string;
}
