import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { DirectivesModule } from '@app/modules/directives.module';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PerformanceItemModule } from '@app/modules/performance-item/performance-item.module';
import { ApprovalModalModule } from '@app/modules/approval-modal/approval-modal.module';
import { AnnouncementsComponent } from './announcements.component';
import { AnnouncementViewComponent } from './announcement-view/announcement-view.component';
import { AnnouncementAddComponent } from './announcement-add/announcement-add.component';
import { AnnouncementItemModule } from '@app/modules/announcement-item/announcement-item.module';
import { AnnouncementRespondModalModule } from '@app/modals/announcement-respond-modal/announcement-respond-modal.module';
import { AnnouncementThreadModule } from '@app/modules/announcement-thread/announcement-thread.module';
import { ReadEyeComponent } from '@app/modules/read-eye/read-eye.component';
import { PullToSearchModule } from '@app/modules/pull-to-search/pull-to-search.module';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { AcknowledgmentsModalModule } from '@app/modals/acknowledgments-modal/acknowledgments-modal.module';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Announcements',
      rootComponent: AnnouncementsComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    IonicModule,
    RouterModule.forChild(ROUTES),
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    EmptyStubModule,
    DirectivesModule,
    PerformanceItemModule,
    ApprovalModalModule,
    AnnouncementItemModule,
    AnnouncementRespondModalModule,
    AnnouncementThreadModule,
    ReadEyeComponent,
    PullToSearchModule,
    WysiwygEditorComponent,
    FormsModule,
    ReactiveFormsModule,
    FullHeightContainerModule,
    ListPostLineModule,
    AcknowledgmentsModalModule,
    ContentTitleModule
  ],
  declarations: [
    AnnouncementsComponent,
    AnnouncementViewComponent,
    AnnouncementAddComponent
  ]
})
export class AnnouncementsModule {}
