<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
      </ion-button>
    </ion-buttons>
    <ion-title>Tasks Completed</ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <div class="full-name">{{ fullName }}</div>
    <ion-buttons *ngIf="range">
      <ion-button
        color="primary"
        class="toolbar-range-button"
        (click)="onRangeClick()"
        appHaptics
      >
        <ng-container *ngIf="isOneDayRange; else rangeTwoDays">
          {{ range.start | date : 'MMM d, y' }}
        </ng-container>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <app-performance-item
      *ngFor="let item of taskPerformances; trackBy: listItemTrackBy"
      [performance]="item"
      [user]="user"
      (click)="isAdminOrManager && onItemClick(item)"
      [disabled]="!isAdminOrManager"
      appHaptics
      [disableHaptics]="!isAdminOrManager"
    />

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="!(taskPerformances.length === 0 || isLoading || !hasNextPage)"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content />
    </ion-infinite-scroll>

    <ng-container *ngIf="taskPerformances.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList" />
    </ng-container>
  </app-width-restricted-container>

  <ion-fab
    [attr.data-hidden]="!isAdminOrManager"
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    class="main-fab"
  >
    <ion-fab-button color="dark" (click)="selectTask()" appHaptics>
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Task Performances for This Date Range" />
</ng-template>

<ng-template #rangeTwoDays>
  {{ range.start | date : 'MMM d, y' }} -
  {{ range.end | date : 'MMM d, y' }}
</ng-template>
