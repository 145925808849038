// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getStatusForAllTags = () => "/tags/status";

export type TSpecHandler_getStatusForAllTags = RequestHandler<TSpecPathGetStatusForAllTags, any, TSpecBodyGetStatusForAllTags, TSpecQueryGetStatusForAllTags, {}>;

export interface TSpecPathGetStatusForAllTags {  }

export interface TSpecBodyGetStatusForAllTags {}

export interface TSpecQueryGetStatusForAllTags {
  ids: string[];
  AppVersion?: string;
  AppUser?: string;
}
