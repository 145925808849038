import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { BehaviorModel } from '@app/core/model/behavior.model';
import fastDeepEqual from 'fast-deep-equal';
import { AuthenticationService } from '@app/core';
import { isMobile } from '@app/utils/is-mobile';
import {
  showBehaviorSelectorModal,
  showUserSelectorModal
} from '@app/utils/modal';

export interface IEditRecognitionModalResult {
  recognition: {
    userId: string;
    behaviorId: string;
    description: string;
    isPrivate: boolean;
  };
  behavior: BehaviorModel;
}

export interface IEditRecognitionModalInitialData {
  userId: ObjectId;
  behaviorId: ObjectId;
  description: string;
  isPrivate: boolean;
}

export interface IEditRecognitionModalProps {
  initialData?: IEditRecognitionModalInitialData;
  createMode?: boolean;
}

@Component({
  selector: 'app-edit-recognition-modal',
  templateUrl: './edit-recognition-modal.component.html',
  styleUrls: ['./edit-recognition-modal.component.scss']
})
export class EditRecognitionModalComponent implements OnInit {
  @Input()
  public readonly initialData?: IEditRecognitionModalInitialData;

  @Input()
  public readonly createMode?: boolean = false;

  protected form = new FormGroup({
    userId: new FormControl<ObjectId>(null, Validators.required),
    behaviorId: new FormControl<ObjectId>(null, Validators.required),
    description: new FormControl<string>('', Validators.required),
    isPrivate: new FormControl<boolean>(false)
  });
  protected allUsers: UserModel[] = [];
  protected isMobile = isMobile();
  protected lastSelectedBehavior: BehaviorModel = null;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService,
    private readonly _authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    if (this.initialData) {
      this.form.reset({
        userId: this.initialData.userId,
        behaviorId: this.initialData.behaviorId,
        description: this.initialData.description,
        isPrivate: this.initialData.isPrivate
      });
    }

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users.filter(
        (user) =>
          user._id.toString() !==
          this._authenticationService.user._id.toString()
      );
    });
  }

  protected get canSave() {
    if (this.createMode) {
      return (
        this.form.get('userId').value &&
        this.form.get('behaviorId').value &&
        this.form.get('description').value
      );
    } else {
      return (
        this.form.get('userId').value &&
        this.form.get('behaviorId').value &&
        this.form.get('description').value &&
        !fastDeepEqual(this.initialData, {
          userId: this.form.get('userId').value,
          behaviorId: this.form.get('behaviorId').value,
          description: this.form.get('description').value,
          isPrivate: this.form.get('isPrivate').value
        })
      );
    }
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.form.get('userId').value];
  }

  protected get currentUser(): UserModel | null {
    const user = this.allUsers.find(
      (user) => user._id === this.form.get('userId').value
    );
    return user;
  }

  protected get privateInfo() {
    if (this.form.get('isPrivate').value) {
      return {
        description: this.currentUser
          ? `Only visible to you, ${this.currentUser.firstName}, and administrators.`
          : 'Only visible to you and administrators.'
      };
    } else {
      return {
        description: 'Visible to everyone in your company.'
      };
    }
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'edit-recognition-modal');
  }

  protected onSaveClick(): void {
    const result: IEditRecognitionModalResult = {
      recognition: {
        userId: this.form.get('userId').value,
        behaviorId: this.form.get('behaviorId').value,
        description: this.form.get('description').value,
        isPrivate: this.form.get('isPrivate').value
      },
      behavior: this.lastSelectedBehavior
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-recognition-modal');
  }

  protected async selectUser() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.selectedUserIds,
        focusSearchbar: true,
        title: 'Select User'
      },
      this._modalCtrl,
      { parentModalName: 'edit-recognition-modal' }
    );

    if (success) {
      this.form.controls.userId.setValue(selectedUsers[0]);
    }
  }

  protected async selectBehavior() {
    const { success, selectedBehavior } = await showBehaviorSelectorModal(
      {
        focusSearchbar: true
      },
      this._modalCtrl,
      { parentModalName: 'edit-recognition-modal' }
    );

    if (success) {
      this.form.controls.behaviorId.setValue(selectedBehavior._id);
      this.lastSelectedBehavior = selectedBehavior;
    }
  }
}
