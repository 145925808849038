// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTaskById = (path: TSpecPathGetTaskById) => {
  let res = "/tasks/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTaskById = RequestHandler<TSpecPathGetTaskById, any, TSpecBodyGetTaskById, TSpecQueryGetTaskById, {}>;

export interface TSpecPathGetTaskById { id: string; }

export interface TSpecBodyGetTaskById {}

export interface TSpecQueryGetTaskById {
  AppVersion?: string;
  AppUser?: string;
}
