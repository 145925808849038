import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AnnouncementModel } from '@app/core/model/announcement.model';

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementItemComponent {
  @Input()
  public readonly announcement: AnnouncementModel;

  @Input()
  public readonly animationOrder?: number = -1;

  @Input()
  public readonly showViewCount?: boolean = false;

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @HostBinding('class.unacknowledged')
  public get unacknowledgedClass() {
    return this.announcement.unacknowledged;
  }

  public constructor(private readonly _sanitizer: DomSanitizer) {}

  protected get acknowledged() {
    return this.announcement.recipients.filter((a) => a.acknowledged);
  }
}
