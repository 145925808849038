// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteBackdoorEula = (path: TSpecPathDeleteBackdoorEula) => {
  let res = "/backdoor/eula/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteBackdoorEula = RequestHandler<TSpecPathDeleteBackdoorEula, any, TSpecBodyDeleteBackdoorEula, TSpecQueryDeleteBackdoorEula, {}>;

export interface TSpecPathDeleteBackdoorEula { id: string; }

export interface TSpecBodyDeleteBackdoorEula {}

export interface TSpecQueryDeleteBackdoorEula {
  AppVersion?: string;
  AppUser?: string;
}
