// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateDepartment = (path: TSpecPathUpdateDepartment) => {
  let res = "/departments/:departmentId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateDepartment = RequestHandler<TSpecPathUpdateDepartment, any, TSpecBodyUpdateDepartment, TSpecQueryUpdateDepartment, {}>;

export interface TSpecPathUpdateDepartment { departmentId: string; }

export interface TSpecBodyUpdateDepartment {
  name: string;
}

export interface TSpecQueryUpdateDepartment {
  AppVersion?: string;
  AppUser?: string;
}
