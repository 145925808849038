// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchTasks = () => "/tasks";

export type TSpecHandler_patchTasks = RequestHandler<TSpecPathPatchTasks, any, TSpecBodyPatchTasks, TSpecQueryPatchTasks, {}>;

export interface TSpecPathPatchTasks {  }

export interface TSpecBodyPatchTasks {
  userId: string;
  tasks: {
    _id: string;
    isNotificationUponCompletion?: boolean;
    skillMatrixStatus?: ESkillMatrixStatus;
  }[];
}

export enum ESkillMatrixStatus {
  CANNOT_PERFORM = "CANNOT_PERFORM",
  CAN_PERFORM_WITH_SUPERVISON = "CAN_PERFORM_WITH_SUPERVISON",
  CAN_PERFORM_WITH_REVIEW = "CAN_PERFORM_WITH_REVIEW",
  CAN_PERFORM_WITHOUT_REVIEW = "CAN_PERFORM_WITHOUT_REVIEW",
  CAN_PERFORM_AND_TRAIN = "CAN_PERFORM_AND_TRAIN",
  MUST_REVIEW_NEW_VERSION = "MUST_REVIEW_NEW_VERSION",
  REVOKED = "REVOKED"
}

export interface TSpecQueryPatchTasks {
  AppVersion?: string;
  AppUser?: string;
}
