import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonNav, LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from '@app/core/service/toast.service';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import { Observable } from 'rxjs';
import { ITenant } from '@backend/models/types/tenant';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import { IBackdoorEula } from '@backend/models/types/backdoor-eula';
import { BackdoorEulaModalComponent } from '@app/modals/backdoor-eula-modal/backdoor-eula-modal.component';
import { showModal } from '@app/utils/modal';

@Component({
  selector: 'app-backdoor-eula-add',
  templateUrl: './backdoor-eula-add.component.html',
  styleUrls: ['./backdoor-eula-add.component.scss']
})
export class BackdoorEulaAddComponent implements OnInit {
  @Input()
  public readonly backdoorEula: MongoStoredObject<IBackdoorEula> = null;

  protected mainFormGroup: FormGroup = new FormGroup({
    title: new FormControl<string>('', Validators.required),
    iframeCode: new FormControl<string>('', Validators.required),
    showToAllTenants: new FormControl<boolean>(true),
    tenantsToShow: new FormControl<ObjectId[]>([])
  });
  protected tenantList$: Observable<MongoStoredObject<ITenant>[]> =
    this._tenantService.tenants$;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _toastService: ToastService,
    private readonly _backdoorApiService: BackdoorApiService,
    private readonly _tenantService: TenantListService,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController
  ) {}

  public ngOnInit(): void {
    if (this.backdoorEula) {
      this.mainFormGroup.reset(this.backdoorEula);
    }
  }

  protected get showToAllTenants() {
    return this.mainFormGroup.controls.showToAllTenants.value;
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    if (this.backdoorEula) {
      this._backdoorApiService
        .updateBackdoorEula({
          path: { id: this.backdoorEula._id },
          body: this.mainFormGroup.value
        })
        .subscribe(() => {
          this._toastService.presentToast('Backdoor Message Updated');
          this._ionNav.pop();
        });
    } else {
      this._backdoorApiService
        .addBackdoorEula({
          body: this.mainFormGroup.value
        })
        .subscribe(() => {
          this._toastService.presentToast('Backdoor Message Added');
          this._ionNav.pop();
        });
    }
  }

  protected async preview() {
    showModal(
      {
        component: BackdoorEulaModalComponent,
        props: {
          backdoorEula: this.mainFormGroup.value,
          preview: true
        }
      },
      this._modalCtrl,
      {
        canDismiss: async (_, role) => role === 'cancel-preview',
        backdropDismiss: false
      }
    );
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }
}
