// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUnacknowledgedCount = () => "/announcements/unacknowledged-count";

export type TSpecHandler_getUnacknowledgedCount = RequestHandler<TSpecPathGetUnacknowledgedCount, any, TSpecBodyGetUnacknowledgedCount, TSpecQueryGetUnacknowledgedCount, {}>;

export interface TSpecPathGetUnacknowledgedCount {  }

export interface TSpecBodyGetUnacknowledgedCount {}

export interface TSpecQueryGetUnacknowledgedCount {
  AppVersion?: string;
  AppUser?: string;
}
