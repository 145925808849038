// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPointsForPeriod = () => "/user/points-for-period";

export type TSpecHandler_getPointsForPeriod = RequestHandler<TSpecPathGetPointsForPeriod, any, TSpecBodyGetPointsForPeriod, TSpecQueryGetPointsForPeriod, {}>;

export interface TSpecPathGetPointsForPeriod {  }

export interface TSpecBodyGetPointsForPeriod {}

export interface TSpecQueryGetPointsForPeriod {
  direction: EAggregationDirection;
  from: string;
  to: string;
  AppVersion?: string;
  AppUser?: string;
}

export enum EAggregationDirection {
  sender = "sender",
  recipient = "recipient"
}
