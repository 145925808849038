<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ libraryDoc ? 'Update Document' : 'Add Document' }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true" [formGroup]="mainFormGroup">
      <ion-item>
        <ion-input
          label="Title"
          formControlName="title"
          labelPlacement="floating"
          [spellcheck]="true"
        />
      </ion-item>
    </ion-list>

    <app-wysiwyg-editor
      [(value)]="body"
      [disableAttachments]="disableAttachments"
    />

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="libraryDoc"
        color="danger"
        expand="block"
        fill="outline"
        (click)="onDeleteClick()"
        appHaptics
        class="ion-no-margin"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="mainFormGroup.invalid || !body"
        [disableHaptics]="mainFormGroup.invalid || !body"
        appHaptics
        (click)="onFormSubmit()"
        class="ion-no-margin"
      >
        {{ libraryDoc ? 'Save Revision' : 'Submit' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
