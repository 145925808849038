<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="libraryDoc" class="ion-text-capitalize">
      {{ libraryDoc.title.toLowerCase() }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin">
      <ion-button
        color="primary"
        (click)="onEditButtonClick($event)"
        appHaptics
      >
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <app-content-title>
      {{ libraryDoc.title.toLowerCase() }}
    </app-content-title>

    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <app-wysiwyg-editor [(value)]="body" mode="view" />

    <div
      bottom
      class="modal-actions"
      *ngIf="!isLoading && !mySign; else signedText"
    >
      <ion-button
        color="dark"
        expand="block"
        (click)="signLibraryDoc()"
        appHaptics
        class="ion-no-margin"
      >
        I Understand and Accept
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ng-template #signedText>
  <ion-col size="12">
    <div class="signed-text">
      Acknowledged and Accepted by
      <div *ngIf="isAdmin; else mySignedText">
        <div *ngFor="let user of signedUsers">
          {{ user.user.firstName }} {{ user.user.lastName }} on
          {{ user.date | date : 'M/d/yy h:mm a' }}
        </div>
      </div>
    </div>
  </ion-col>
</ng-template>

<ng-template #mySignedText>
  <div>
    {{ mySign.user.firstName }} {{ mySign.user.lastName }} on
    {{ mySign.date | date : 'M/d/yy h:mm a' }}
  </div>
</ng-template>

<ion-popover
  [isOpen]="isPopoverOpen"
  [dismissOnSelect]="true"
  (didDismiss)="onPopoverDismiss()"
  #popover
>
  <ng-template>
    <ion-list lines="none">
      <ion-item button (click)="editDoc()" appHaptics>
        <ion-label>Edit Document</ion-label>
      </ion-item>
      <ion-item button (click)="requestDocToSign()" appHaptics>
        <ion-label>Request Users to Read And Sign</ion-label>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
