import { NgModule } from '@angular/core';
import { WysiwygModalComponent } from './wysiwyg-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    WysiwygEditorComponent,
    FullHeightContainerModule
  ],
  exports: [WysiwygModalComponent],
  declarations: [WysiwygModalComponent]
})
export class WysiwygModalModule {}
