<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title class="ion-text-capitalize">
      {{ entity.name }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Points"
          labelPlacement="floating"
          pattern="[1-9][0-9]*"
          formControlName="points"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
          placeholder="0"
        />
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        (click)="onSaveClick()"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
