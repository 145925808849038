<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Tenants</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <app-width-restricted-container
    *ngIf="tenantList$ | async as tenantList"
    class="container-with-fab"
  >
    <div
      *ngIf="!tenantList || tenantList.length === 0; else tenantListTemplate"
      class="empty-list"
    >
      <div>
        Press <span class="add-button-name">+</span> button to create a tenant
      </div>
    </div>

    <ng-template #tenantListTemplate>
      <ion-card
        *ngFor="let tenant of tenantList; trackBy: itemTrackBy"
        class="tenant-card"
      >
        <ion-card-header>
          <ion-card-title
            [class.muted]="tenant.isDeleted"
            class="tenant-card-title"
          >
            {{ tenant.name }}
            <ion-chip
              class="ion-no-margin"
              color="warning"
              *ngIf="currentTenantId === tenant._id"
            >
              Current
            </ion-chip>
          </ion-card-title>
        </ion-card-header>
        <ion-accordion-group>
          <ion-accordion value="first">
            <ion-item slot="header" appHaptics>
              <ion-label class="properties">Properties</ion-label>
            </ion-item>
            <div slot="content">
              <ion-item>
                <ion-input
                  label="Tenant Name"
                  label-placement="floating"
                  [value]="tenant.name"
                  [debounce]="700"
                  (ionInput)="onTenantNameInput($any($event), tenant)"
                  autocapitalize="on"
                  [spellcheck]="true"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-toggle
                  [checked]="!tenant.isDeleted"
                  (ionChange)="onEnabledChange($any($event), tenant)"
                  appHaptics
                >
                  Enabled
                </ion-toggle>
              </ion-item>
              <ion-item button (click)="onUsersClick(tenant)" appHaptics>
                <ion-label>Users</ion-label>
              </ion-item>
              <ion-item
                button
                (click)="onTasksClick(tenant, tenantList)"
                appHaptics
              >
                <ion-label>Tasks</ion-label>
              </ion-item>
              <ion-item button (click)="onIntegrationsClick(tenant)" appHaptics>
                <ion-label>Integrations</ion-label>
              </ion-item>
              <ion-item
                button
                (click)="onFeaturesClick(tenant)"
                appHaptics
                lines="none"
              >
                <ion-label>Features</ion-label>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-card>
    </ng-template>
  </app-width-restricted-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onFabClick()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
