// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateTagStatus = (path: TSpecPathUpdateTagStatus) => {
  let res = "/tags/:tagId/status";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateTagStatus = RequestHandler<TSpecPathUpdateTagStatus, any, TSpecBodyUpdateTagStatus, TSpecQueryUpdateTagStatus, {}>;

export interface TSpecPathUpdateTagStatus { tagId: string; }

export interface TSpecBodyUpdateTagStatus {
  status: string;
}

export interface TSpecQueryUpdateTagStatus {
  AppVersion?: string;
  AppUser?: string;
}
