import {
  AfterViewInit,
  Component,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ModalClass } from '@app/core/class/modal.class';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorEula } from '@backend/models/types/backdoor-eula';
import { IonLoading, ModalController } from '@ionic/angular';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-backdoor-eula-modal',
  templateUrl: './backdoor-eula-modal.component.html',
  styleUrls: ['./backdoor-eula-modal.component.scss']
})
export class BackdoorEulaModalComponent
  extends ModalClass
  implements AfterViewInit
{
  @Input()
  public backdoorEula: MongoStoredObject<IBackdoorEula>;

  @Input()
  public preview: boolean;

  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _renderer: Renderer2,
    private readonly _backdoorApiService: BackdoorApiService
  ) {
    super();
  }

  public ngAfterViewInit() {
    const iframe = this._renderer.selectRootElement('iframe', true);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(this.backdoorEula.iframeCode);
      iframeDoc.close();
    }
  }

  protected dismiss() {
    this._modalCtrl.dismiss(
      {},
      this.preview ? 'cancel-preview' : 'cancel',
      this._modalName
    );
  }

  protected accept() {
    if (this.preview) {
      this.dismiss();
    } else {
      this.loading.present();

      Promise.all([Device.getId(), Device.getInfo()]).then(
        ([deviceId, deviceInfo]) => {
          this._backdoorApiService
            .acceptEula({
              path: { backdoorEulaId: this.backdoorEula._id },
              body: {
                deviceId: deviceId.identifier,
                deviceModel: deviceInfo.model,
                deviceOperatingSystem: deviceInfo.operatingSystem,
                deviceOsVersion: deviceInfo.osVersion,
                deviceWebViewVersion: deviceInfo.webViewVersion,
                devicePlatform: deviceInfo.platform
              }
            })
            .subscribe(() => {
              this.loading.dismiss();
              this._modalCtrl.dismiss({}, 'confirm', this._modalName);
            });
        }
      );
    }
  }
}
