// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getInstantFeedbacks = () => "/instant-feedback";

export type TSpecHandler_getInstantFeedbacks = RequestHandler<TSpecPathGetInstantFeedbacks, any, TSpecBodyGetInstantFeedbacks, TSpecQueryGetInstantFeedbacks, {}>;

export interface TSpecPathGetInstantFeedbacks {  }

export interface TSpecBodyGetInstantFeedbacks {}

export interface TSpecQueryGetInstantFeedbacks {
  page?: string;
  limit?: string;
  AppVersion?: string;
  AppUser?: string;
}
