// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPublicSettings = () => "/integrations/public-settings";

export type TSpecHandler_getPublicSettings = RequestHandler<TSpecPathGetPublicSettings, any, TSpecBodyGetPublicSettings, TSpecQueryGetPublicSettings, {}>;

export interface TSpecPathGetPublicSettings {  }

export interface TSpecBodyGetPublicSettings {}

export interface TSpecQueryGetPublicSettings {
  provider: EIntegrationProvider;
  AppVersion?: string;
  AppUser?: string;
}

export enum EIntegrationProvider {
  AIRTABLE = "AIRTABLE",
  GOHIGHLEVEL = "GOHIGHLEVEL"
}
