// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPsychologicalSurveyBehaviors = (path: TSpecPathGetPsychologicalSurveyBehaviors) => {
  let res = "/psychological-survey/:surveyId/behaviors";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getPsychologicalSurveyBehaviors = RequestHandler<TSpecPathGetPsychologicalSurveyBehaviors, any, TSpecBodyGetPsychologicalSurveyBehaviors, TSpecQueryGetPsychologicalSurveyBehaviors, {}>;

export interface TSpecPathGetPsychologicalSurveyBehaviors { surveyId: string; }

export interface TSpecBodyGetPsychologicalSurveyBehaviors {}

export interface TSpecQueryGetPsychologicalSurveyBehaviors {
  AppVersion?: string;
  AppUser?: string;
}
