<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title> Sign Up </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container maxWidth="modal">
    <app-content-title
      subtitle="Complete Sign Up"
      [showLogo]="true"
      align="center"
    />

    <ion-list [inset]="true" [formGroup]="mainFormGroup">
      <ion-item>
        <ion-input
          appAutofill
          type="text"
          formControlName="firstName"
          placeholder="First Name"
          name="firstName"
        />
      </ion-item>
      <ion-item [lines]="noPassword ? 'none' : 'full'">
        <ion-input
          appAutofill
          type="text"
          formControlName="lastName"
          placeholder="Last Name"
          name="lastName"
        />
      </ion-item>
      <ion-item [attr.data-hidden]="noPassword">
        <ion-input
          appAutofill
          type="password"
          formControlName="password"
          placeholder="Password"
          name="password"
        />
      </ion-item>
      <ion-item [attr.data-hidden]="noPassword">
        <ion-input
          appAutofill
          type="password"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          name="confirmPassword"
        />
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="formError" [text]="formError" color="danger" />

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        expand="block"
        (click)="submit()"
        class="ion-no-margin"
      >
        Submit
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
