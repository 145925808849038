<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title> Login </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container maxWidth="modal">
    <app-content-title
      [subtitle]="
        resetPasswordMode
          ? 'Enter your email to receive a password reset link'
          : ''
      "
      [showLogo]="true"
      align="center"
    />

    <ion-list [inset]="true" *ngIf="!resetPasswordMode" [formGroup]="loginForm">
      <ion-item>
        <ion-input
          #emailInput
          appAutofill
          type="email"
          autocomplete="email"
          formControlName="email"
          placeholder="Email"
          email
          name="email"
          (ionInput)="onInput()"
          (ionChange)="onInput()"
        />
      </ion-item>
      <ion-item>
        <ion-input
          #passwordInput
          appAutofill
          type="password"
          autocomplete="current-password"
          formControlName="password"
          placeholder="Password"
          name="password"
          (ionInput)="onInput()"
          (ionChange)="onInput()"
        />
      </ion-item>
    </ion-list>
    <app-list-post-line
      *ngIf="!resetPasswordMode && loginError"
      [text]="loginError"
      color="danger"
    />
    <div bottom class="modal-actions" *ngIf="!resetPasswordMode">
      <ion-button
        color="dark"
        [disabled]="loginForm.invalid"
        appHaptics
        [disableHaptics]="loginForm.invalid"
        expand="block"
        class="ion-no-margin"
        (click)="login()"
      >
        Log in
      </ion-button>
    </div>
    <div bottom class="modal-actions" *ngIf="!resetPasswordMode">
      <ion-button
        color="dark"
        fill="clear"
        size="small"
        (click)="goToResetPassword()"
        appHaptics
        expand="block"
        class="ion-no-margin"
      >
        Reset Password
      </ion-button>
    </div>

    <ion-list
      [inset]="true"
      *ngIf="resetPasswordMode"
      [formGroup]="resetPasswordForm"
    >
      <ion-item>
        <ion-input
          appAutofill
          type="email"
          autocomplete="email"
          formControlName="email"
          placeholder="Email"
          email
          name="email"
        />
      </ion-item>
    </ion-list>
    <app-list-post-line
      *ngIf="resetPasswordMode && resetPasswordError"
      [text]="resetPasswordError"
      color="danger"
    />
    <div bottom class="modal-actions" *ngIf="resetPasswordMode">
      <ion-button
        color="dark"
        class="ion-no-margin"
        [disabled]="resetPasswordForm.invalid"
        appHaptics
        [disableHaptics]="resetPasswordForm.invalid"
        expand="block"
        (click)="requestResetPassword()"
      >
        Reset Password
      </ion-button>
    </div>
    <div bottom class="modal-actions" *ngIf="resetPasswordMode">
      <ion-button
        color="dark"
        fill="clear"
        size="small"
        (click)="backToLogin()"
        appHaptics
        class="ion-no-margin"
        expand="block"
      >
        Back to Login
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
