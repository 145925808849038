import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService, LocalStorageService } from '@app/core';
import { EnvironmentService } from '@app/core/service/environment.service';
import { ToastService } from '@app/core/service/toast.service';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Capacitor } from '@capacitor/core';
import { LoadingController } from '@ionic/angular';
import { EMAIL_REGEX } from '@app/utils/email';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  @ViewChild('emailInput', { read: ElementRef })
  public emailInput: ElementRef<HTMLInputElement>;

  @ViewChild('passwordInput', { read: ElementRef })
  public passwordInput: ElementRef<HTMLInputElement>;

  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  googleAuthUrl: string;
  resetPasswordMode = false;
  topMessage = '';

  protected prevValue: { email: string; password: string } = {
    email: '',
    password: ''
  };
  protected addConnectedAccount = false;

  public constructor(
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _authenticationService: AuthenticationService,
    private readonly _environment: EnvironmentService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _toastService: ToastService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _route: ActivatedRoute
  ) {
    this._createForm();

    this._route.queryParams.subscribe((params) => {
      this.addConnectedAccount = params.addConnectedAccount === 'true';
    });
  }

  public ngOnInit() {
    this.googleAuthUrl = `${this._environment.serverUrl}/auth/google`;

    const multiUserMode = !!this._localStorageService.getItem('multiUserMode');

    if (multiUserMode) {
      this._router.navigate(['/pin']);
    }
  }

  protected onInput() {
    setTimeout(() => {
      if (
        this.prevValue.email !== this.emailInput.nativeElement.value &&
        this.prevValue.password !== this.passwordInput.nativeElement.value
      ) {
        if (
          this.emailInput.nativeElement.value &&
          this.passwordInput.nativeElement.value
        ) {
          this.login(
            this.emailInput.nativeElement.value,
            this.passwordInput.nativeElement.value
          );
        }
      }

      this.loginForm.controls.email.setValue(
        this.emailInput.nativeElement.value
      );
      this.loginForm.controls.password.setValue(
        this.passwordInput.nativeElement.value
      );

      this.prevValue = {
        email: this.emailInput.nativeElement.value,
        password: this.passwordInput.nativeElement.value
      };
    }, 100);
  }

  protected async login(emailValue?: string, passwordValue?: string) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const email = emailValue
      ? emailValue.toLowerCase().trim()
      : this.loginForm.value.email.toLowerCase().trim();
    const password = passwordValue || this.loginForm.value.password;

    this._authenticationService
      .login({
        email,
        password,
        addConnectedAccount: this.addConnectedAccount
      })
      .pipe(
        finalize(() => {
          loading.dismiss();
          this.loginForm.markAsPristine();
          this._cdr.markForCheck();
        })
      )
      .subscribe({
        next: async ({ payload }) => {
          if (Capacitor.getPlatform() === 'ios') {
            await SavePassword.promptDialog({
              username: payload.email,
              password: payload.password
            })
              .then(() => console.log('SavePassword success'))
              .catch((err) => console.error('SavePassword failure', err));
          }

          this._router.navigate(['/'], {
            replaceUrl: true
          });
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
          this._cdr.markForCheck();
        }
      });
  }

  protected async requestResetPassword() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const formValues = this.resetPasswordForm.value;
    const { email } = formValues;

    this._authenticationService
      .requestPasswordReset(email.toLowerCase())
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'Password reset link has been sent to your email. Follow the link to proceed with the password change.',
            { duration: 20000 }
          );
          this.backToLogin();
          this._cdr.markForCheck();
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
          this._cdr.markForCheck();
        }
      });
  }

  protected goToResetPassword() {
    this.resetPasswordForm.reset();
    this.resetPasswordMode = true;
  }

  protected backToLogin() {
    this.loginForm.reset();
    this.resetPasswordMode = false;
  }

  private _createForm() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', Validators.required]
    });
    this.resetPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]]
    });
  }

  protected get loginError(): string {
    if (
      this.loginForm.get('email').dirty &&
      this.loginForm.get('email').errors
    ) {
      if (this.loginForm.get('email').errors.required) {
        return 'Email is required';
      } else if (this.loginForm.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }

    if (
      this.loginForm.get('password').dirty &&
      this.loginForm.get('password').errors
    ) {
      if (this.loginForm.get('password').errors.required) {
        return 'Password is required';
      }
    }

    return '';
  }

  protected get resetPasswordError(): string {
    if (
      this.resetPasswordForm.get('email').dirty &&
      this.resetPasswordForm.get('email').errors
    ) {
      if (this.resetPasswordForm.get('email').errors.required) {
        return 'Email is required';
      } else if (this.resetPasswordForm.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }

    return '';
  }
}
