// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_signLibraryDoc = (path: TSpecPathSignLibraryDoc) => {
  let res = "/library/docs/:libraryDocId/sign";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_signLibraryDoc = RequestHandler<TSpecPathSignLibraryDoc, any, TSpecBodySignLibraryDoc, TSpecQuerySignLibraryDoc, {}>;

export interface TSpecPathSignLibraryDoc { libraryDocId: string; }

export interface TSpecBodySignLibraryDoc {}

export interface TSpecQuerySignLibraryDoc {
  AppVersion?: string;
  AppUser?: string;
}
