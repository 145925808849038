// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateUser = (path: TSpecPathUpdateUser) => {
  let res = "/users/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateUser = RequestHandler<TSpecPathUpdateUser, any, TSpecBodyUpdateUser, TSpecQueryUpdateUser, {}>;

export interface TSpecPathUpdateUser { id: string; }

export interface TSpecBodyUpdateUser {
  firstName?: string;
  lastName?: string;
  phone?: string;
  department?: string;
  roles?: UserRoles[];
  schedule?: {
    scheduleType: EUserScheduleType;
    weekDays?: boolean[];
    weekTime?: {
      from: string;
      to: string;
    }[];
  };
  compensationEmployeeId?: string;
  compensationPools?: string[];
  supervisorId?: string;
}

export enum UserRoles {
  Admin = "Admin",
  Manager = "Manager"
}
export enum EUserScheduleType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE"
}

export interface TSpecQueryUpdateUser {
  AppVersion?: string;
  AppUser?: string;
}
