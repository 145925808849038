// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_removeCompensationField = (path: TSpecPathRemoveCompensationField) => {
  let res = "/compensation/fields/:compensationFieldId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_removeCompensationField = RequestHandler<TSpecPathRemoveCompensationField, any, TSpecBodyRemoveCompensationField, TSpecQueryRemoveCompensationField, {}>;

export interface TSpecPathRemoveCompensationField { compensationFieldId: string; }

export interface TSpecBodyRemoveCompensationField {}

export interface TSpecQueryRemoveCompensationField {
  AppVersion?: string;
  AppUser?: string;
}
