import { Routes } from '@angular/router';
import { FullComponent } from './shared/layouts/full/full.component';
import { inject } from '@angular/core';
import { AuthenticationGuard } from './core';
import { BlankComponent } from './shared/layouts/blank/blank.component';
import { GlobalSettingsService } from './core/service/global-settings.service';
import { take } from 'rxjs';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'i',
    pathMatch: 'full'
  },
  {
    path: 'i',
    component: FullComponent,
    children: [
      {
        path: '',
        canLoad: [() => inject(AuthenticationGuard).canActivate()],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      }
    ],
    resolve: {
      globalSettings: () =>
        inject(GlobalSettingsService).globalSettings$.pipe(take(1))
    }
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '404',
        loadChildren: () =>
          import('./pages/not-found/not-found.module').then(
            (m) => m.NotFoundModule
          )
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('./pages/logout/logout.module').then((m) => m.LogoutModule)
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./pages/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule
          )
      },
      {
        path: 'change-email',
        loadChildren: () =>
          import('./pages/change-email/change-email.module').then(
            (m) => m.ChangeEmailModule
          )
      },
      {
        path: 'pin',
        loadChildren: () =>
          import('./pages/pin-code/pin-code.module').then(
            (m) => m.PinCodeModule
          )
      },
      {
        path: 'signup/user/:signupToken',
        loadChildren: () =>
          import('./pages/signup-user/signup-user.module').then(
            (m) => m.SignupUserModule
          )
      },
      {
        path: 'suggestion/:tenant',
        loadChildren: () =>
          import('./pages/suggestion-form/suggestion-form.module').then(
            (m) => m.SuggestionFormModule
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
