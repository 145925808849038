import { Component, OnInit } from '@angular/core';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { UserEntityService } from '@app/core/service/user-entity.service';
import fastDeepEqual from 'fast-deep-equal';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { EMAIL_REGEX } from '@app/utils/email';
import { ChangeEmailModalComponent } from '@app/modals/change-email-modal/change-email-modal.component';
import { UserScheduleEditorModalComponent } from '@app/modals/user-schedule-editor-modal/user-schedule-editor-modal.component';
import { showModal } from '@app/utils/modal';
import { formatPhoneNumber } from '@app/utils/phone-number';
import { ObjectId } from '@app/types/object-id';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  protected mainFormGroup: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX)
    ]),
    phone: new FormControl<string>(''),
    receivePNs: new FormControl<boolean>(true, Validators.required)
  });
  protected userParent: ObjectId;
  protected tenantFeatures: Pick<ITenantFeatures, 'userSchedule'> = {
    userSchedule: false
  };
  protected isResetPasswordEmailSent = false;

  public constructor(
    private readonly _userApiService: UserApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userEntityService: UserEntityService,
    private readonly _tenantService: TenantService,
    private readonly _modalCtrl: ModalController
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
    });
  }

  protected get firstNameError(): string {
    if (
      this.mainFormGroup.get('firstName').dirty &&
      this.mainFormGroup.get('firstName').errors
    ) {
      if (this.mainFormGroup.get('firstName').errors.required) {
        return 'First name is required';
      } else if (this.mainFormGroup.get('firstName').errors.pattern) {
        return 'First name contains invalid characters';
      }
    }
    return '';
  }

  protected get lastNameError(): string {
    if (
      this.mainFormGroup.get('lastName').dirty &&
      this.mainFormGroup.get('lastName').errors
    ) {
      if (this.mainFormGroup.get('lastName').errors.required) {
        return 'Last name is required';
      } else if (this.mainFormGroup.get('lastName').errors.pattern) {
        return 'Last name contains invalid characters';
      }
    }
    return '';
  }

  protected get phoneError() {
    const phoneField = this.mainFormGroup.get('phone');

    if (phoneField.value && phoneField.value !== '(') {
      if (phoneField.value.length < 14) {
        return 'Invalid phone number';
      }
    }

    return '';
  }

  protected get formError() {
    return this.firstNameError || this.lastNameError || this.phoneError;
  }

  protected get canSave() {
    const isValid = this.mainFormGroup.valid;
    const mainDataChanged = !fastDeepEqual(
      {
        firstName: this.mainFormGroup.value.firstName,
        lastName: this.mainFormGroup.value.lastName,
        email: this.mainFormGroup.value.email || '',
        phone: this.mainFormGroup.value.phone || '(',
        receivePNs: this.mainFormGroup.value.receivePNs
      },
      {
        firstName: this._authenticationService.user.firstName,
        lastName: this._authenticationService.user.lastName,
        email: this._authenticationService.user.email || '',
        phone: this._authenticationService.user.phone || '(',
        receivePNs: this._authenticationService.user.receivePNs
      }
    );

    return isValid && mainDataChanged && !this.formError;
  }

  public ngOnInit() {
    this._loadInfo();
  }

  protected _loadInfo() {
    this.mainFormGroup.reset({
      firstName: this._authenticationService.user.firstName,
      lastName: this._authenticationService.user.lastName,
      email: this._authenticationService.user.email,
      receivePNs: this._authenticationService.user.receivePNs,
      phone: this._authenticationService.user.phone
        ? formatPhoneNumber(this._authenticationService.user.phone)
        : ''
    });
    this.userParent = this._authenticationService.user.parent;
  }

  protected async submit() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    const newMainValue = this.mainFormGroup.value;

    this._userApiService
      .updateUser({
        path: { id: this._authenticationService.user._id },
        body: {
          ...newMainValue,
          email: newMainValue.email.toLowerCase(),
          phone: newMainValue.phone ? formatPhoneNumber(newMainValue.phone) : ''
        }
      })
      .subscribe({
        next: (user) => {
          this._authenticationService.setUser(user);
          this._userEntityService.update();
          loading.dismiss();
          this._toastService.presentToast('Changes saved successfully!');
          this._loadInfo();
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'There was an error while updating the user'
          );
        }
      });
  }

  protected async requestResetPassword() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const email = this.mainFormGroup.value.email;

    this._authenticationService
      .requestPasswordReset(email.toLowerCase())
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'Password reset link has been sent to your email. Follow the link to proceed with the password change.',
            { duration: 20000 }
          );
          this.isResetPasswordEmailSent = true;
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
        }
      });
  }

  protected async requestEmailChange() {
    await showModal(
      {
        component: ChangeEmailModalComponent,
        props: {
          oldEmail: this._authenticationService.user.email
        }
      },
      this._modalCtrl
    );
  }

  protected async openSchedule() {
    const { role } = await showModal(
      {
        component: UserScheduleEditorModalComponent,
        props: {
          userId: this._authenticationService.user._id,
          userSchedule: this._authenticationService.user.schedule
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this._loadInfo();
    }
  }
}
