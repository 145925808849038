<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title>Update User</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container
    [isFullHeight]="true"
    [withVerticalPadding]="true"
    [formGroup]="mainFormGroup"
  >
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="First Name"
          formControlName="firstName"
          labelPlacement="floating"
          autocapitalize="on"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Last Name"
          formControlName="lastName"
          labelPlacement="floating"
          autocapitalize="on"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Email"
          labelPlacement="floating"
          formControlName="email"
          [readonly]="true"
        />
        <ion-button
          *ngIf="isInvitationInProgress && !isInvitationLinkSent"
          slot="end"
          fill="clear"
          size="default"
          appHaptics
          class="ion-no-margin"
          (click)="resendInvitationLink()"
        >
          Resend Invitation
        </ion-button>
      </ion-item>
      <ion-item *ngIf="mainFormGroup.value.phone">
        <ion-input
          label="Phone"
          labelPlacement="floating"
          formControlName="phone"
          [readonly]="true"
        />
      </ion-item>
      <ion-item>
        <ion-select
          label="Role"
          labelPlacement="floating"
          formControlName="role"
          appHaptics
          interface="popover"
        >
          <ion-select-option [value]="role" *ngFor="let role of roleOptions">
            {{ role }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="formError" [text]="formError" color="danger" />

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Supervisor (optional)"
          labelPlacement="floating"
          [value]="formattedSupervisor"
          [readonly]="true"
          (click)="selectSupervisor($event)"
          appHaptics
        />
        <ion-button
          *ngIf="formattedSupervisor"
          fill="clear"
          (click)="handleSupervisorClearClick()"
          appHaptics
          class="ion-no-margin"
        >
          <ion-icon slot="icon-only" name="close-circle" class="input-icon" />
        </ion-button>
      </ion-item>
      <ion-item *ngIf="departments.length > 0">
        <ion-select
          label="Department"
          interface="popover"
          placeholder="Select Department"
          aria-label="Department"
          formControlName="department"
          labelPlacement="floating"
          appHaptics
        >
          <ion-select-option [value]="null">No Department</ion-select-option>
          <ion-select-option
            *ngFor="let department of departments"
            [value]="department._id"
          >
            {{ department.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="tenantFeatures.userSchedule" [inset]="true">
      <ion-item button appHaptics (click)="openSchedule()">
        <ion-icon slot="start" name="calendar-outline" />
        <ion-label>Schedule</ion-label>
      </ion-item>
    </ion-list>

    <div *ngIf="tenantFeatures.checklist">
      <app-list-pre-line title="Tasks" />
      <ion-list [inset]="true">
        <ion-item button appHaptics (click)="openSkillMatrix()">
          <ion-icon slot="start" name="grid-outline" />
          <ion-label>Training</ion-label>
        </ion-item>
        <ion-item button appHaptics (click)="manageTasks()">
          <ion-icon slot="start" name="notifications-outline" />
          <ion-label>Notifications</ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div *ngIf="tenantFeatures.calculateRewards">
      <app-list-pre-line title="Compensation" />
      <ion-list [inset]="true">
        <ion-item>
          <ion-input
            label="Employee ID from Payroll Provider (optional)"
            labelPlacement="floating"
            formControlName="compensationEmployeeId"
            autocapitalize="on"
          />
        </ion-item>
        <ion-item *ngIf="compensationPools.length > 0">
          <ion-select
            label="Compensation Pools (optional)"
            labelPlacement="floating"
            formControlName="compensationPools"
            appHaptics
            [multiple]="true"
          >
            <ion-select-option
              [value]="compensationPool._id"
              *ngFor="let compensationPool of compensationPools"
            >
              {{ compensationPool.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="!isInvitationInProgress"
        [color]="isDeleted ? 'primary' : 'danger'"
        fill="outline"
        appHaptics
        (click)="toggleSoftDelete()"
        expand="block"
        class="ion-no-margin"
      >
        {{ isDeleted ? 'Reactivate' : 'Deactivate' }}
      </ion-button>
      <ion-button
        color="dark"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        (click)="updateUser()"
        expand="block"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
