import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalClass } from '@app/core/class/modal.class';
import { LoadingController, ModalController } from '@ionic/angular';
import { EUserScheduleType, IUserSchedule } from '@backend/models/types/user';
import {
  defaultScheduleFormGroup,
  defaultWeekTimeFormGroup
} from '@app/utils/user-schedule';
import fastDeepEqual from 'fast-deep-equal';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ToastService } from '@app/core/service/toast.service';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-user-schedule-editor-modal',
  templateUrl: './user-schedule-editor-modal.component.html'
})
export class UserScheduleEditorModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public userId: string;

  @Input()
  public userSchedule: IUserSchedule;

  protected readonly userScheduleType = EUserScheduleType;

  protected weekTimeFormGroup: FormGroup = new FormGroup(
    defaultWeekTimeFormGroup
  );
  protected scheduleFormGroup: FormGroup = new FormGroup(
    defaultScheduleFormGroup
  );

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userApiService: UserApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userEntityService: UserEntityService,
    private readonly _toastService: ToastService,
    private readonly _authenticationService: AuthenticationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._loadInfo();
  }

  private _loadInfo() {
    if (this.userSchedule) {
      this.scheduleFormGroup.reset({
        scheduleType: this.userSchedule.scheduleType,
        weekDays: this.userSchedule.weekDays
      });
      this.weekTimeFormGroup.reset(this.userSchedule.weekTime);
    }
  }

  protected close() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected get canSave() {
    if (!this.userSchedule) return true;

    const isValid =
      this.scheduleFormGroup.valid && this.weekTimeFormGroup.valid;
    const scheduleDataChanged = !fastDeepEqual(this.scheduleFormGroup.value, {
      scheduleType: this.userSchedule.scheduleType,
      weekDays: this.userSchedule.weekDays
    });
    const weekTimeDataChanged = !fastDeepEqual(
      Object.values(this.weekTimeFormGroup.value),
      this.userSchedule.weekTime.map((time) => ({
        from: time.from,
        to: time.to
      }))
    );

    return isValid && (scheduleDataChanged || weekTimeDataChanged);
  }

  protected async submit() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    const newScheduleValue = this.scheduleFormGroup.value;

    this._userApiService
      .updateUser({
        path: { id: this.userId },
        body: {
          schedule: {
            ...newScheduleValue,
            weekTime: Object.values(this.weekTimeFormGroup.value)
          }
        }
      })
      .subscribe({
        next: (user) => {
          if (
            user._id.toString() ===
            this._authenticationService.user._id.toString()
          ) {
            this._authenticationService.setUser({
              ...this._authenticationService.user,
              schedule: user.schedule
            });
            this._userEntityService.update();
          }

          loading.dismiss();
          this._toastService.presentToast('Changes saved successfully!');
          this._modalCtrl.dismiss(null, 'confirm', this._modalName);
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'There was an error while updating the user'
          );
        }
      });
  }
}
