// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_cancelTaskPerformance = (path: TSpecPathCancelTaskPerformance) => {
  let res = "/task-performance/:id/cancel";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_cancelTaskPerformance = RequestHandler<TSpecPathCancelTaskPerformance, any, TSpecBodyCancelTaskPerformance, TSpecQueryCancelTaskPerformance, {}>;

export interface TSpecPathCancelTaskPerformance { id: string; }

export interface TSpecBodyCancelTaskPerformance {}

export interface TSpecQueryCancelTaskPerformance {
  AppVersion?: string;
  AppUser?: string;
}
