<ion-item
  *ngIf="isItem; else button"
  button
  (click)="onDateButtonClick($event)"
  [disabled]="disabled"
  appHaptics
  [disableHaptics]="disabled"
  [lines]="itemLines ? undefined : 'none'"
>
  <ion-label>{{ label }}</ion-label>
  <ion-note slot="end">
    <ng-container *ngIf="value; else stub">
      {{ value | date : 'MMM d, y' }}
    </ng-container>
    <ng-template #stub>--- --, ----</ng-template>
  </ion-note>
</ion-item>

<ng-template #button>
  <app-wide-button
    [label]="label"
    [disabled]="disabled"
    (buttonClicked)="onDateButtonClick($event)"
  >
    <ng-container *ngIf="value; else stub">
      {{ value | date : 'MMM d, y' }}
    </ng-container>
    <ng-template #stub>--- --, ----</ng-template>
  </app-wide-button>
</ng-template>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      [min]="min"
      [max]="max"
      (ionChange)="onCalendarChange($event)"
    />
  </ng-template>
</ion-popover>
