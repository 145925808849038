import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';

@Component({
  selector: 'app-slim-menu-button',
  templateUrl: './slim-menu-button.component.html',
  standalone: true,
  imports: [IonicModule, DirectivesModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlimMenuButtonComponent {}
