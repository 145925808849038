// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_acceptEula = (path: TSpecPathAcceptEula) => {
  let res = "/backdoor/eula/:backdoorEulaId/accept";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_acceptEula = RequestHandler<TSpecPathAcceptEula, any, TSpecBodyAcceptEula, TSpecQueryAcceptEula, {}>;

export interface TSpecPathAcceptEula { backdoorEulaId: string; }

export interface TSpecBodyAcceptEula {
  deviceId: string;
  deviceModel: string;
  deviceOperatingSystem: string;
  deviceOsVersion: string;
  deviceWebViewVersion: string;
  devicePlatform: string;
}

export interface TSpecQueryAcceptEula {
  AppVersion?: string;
  AppUser?: string;
}
