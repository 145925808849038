// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_reserveTag = () => "/tags/reserve";

export type TSpecHandler_reserveTag = RequestHandler<TSpecPathReserveTag, any, TSpecBodyReserveTag, TSpecQueryReserveTag, {}>;

export interface TSpecPathReserveTag {  }

export interface TSpecBodyReserveTag {
  clientDate: string;
}

export interface TSpecQueryReserveTag {
  AppVersion?: string;
  AppUser?: string;
}
