// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getWaitingForApprovalTaskPerformances = () => "/task-performance/waiting-for-approval";

export type TSpecHandler_getWaitingForApprovalTaskPerformances = RequestHandler<TSpecPathGetWaitingForApprovalTaskPerformances, any, TSpecBodyGetWaitingForApprovalTaskPerformances, TSpecQueryGetWaitingForApprovalTaskPerformances, {}>;

export interface TSpecPathGetWaitingForApprovalTaskPerformances {  }

export interface TSpecBodyGetWaitingForApprovalTaskPerformances {}

export interface TSpecQueryGetWaitingForApprovalTaskPerformances {
  AppVersion?: string;
  AppUser?: string;
}
