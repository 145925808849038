import { Component, ViewChild } from '@angular/core';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { AuthenticationService } from '@app/core';
import { AnnouncementApiService } from '@app/core/service/api/announcement.api.service';
import {
  IonNav,
  IonInfiniteScroll,
  ViewWillEnter,
  LoadingController,
  ViewDidEnter
} from '@ionic/angular';
import { AnnouncementAddComponent } from '@app/pages/announcements/announcement-add/announcement-add.component';
import { AnnouncementViewComponent } from '@app/pages/announcements/announcement-view/announcement-view.component';
import { take } from 'rxjs';
import { UserListService } from '@app/core/service/user-list.service';
import { groupByDate } from '@app/utils/group-by-date';
import { TenantService } from '@app/core/service/tenant.service';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements ViewWillEnter, ViewDidEnter {
  @ViewChild('infiniteScroll')
  public infiniteScroll: IonInfiniteScroll;

  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected announcements: AnnouncementModel[];
  protected announcementsGroupedByDate: {
    date: string;
    items: AnnouncementModel[];
  }[] = [];
  protected hasEditRights = false;
  protected isLoading = true;
  protected hasNextPage = true;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;
  protected filters: {
    showRewardReports: boolean;
  } = {
    showRewardReports: false
  };

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _ionNav: IonNav,
    private readonly _userList: UserListService,
    private readonly _announcementApiService: AnnouncementApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _tenantService: TenantService,
    private readonly _route: ActivatedRoute,
    private readonly _location: Location
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.hasEditRights =
        tenant.settings.announcements.whoCanSendAnnouncements.reduce(
          (prev, role) =>
            prev || this._authenticationService.user.roles.includes(role),
          false
        );
    });

    this._route.params.subscribe((params) => {
      const queryId = params['id'];

      if (queryId) {
        this._location.replaceState('/i/suggestions');
        this.onAnnouncementClick(queryId);
      }
    });
  }

  public ionViewWillEnter(): void {
    this._fetchAnnouncements(true);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected _fetchAnnouncements(reset = false) {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.hasNextPage = true;
      this.announcements = [];
      this.announcementsGroupedByDate = [];
    }

    this._announcementApiService
      .getAllAnnouncements({
        query: {
          page: this.page.toString(),
          limit: this.pageSize.toString(),
          searchTerm: this.searchTerm,
          showRewardReports: this.filters.showRewardReports
        }
      })
      .subscribe((fetchedAnnouncements) => {
        this.hasNextPage = fetchedAnnouncements.length === this.pageSize;
        this.announcements = this.announcements.concat(fetchedAnnouncements);
        this.announcementsGroupedByDate = groupByDate<AnnouncementModel>(
          this.announcements,
          'date'
        );
        this.isLoading = false;
        this.pullToSearch.initSearch();
        this.infiniteScroll?.complete();
      });
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchAnnouncements();
  }

  protected onAddClick(): void {
    this._userList.users$.pipe(take(1)).subscribe((users) => {
      this._ionNav.push(AnnouncementAddComponent, { users });
    });
  }

  protected onAnnouncementClick(announcementId: string): void {
    this._ionNav.push(AnnouncementViewComponent, {
      id: announcementId
    });
  }

  protected handleSearchInput(value: string) {
    this.searchTerm = value;
    this._fetchAnnouncements(true);
  }

  protected handleFilter(filter: 'showRewardReports', event: any) {
    this.filters[filter] = event.detail.checked;
    this._fetchAnnouncements(true);
  }

  protected trackPosts(_: number, item: AnnouncementModel) {
    return item._id;
  }

  protected trackDates(
    _: number,
    item: {
      date: string;
      items: AnnouncementModel[];
    }
  ) {
    return item.date;
  }

  protected firstPagePostsCount(groupIndex: number) {
    return this.announcementsGroupedByDate
      .slice(0, groupIndex)
      .reduce((prev, cur) => prev + cur.items.length, 0);
  }
}
