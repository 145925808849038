// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateGlobalSettings = () => "/global-settings";

export type TSpecHandler_updateGlobalSettings = RequestHandler<TSpecPathUpdateGlobalSettings, any, TSpecBodyUpdateGlobalSettings, TSpecQueryUpdateGlobalSettings, {}>;

export interface TSpecPathUpdateGlobalSettings {  }

export interface TSpecBodyUpdateGlobalSettings {
  instantFeedback: {
    email: string;
    inactivityTime: number;
  };
  psychologicalSurvey: {
    currentSurvey: string;
    emailForSuggestedOptions: string;
    showFeedbackBy: EPsychologicalSurveyShowFeedbackBy;
  };
  aiPrompts: {
    suggestionTitle: string;
  };
}

export enum EPsychologicalSurveyShowFeedbackBy {
  feedbackByBehaviors = "feedbackByBehaviors",
  feedbackByEvaluationOptions = "feedbackByEvaluationOptions"
}

export interface TSpecQueryUpdateGlobalSettings {
  AppVersion?: string;
  AppUser?: string;
}
