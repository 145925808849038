import { Component, Input, OnInit } from '@angular/core';
import { TaskListService } from '@app/core/service/task-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import { TaskDto } from '@app/types/task-dto';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { ModalController } from '@ionic/angular';
import { skillMatrixStatuses } from '@app/utils/skill-matrix';
import { ModalClass } from '@app/core/class/modal.class';
import { showTaskSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-skill-matrix-for-user-modal',
  templateUrl: './skill-matrix-for-user-modal.component.html',
  styleUrls: ['./skill-matrix-for-user-modal.component.scss']
})
export class SkillMatrixForUserModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public userId: ObjectId;

  protected allTasks: MongoStoredObject<TaskDto>[] = [];
  protected skillMatrix: { status: ESkillMatrixStatus; task: ObjectId }[];

  protected readonly statuses = skillMatrixStatuses;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _taskListService: TaskListService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._taskListService.list$.subscribe(async (at) => {
      const allTasks = at.filter((task) => !task.isDeleted);

      const skillMatrix = allTasks.map((t) => ({
        task: t._id,
        status:
          t.skillMatrix.find((smu) => smu.user.toString() === this.userId)
            ?.status || ESkillMatrixStatus.CANNOT_PERFORM
      }));

      this.allTasks = allTasks;
      this.skillMatrix = skillMatrix;
    });
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected emitSkillMatrix(): void {
    this._taskListService.patchTasks(
      this.userId,
      this.skillMatrix.map((smt) => ({
        _id: smt.task,
        skillMatrixStatus: smt.status
      }))
    );
  }

  protected trackItems(_: number, task: MongoStoredObject<TaskDto>) {
    return task._id;
  }

  protected getTaskStatus(task: MongoStoredObject<TaskDto>) {
    return (
      this.skillMatrix.find(
        (smt) => smt.task.toString() === task._id.toString()
      )?.status || ESkillMatrixStatus.CANNOT_PERFORM
    );
  }

  protected onStatusChange(
    status: ESkillMatrixStatus,
    task: MongoStoredObject<TaskDto>
  ) {
    if (this.skillMatrix.find((smt) => smt.task === task._id)) {
      this.skillMatrix = this.skillMatrix.map((smt) =>
        smt.task === task._id ? { ...smt, status } : smt
      );
    } else {
      this.skillMatrix = [...this.skillMatrix, { task: task._id, status }];
    }

    this.emitSkillMatrix();
  }

  protected async selectTasksForGroup(skillMatrixStatus: ESkillMatrixStatus) {
    const selectedStatusTitle = skillMatrixStatuses.find(
      (s) => s.status === skillMatrixStatus
    ).title;
    const tasksIdsOfSelectedStatus = this.getTasksOfStatus(
      skillMatrixStatus
    ).map(({ _id }) => _id);

    const { success, selectedTasks } = await showTaskSelectorModal(
      {
        title: selectedStatusTitle,
        tasks: this.allTasks,
        selectedTasks: tasksIdsOfSelectedStatus,
        multiple: true
      },
      this._modalCtrl,
      { parentModalName: this._modalName }
    );

    if (success) {
      selectedTasks.map((v) => {
        const foundUser = this.skillMatrix.find((u) => u.task === v);
        if (foundUser) {
          foundUser.status = skillMatrixStatus;
        } else {
          this.skillMatrix.push({ task: v, status: skillMatrixStatus });
        }
      });

      this.emitSkillMatrix();
    }
  }

  protected getTasksOfStatus(status: ESkillMatrixStatus) {
    if (status === ESkillMatrixStatus.CANNOT_PERFORM) {
      return this.allTasks.filter((task) => {
        const exists = this.skillMatrix.find((smt) => task._id === smt.task);

        if (exists) {
          return exists.status === ESkillMatrixStatus.CANNOT_PERFORM;
        } else {
          return true;
        }
      });
    }

    return this.allTasks.filter((task) => {
      return (
        this.skillMatrix.find((smt) => task._id === smt.task)?.status === status
      );
    });
  }
}
