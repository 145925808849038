// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUsersWhoWereWorkingLastPeriod = () => "/psychological-survey/users-who-were-working-last-period";

export type TSpecHandler_getUsersWhoWereWorkingLastPeriod = RequestHandler<TSpecPathGetUsersWhoWereWorkingLastPeriod, any, TSpecBodyGetUsersWhoWereWorkingLastPeriod, TSpecQueryGetUsersWhoWereWorkingLastPeriod, {}>;

export interface TSpecPathGetUsersWhoWereWorkingLastPeriod {  }

export interface TSpecBodyGetUsersWhoWereWorkingLastPeriod {}

export interface TSpecQueryGetUsersWhoWereWorkingLastPeriod {
  AppVersion?: string;
  AppUser?: string;
}
