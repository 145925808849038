// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_reorderTaskFoldersMultiple = () => "/task-folders/reorder-multiple";

export type TSpecHandler_reorderTaskFoldersMultiple = RequestHandler<TSpecPathReorderTaskFoldersMultiple, any, TSpecBodyReorderTaskFoldersMultiple, TSpecQueryReorderTaskFoldersMultiple, {}>;

export interface TSpecPathReorderTaskFoldersMultiple {  }

export interface TSpecBodyReorderTaskFoldersMultiple {
  taskFolders: {
    taskFolderId: string;
    orderIndex: number;
  }[];
}

export interface TSpecQueryReorderTaskFoldersMultiple {
  AppVersion?: string;
  AppUser?: string;
}
