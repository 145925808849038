import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SignupUserComponent } from './signup-user.component';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Sign Up',
      rootComponent: SignupUserComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    RouterModule.forChild(ROUTES),
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    DirectivesModule,
    FullHeightContainerModule,
    ListPostLineModule,
    ContentTitleModule
  ],
  declarations: [SignupUserComponent]
})
export class SignupUserModule {}
