<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Team Settings</ion-title>
    <ion-buttons slot="start">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <app-content-title> Team Settings </app-content-title>

    <ion-list [inset]="true">
      <ion-item button (click)="goToGeneral()" appHaptics>
        <ion-icon slot="start" name="cog-outline" />
        <ion-label>General</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.insights || tenantFeatures.multiUserMode"
        button
        (click)="goToSecurity()"
        appHaptics
      >
        <ion-icon slot="start" name="hand-left-outline" />
        <ion-label>Security & Permissions</ion-label>
      </ion-item>
      <ion-item button (click)="goToCompensation()" appHaptics>
        <ion-icon slot="start" name="cash-outline" />
        <ion-label>Compensation</ion-label>
      </ion-item>
      <ion-item button (click)="goToBehavior()" appHaptics>
        <ion-icon slot="start" name="extension-puzzle-outline" />
        <ion-label>Behavior</ion-label>
      </ion-item>
      <ion-item button (click)="goToRecognition()" appHaptics>
        <ion-icon slot="start" name="happy-outline" />
        <ion-label>Recognition</ion-label>
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Modules" />
    <ion-list [inset]="true">
      <ion-item
        *ngIf="tenantFeatures.dailyReport"
        button
        (click)="goToDailyReport()"
        appHaptics
      >
        <ion-icon slot="start" name="today-outline" />
        <ion-label>Daily Activity Report</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.checklist"
        button
        (click)="goToChecklist()"
        appHaptics
      >
        <ion-icon slot="start" name="checkmark-circle-outline" />
        <ion-label>Checklist</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.tags"
        button
        (click)="goToTags()"
        appHaptics
      >
        <ion-icon slot="start" name="pricetags-outline" />
        <ion-label>Tags</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.announcements"
        button
        (click)="goToAnnouncements()"
        appHaptics
      >
        <ion-icon slot="start" name="mail-outline" />
        <ion-label>Announcements</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.suggestions"
        button
        (click)="goToSuggestions()"
        appHaptics
      >
        <ion-icon slot="start" name="chatbubbles-outline" />
        <ion-label>Suggestions</ion-label>
      </ion-item>
      <ion-item
        *ngIf="tenantFeatures.survey"
        button
        (click)="goToPulse()"
        appHaptics
      >
        <ion-icon slot="start" name="leaf-outline" />
        <ion-label>Pulse</ion-label>
      </ion-item>
    </ion-list>
  </app-full-height-container>
</ion-content>
