// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllBackdoorInfos = () => "/backdoor/info";

export type TSpecHandler_getAllBackdoorInfos = RequestHandler<TSpecPathGetAllBackdoorInfos, any, TSpecBodyGetAllBackdoorInfos, TSpecQueryGetAllBackdoorInfos, {}>;

export interface TSpecPathGetAllBackdoorInfos {  }

export interface TSpecBodyGetAllBackdoorInfos {}

export interface TSpecQueryGetAllBackdoorInfos {
  AppVersion?: string;
  AppUser?: string;
}
