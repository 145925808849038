<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBack()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
      </ion-button>
    </ion-buttons>

    <ion-title>Manage Tasks</ion-title>

    <ion-buttons slot="end">
      <ion-button
        color="primary"
        *ngIf="reorderEnabled; else popoverTrigger"
        (click)="onReorderDoneClick()"
        appHaptics
      >
        Done
      </ion-button>
      <ng-template #popoverTrigger>
        <ion-button
          color="primary"
          (click)="onEditButtonClick($event)"
          appHaptics
        >
          Edit
        </ion-button>
      </ng-template>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <app-width-restricted-container>
      <ion-searchbar
        (ionInput)="onSearchChange($event)"
        [(ngModel)]="searchBarValue"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="reorderEnabled"
      />
    </app-width-restricted-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container *ngIf="taskFolders$ | async as taskFolders">
    <ng-container *ngIf="defaultFolder$ | async as defaultFolder">
      <div
        *ngIf="taskFolders.length === 0 && defaultFolder.tasks.length === 0"
        class="empty-list"
      >
        <div>
          Press <span class="add-button-name">Edit</span> button to create a
          task
        </div>
      </div>

      <ion-list class="groups-list">
        <ion-reorder-group
          class="reorder-group-with-wrappers groups-list-reorderer"
          [disabled]="!reorderEnabled"
          (ionItemReorder)="handleFolderReorder($any($event))"
        >
          <ng-container *ngFor="let folder of taskFolders">
            <ion-reorder>
              <ion-item
                class="group-title opaque-reorder-item"
                [ngClass]="{ 'group-title--reordering': reorderEnabled }"
              >
                <ion-button
                  fill="clear"
                  class="ion-no-margin ion-text-capitalize"
                  (click)="!reorderEnabled && selectTasksForGroup(folder)"
                  appHaptics
                  [disableHaptics]="reorderEnabled"
                >
                  {{ folder.title.toLowerCase() }}
                </ion-button>
                <ion-icon
                  *ngIf="reorderEnabled; else editFolderBtn"
                  class="reorder-handle-icon"
                  name="reorder-two-outline"
                  slot="end"
                />
                <ng-template #editFolderBtn>
                  <ion-button
                    *ngIf="isAdmin"
                    (click)="onEditFolderClick(folder)"
                    fill="clear"
                    class="ion-no-margin"
                    slot="end"
                  >
                    Edit
                  </ion-button>
                </ng-template>
              </ion-item>
              <ion-list>
                <ion-reorder-group
                  class="reorder-group-with-wrappers tasks-list-reorderer"
                  [disabled]="!reorderEnabled"
                  (ionItemReorder)="handleTaskReorder($any($event), folder)"
                >
                  <ng-container
                    appHaptics
                    [disableHaptics]="reorderEnabled"
                    *ngFor="let task of folder.tasks; trackBy: taskTrackBy"
                  >
                    <ion-reorder>
                      <ion-item
                        [button]="!reorderEnabled"
                        (click)="onOpenTaskClick(task)"
                        [detail]="!reorderEnabled"
                        class="task-item opaque-reorder-item"
                      >
                        <ion-label class="ion-text-capitalize">
                          {{ task.title.toLowerCase() }}
                        </ion-label>
                        <ion-chip
                          *ngIf="task.isDeleted"
                          color="warning"
                          disabled
                        >
                          Archived
                        </ion-chip>
                        <ion-icon
                          *ngIf="reorderEnabled"
                          class="reorder-handle-icon"
                          name="reorder-two-outline"
                          slot="end"
                        />
                      </ion-item>
                    </ion-reorder>
                  </ng-container>
                </ion-reorder-group>
              </ion-list>
            </ion-reorder>
          </ng-container>
        </ion-reorder-group>

        <ng-container>
          <ion-item
            *ngIf="defaultFolder.tasks.length"
            class="group-title opaque-reorder-item"
            [ngClass]="{ 'group-title--reordering': reorderEnabled }"
          >
            <ion-button
              fill="clear"
              class="ion-no-margin"
              (click)="!reorderEnabled && selectTasksForGroup(defaultFolder)"
              appHaptics
              [disableHaptics]="reorderEnabled"
            >
              {{ defaultFolder.title }}
            </ion-button>
          </ion-item>
          <ion-list>
            <ion-reorder-group
              class="reorder-group-with-wrappers tasks-list-reorderer"
              [disabled]="!reorderEnabled"
              (ionItemReorder)="handleTaskReorder($any($event), defaultFolder)"
            >
              <ng-container
                appHaptics
                [disableHaptics]="reorderEnabled"
                *ngFor="let task of defaultFolder.tasks; trackBy: taskTrackBy"
              >
                <ion-reorder>
                  <ion-item
                    [button]="!reorderEnabled"
                    (click)="onOpenTaskClick(task)"
                    [detail]="!reorderEnabled"
                    class="task-item opaque-reorder-item"
                  >
                    <ion-label class="ion-text-capitalize">
                      {{ task.title.toLowerCase() }}
                    </ion-label>
                    <ion-chip *ngIf="task.isDeleted" color="warning" disabled>
                      Archived
                    </ion-chip>
                    <ion-icon
                      *ngIf="reorderEnabled"
                      class="reorder-handle-icon"
                      name="reorder-two-outline"
                      slot="end"
                    />
                  </ion-item>
                </ion-reorder>
              </ng-container>
            </ion-reorder-group>
          </ion-list>
        </ng-container>
      </ion-list>
    </ng-container>
  </app-width-restricted-container>
</ion-content>

<ion-popover
  [isOpen]="isPopoverOpen"
  [dismissOnSelect]="true"
  (didDismiss)="onPopoverDismiss()"
  #popover
>
  <ng-template>
    <ion-list lines="none">
      <ion-item
        button
        (click)="onCreateTaskPressed()"
        [disabled]="reorderEnabled"
        appHaptics
        [disableHaptics]="reorderEnabled"
      >
        <ion-label>Create Task</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="onCreateFolderPressed()"
        [disabled]="reorderEnabled"
        appHaptics
        [disableHaptics]="reorderEnabled"
      >
        <ion-label>Create Group</ion-label>
      </ion-item>
      <ion-item button (click)="onReorderEnableClick()" appHaptics>
        <ion-label>Rearrange</ion-label>
      </ion-item>
      <ion-item *ngIf="filter$ | async as filter">
        <ion-toggle
          [checked]="filter.showIsDeleted"
          (ionChange)="handleShowIsDeletedChange($event)"
          appHaptics
        >
          Show Archived
        </ion-toggle>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
