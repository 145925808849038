// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_refoldTasksMultiple = () => "/tasks/refold-multiple";

export type TSpecHandler_refoldTasksMultiple = RequestHandler<TSpecPathRefoldTasksMultiple, any, TSpecBodyRefoldTasksMultiple, TSpecQueryRefoldTasksMultiple, {}>;

export interface TSpecPathRefoldTasksMultiple {  }

export type TSpecBodyRefoldTasksMultiple = {
  tasks: string[];
  taskFolder: string | null;
}[];

export interface TSpecQueryRefoldTasksMultiple {
  AppVersion?: string;
  AppUser?: string;
}
