<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ backdoorEula ? 'Update' : 'Add' }} EULA </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [formGroup]="mainFormGroup">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Title"
          formControlName="title"
          placeholder="New Update Available"
          autocapitalize="on"
          [spellcheck]="true"
          labelPlacement="floating"
        />
      </ion-item>
      <ion-item>
        <ion-textarea
          label="Iframe Code"
          formControlName="iframeCode"
          placeholder="<div />"
          autocapitalize="on"
          [autoGrow]="true"
          labelPlacement="floating"
        />
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle formControlName="showToAllTenants">
          Show to All Tenants
        </ion-toggle>
      </ion-item>
      <ion-item *ngIf="!showToAllTenants">
        <ion-select
          label="Tenants"
          [multiple]="true"
          formControlName="tenantsToShow"
          appHaptics
          labelPlacement="floating"
        >
          <ion-select-option
            *ngFor="let tenant of tenantList$ | async as tenantList"
            [value]="tenant._id"
          >
            {{ tenant.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        fill="outline"
        expand="block"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        (click)="preview()"
        class="ion-no-margin"
      >
        Preview
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        (click)="onFormSubmit()"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
