import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '@app/modules/directives.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatTimeModule } from '@app/modules/format-time/format-time.module';
import { UserScheduleEditorModule } from '@app/modules/user-schedule-editor/user-schedule-editor.module';
import { ChangeEmailModalModule } from '@app/modals/change-email-modal/change-email-modal.module';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';
import { ListPostLineModule } from '../../modules/list-post-line/list-post-line.module';
import { FullHeightContainerModule } from '../../modules/full-height-container/full-height-container.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Profile',
      rootComponent: ProfileComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    DirectivesModule,
    SlimMenuButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    FormatTimeModule,
    UserScheduleEditorModule,
    ChangeEmailModalModule,
    ContentTitleModule,
    ListPostLineModule,
    ListPostLineModule,
    FullHeightContainerModule
  ],
  declarations: [ProfileComponent],
  providers: []
})
export class ProfileModule {}
