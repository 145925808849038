export const formatPhoneNumber = (phoneNumber: string): string => {
  const rawValue = phoneNumber.replace(/\D/g, '');
  let formatted = '';

  if (rawValue.length > 0) {
    formatted += `(${rawValue.substring(0, 3)}`;
  }
  if (rawValue.length >= 4) {
    formatted += `) ${rawValue.substring(3, 6)}`;
  }
  if (rawValue.length >= 7) {
    formatted += `-${rawValue.substring(6, 10)}`;
  }

  return formatted;
};
