import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { ObjectId } from '@app/types/object-id';
import { FilterTasksPipe } from './filter-tasks.pipe';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { ModalClass } from '@app/core/class/modal.class';

@Component({
  selector: 'app-task-selector-modal',
  templateUrl: './task-selector-modal.component.html',
  styleUrls: ['./task-selector-modal.component.scss']
})
export class TaskSelectorModalComponent
  extends ModalClass
  implements OnInit, AfterViewInit
{
  @Input()
  public set selectedTasks(value: ObjectId[]) {
    this.originalSelectedTasks = value;
  }

  @Input()
  public readonly title?: string = 'Tasks';

  @Input()
  public readonly tasks: MongoStoredObject<TaskDto>[];

  @Input()
  public readonly hiddenTasks: ObjectId[] = [];

  @Input()
  public readonly multiple?: boolean = false;

  @Input()
  public readonly focusSearchbar?: boolean = false;

  @Input()
  public readonly emptyText?: string = 'No Tasks';

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected originalSelectedTasks: ObjectId[] = [];
  public selected: {
    id: ObjectId;
    name: string;
    checked: boolean;
    isDeleted: boolean;
  }[] = [];
  public filter = '';

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  protected get isAllChecked(): boolean {
    return (
      this.selected.filter((item) => item.checked).length ===
        this.tasks.length && this.tasks.length > 0
    );
  }

  protected get isIndeterminate(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);
    return checkedItems.length > 0 && checkedItems.length < this.tasks.length;
  }

  protected get canSave(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);

    if (checkedItems.length !== this.originalSelectedTasks.length) {
      return true;
    }

    return !this.originalSelectedTasks.reduce((prev, cur) => {
      if (prev === false) return false;
      return !!checkedItems.find((item) => item.id === cur);
    }, true);
  }

  public ngOnInit(): void {
    this.selected = this.tasks
      .map((task) => ({
        id: task._id,
        name: task.title,
        checked: this.originalSelectedTasks.includes(task._id),
        isDeleted: task.isDeleted
      }))
      .sort((a, b) => {
        const aChecked = a.checked;
        const bChecked = b.checked;
        if (aChecked && !bChecked) {
          return -1;
        }
        if (!aChecked && bChecked) {
          return 1;
        }
        return 0;
      });
  }

  public ngAfterViewInit(): void {
    if (this.focusSearchbar) {
      setTimeout(() => {
        this.ionSearchbar.setFocus();
      }, 600);
    }
  }

  protected trackItems(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
    }
  ) {
    return item.id;
  }

  protected cancelChanges() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected confirmChanges() {
    const checkedItems = this.selected
      .filter((i) => i.checked)
      .map((i) => i.id);
    this._modalCtrl.dismiss(checkedItems, 'confirm', this._modalName);
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    if (this.multiple) {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value ? !item.checked : item.checked
      }));
    } else {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value
      }));

      this.confirmChanges();
    }
  }

  protected allTasksChange(event: any): void {
    const {
      detail: { checked }
    }: { detail: { checked: boolean } } = event;

    this.selected = this.selected.map((item) => ({
      ...item,
      checked
    }));
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected isTaskHidden(taskId: ObjectId) {
    return this.hiddenTasks.includes(taskId);
  }

  protected getFirst() {
    if (!this.filter) return null;

    const tasks = new FilterTasksPipe()
      .transform(this.selected, this.filter)
      .filter((task) => !this.isTaskHidden(task.id));

    if (tasks.length > 0) {
      return tasks[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      if (this.multiple) {
        first.checked = !first.checked;
      } else {
        this.selected = this.selected.map((task) => ({
          ...task,
          checked: task.id === first.id
        }));

        this.confirmChanges();
      }
    }
  }
}
