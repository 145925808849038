// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getReceivedLedger = (path: TSpecPathGetReceivedLedger) => {
  let res = "/users/:id/ledger-received";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getReceivedLedger = RequestHandler<TSpecPathGetReceivedLedger, any, TSpecBodyGetReceivedLedger, TSpecQueryGetReceivedLedger, {}>;

export interface TSpecPathGetReceivedLedger { id: string; }

export interface TSpecBodyGetReceivedLedger {}

export interface TSpecQueryGetReceivedLedger {
  searchTerm?: string;
  limit?: string;
  page?: string;
  from?: string;
  to?: string;
  AppVersion?: string;
  AppUser?: string;
}
