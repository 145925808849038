<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Announcements </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="pnFlags.isUsersReceiveNotificationsForAnnouncements"
          (ionChange)="
            onPnFlagChange(
              $event,
              'isUsersReceiveNotificationsForAnnouncements'
            )
          "
        >
          <ion-label>
            Users receive Push Notifications for announcements
          </ion-label>
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-select
          labelPlacement="floating"
          aria-label="Pay Frequency"
          interface="popover"
          label="Who can send and edit announcements?"
          [value]="whoCanSendAnnouncements"
          (ionChange)="onWhoCanSendAnnouncementsChange($event)"
          appHaptics
        >
          <ion-select-option value="Admin+Manager">
            Only Managers and Admins
          </ion-select-option>
          <ion-select-option value="Admin"> Only Admins </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..."></ion-loading>
