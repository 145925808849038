import { NgModule } from '@angular/core';
import { SkillMatrixForTaskModalComponent } from './skill-matrix-for-task-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { SkillMatrixSelectModule } from '@app/modules/skill-matrix-status-select/skill-matrix-status-select.module';
import { UserSelectorModalModule } from '../user-selector-modal/user-selector-modal.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    SkillMatrixSelectModule,
    UserSelectorModalModule,
    FullHeightContainerModule
  ],
  exports: [SkillMatrixForTaskModalComponent],
  declarations: [SkillMatrixForTaskModalComponent]
})
export class SkillMatrixForTaskModalModule {}
