// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getPublicSolutionsOfOpportunity = (path: TSpecPathGetPublicSolutionsOfOpportunity) => {
  let res = "/suggestions/opportunities/:opportunityId/solutions/public";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getPublicSolutionsOfOpportunity = RequestHandler<TSpecPathGetPublicSolutionsOfOpportunity, any, TSpecBodyGetPublicSolutionsOfOpportunity, TSpecQueryGetPublicSolutionsOfOpportunity, {}>;

export interface TSpecPathGetPublicSolutionsOfOpportunity { opportunityId: string; }

export interface TSpecBodyGetPublicSolutionsOfOpportunity {}

export interface TSpecQueryGetPublicSolutionsOfOpportunity {
  AppVersion?: string;
  AppUser?: string;
}
