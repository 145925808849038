<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">
      GHL Tag Uploader: {{ tenant.name }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="!isDone">
      <ion-button
        (click)="confirmSyncTags()"
        appHaptics
        [disabled]="!tags.length || tagTotal !== 0"
        [disableHaptics]="!tags.length || tagTotal !== 0"
        color="primary"
      >
        Upload
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container
    class="container-with-fab"
    [formGroup]="mainFormGroup"
  >
    <ion-list *ngIf="!isDone" [inset]="true">
      <ion-item class="note">
        This tool will find all the tags with tag numbers within the specified
        range from this tenant and will upload them to GHL. Please ensure these
        tags are not present in the GHL to avoid duplicates.
      </ion-item>
      <ion-item>
        <ion-input
          label="Tag Number From (included)"
          labelPlacement="floating"
          pattern="[1-9][0-9]*"
          formControlName="tagNumberFrom"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
          placeholder="0"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Tag Number To (included)"
          labelPlacement="floating"
          pattern="[1-9][0-9]*"
          formControlName="tagNumberTo"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
          placeholder="0"
        />
      </ion-item>
      <ion-item button [detail]="false" (click)="syncTags()" appHaptics>
        <ion-label>Find</ion-label>
      </ion-item>
    </ion-list>

    <app-list-pre-line
      *ngIf="tags.length"
      [title]="
        tagTotal ? 'Syncing... ' + (tagCurrent + 1) + ' / ' + tagTotal : 'Tags'
      "
    />
    <ion-list [inset]="true">
      <ion-item
        *ngFor="let tag of tags; let i = index; trackBy: trackTags"
        [color]="
          tag.isSuccess
            ? 'success'
            : tag.isError
            ? 'danger'
            : tag.status === tagStatus.UPLOADED &&
              tag.customerCell &&
              tag.pickupStatus
            ? undefined
            : 'warning'
        "
        class="note"
      >
        <div>
          <ion-label class="ion-text-capitalize">
            #{{ tag.tagNumber }}
          </ion-label>
          <ion-note>{{ tag.customerName }}</ion-note>
          <br />
          <ion-note>
            {{ tag.customerCell }}
          </ion-note>
          <br />
          <ion-note>{{ tag.pickupStatus }}</ion-note>
        </div>
        <ion-note slot="end" class="ion-text-capitalize">
          {{ tag.status.toLowerCase() }}
        </ion-note>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>
