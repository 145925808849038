// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllPsychologicalSurveys = () => "/psychological-survey/all";

export type TSpecHandler_getAllPsychologicalSurveys = RequestHandler<TSpecPathGetAllPsychologicalSurveys, any, TSpecBodyGetAllPsychologicalSurveys, TSpecQueryGetAllPsychologicalSurveys, {}>;

export interface TSpecPathGetAllPsychologicalSurveys {  }

export interface TSpecBodyGetAllPsychologicalSurveys {}

export interface TSpecQueryGetAllPsychologicalSurveys {
  AppVersion?: string;
  AppUser?: string;
}
