<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background" *ngIf="cancelButton === 'menu'">
    <ion-buttons
      slot="start"
      class="ion-hide-lg-up"
      *ngIf="question.orderIndex === 0; else goBackBtn"
    >
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar
    class="app-common-background survey-preview-toolbar"
    *ngIf="cancelButton === 'cancel'"
  >
    <ion-buttons slot="start" *ngIf="question.orderIndex === 0; else goBackBtn">
      <ion-button (click)="onCancelClick()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-width-restricted-container class="question-holder">
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2 class="ion-text-center heading" [innerHTML]="questionTitle"></h2>
      <h5
        class="ion-text-center subheading"
        [innerHTML]="question.params.questionSubtitle"
      ></h5>
    </ion-text>
    <ion-grid class="ion-padding">
      <ion-row
        *ngIf="isLoading; else options"
        class="ion-justify-content-center"
      >
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ng-container *ngTemplateOutlet="confidentialNotice"></ng-container>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="usersWhoAreWorkingToday.length > 0">
        <ion-col size="12" class="current-user">
          {{ currentUser + 1 }} / {{ usersWhoAreWorkingToday.length }}
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6" *ngIf="currentUser > 0">
          <ion-button
            fill="outline"
            color="dark"
            (click)="prevUser()"
            appHaptics
            expand="block"
            [disabled]="isLoading"
            [disableHaptics]="isLoading"
          >
            <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
            {{ question.params.prevUserButtonText }}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button
            color="dark"
            (click)="
              currentUser >= usersWhoAreWorkingToday.length - 1
                ? completeQuestion()
                : nextUser()
            "
            appHaptics
            expand="block"
            [disabled]="isLoading"
            [disableHaptics]="isLoading"
          >
            {{ buttonText }}
            <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<ng-template #goBackBtn>
  <ion-buttons slot="start">
    <ion-button (click)="onBackButtonClick()" appHaptics>
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ng-template>

<ng-template #options>
  <ng-container *ngIf="usersWhoAreWorkingToday.length > 0; else emptyList">
    <ion-row
      class="ion-justify-content-center"
      *ngFor="let option of question.params.options"
    >
      <ion-col size-xs="12" size-sm="6">
        <app-emoji-button
          [label]="option.text"
          [emoji]="option.emoji"
          appHaptics
          (click)="toggleEvaluation(option.text)"
          [selected]="optionIsSelected(option.text)"
        />
      </ion-col>
    </ion-row>
  </ng-container>
</ng-template>

<ng-template #emptyList>
  <app-empty-stub
    icon="calendar-outline"
    text="It seems that no one has worked with you recently"
  />
</ng-template>

<ng-template #confidentialNotice>
  <div class="confidential-notice ion-text-center ion-padding-horizontal">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9254 10.6448C14.2154 10.8924 14.247 11.325 13.996 11.6111L9.18694 17.0917C9.06154 17.2346 8.88186 17.3201 8.69041 17.3278C8.49897 17.3356 8.31278 17.265 8.17591 17.1327L6.0405 15.0689C5.76653 14.8041 5.76202 14.3704 6.03041 14.1001C6.29881 13.8299 6.73849 13.8254 7.01245 14.0902L8.62025 15.644L12.9459 10.7144C13.1969 10.4283 13.6354 10.3971 13.9254 10.6448Z"
        fill="#FFD500"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70601 4.89704C9.89292 4.81541 10.1071 4.81541 10.294 4.89704C13.8452 6.44819 15.4393 6.94377 19.3715 7.62597C19.6952 7.68214 19.9375 7.94493 19.9582 8.26245C20.7131 19.8344 11.0034 23.8422 10.2668 24.1291C10.0956 24.1958 9.90438 24.1958 9.73317 24.1291C8.99664 23.8422 -0.713092 19.8344 0.0418037 8.26245C0.0625171 7.94493 0.304764 7.68214 0.628545 7.62597C4.5607 6.94377 6.15484 6.44819 9.70601 4.89704ZM1.44032 8.88723C1.10353 18.3125 8.40815 22.0379 10 22.7361C11.5918 22.0379 18.8965 18.3125 18.5597 8.88723C15.0063 8.24921 13.2875 7.70888 10 6.28417C6.71248 7.70888 4.99373 8.24921 1.44032 8.88723Z"
        fill="url(#paint0_linear_4818_6698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4818_6698"
          x1="0"
          y1="7.25373"
          x2="19.4185"
          y2="24.8218"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE100" />
          <stop offset="1" stop-color="#FFC800" />
        </linearGradient>
      </defs>
    </svg>
    <ion-text>
      <p class="confidential-notice-text">
        {{ question.params.noticeText.replace('${period}', periodText) }}
      </p>
    </ion-text>
  </div>
</ng-template>
