// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getDepartments = () => "/departments";

export type TSpecHandler_getDepartments = RequestHandler<TSpecPathGetDepartments, any, TSpecBodyGetDepartments, TSpecQueryGetDepartments, {}>;

export interface TSpecPathGetDepartments {  }

export interface TSpecBodyGetDepartments {}

export interface TSpecQueryGetDepartments {
  AppVersion?: string;
  AppUser?: string;
}
