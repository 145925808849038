// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllUsersWithDeleted = (path: TSpecPathGetAllUsersWithDeleted) => {
  let res = "/tenants/:tenantId/users-with-deleted";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAllUsersWithDeleted = RequestHandler<TSpecPathGetAllUsersWithDeleted, any, TSpecBodyGetAllUsersWithDeleted, TSpecQueryGetAllUsersWithDeleted, {}>;

export interface TSpecPathGetAllUsersWithDeleted { tenantId: string; }

export interface TSpecBodyGetAllUsersWithDeleted {}

export interface TSpecQueryGetAllUsersWithDeleted {
  AppVersion?: string;
  AppUser?: string;
}
