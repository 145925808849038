<app-width-restricted-container
  [maxWidth]="maxWidth"
  class="top-content"
  [ngClass]="{
    'modal-body': isModal,
    'virtual-scroll-container': isVirtualScroll,
    'with-vertical-padding': withVerticalPadding
  }"
>
  <ng-content></ng-content>
</app-width-restricted-container>

<app-width-restricted-container [maxWidth]="maxWidth" class="bottom-content">
  <ng-content select="[bottom]"></ng-content>
</app-width-restricted-container>
