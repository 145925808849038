import { Component, Input } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { ModalController } from '@ionic/angular';
import { skillMatrixStatuses } from '@app/utils/skill-matrix';
import { BehaviorSubject } from 'rxjs';
import { showUserSelectorModal } from '@app/utils/modal';
import { ModalClass } from '@app/core/class/modal.class';

@Component({
  selector: 'app-skill-matrix-for-task-modal',
  templateUrl: './skill-matrix-for-task-modal.component.html',
  styleUrls: ['./skill-matrix-for-task-modal.component.scss']
})
export class SkillMatrixForTaskModalComponent extends ModalClass {
  @Input()
  public readonly allUsers: UserModel[] = [];

  @Input()
  public skillMatrix: { status: ESkillMatrixStatus; user: ObjectId }[] = [];

  @Input()
  public skillMatrixSubject: BehaviorSubject<
    { status: ESkillMatrixStatus; user: ObjectId }[]
  >;

  protected readonly statuses = skillMatrixStatuses;

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected trackItems(_: number, user: UserModel) {
    return user._id;
  }

  protected getUserStatus(user: UserModel) {
    return (
      this.skillMatrix.find(
        (smu) => smu.user.toString() === user._id.toString()
      )?.status || ESkillMatrixStatus.CANNOT_PERFORM
    );
  }

  protected onStatusChange(status: ESkillMatrixStatus, user: UserModel) {
    if (this.skillMatrix.find((smu) => smu.user === user._id)) {
      this.skillMatrix = this.skillMatrix.map((smu) =>
        smu.user === user._id ? { ...smu, status } : smu
      );
    } else {
      this.skillMatrix = [...this.skillMatrix, { user: user._id, status }];
    }

    this.emitSkillMatrix();
  }

  protected emitSkillMatrix() {
    this.skillMatrixSubject.next(this.skillMatrix);
  }

  protected async selectUsersForGroup(skillMatrixStatus: ESkillMatrixStatus) {
    const usersIdsOfSelectedStatus = this.getUsersOfStatus(
      skillMatrixStatus
    ).map(({ _id }) => _id);
    const selectedStatusTitle = this.statuses.find(
      (s) => s.status === skillMatrixStatus
    ).title;

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        recipients: usersIdsOfSelectedStatus,
        users: this.allUsers,
        title: selectedStatusTitle,
        multiple: true
      },
      this._modalCtrl,
      { parentModalName: this._modalName }
    );

    if (success) {
      selectedUsers.map((v) => {
        const foundUser = this.skillMatrix.find((u) => u.user === v);
        if (foundUser) {
          foundUser.status = skillMatrixStatus;
        } else {
          this.skillMatrix.push({ user: v, status: skillMatrixStatus });
        }
      });

      this.emitSkillMatrix();
    }
  }

  protected getUsersOfStatus(status: ESkillMatrixStatus) {
    if (status === ESkillMatrixStatus.CANNOT_PERFORM) {
      return this.allUsers.filter((user) => {
        const exists = this.skillMatrix.find((smu) => user._id === smu.user);

        if (exists) {
          return exists.status === ESkillMatrixStatus.CANNOT_PERFORM;
        } else {
          return true;
        }
      });
    }

    return this.allUsers.filter((user) => {
      return (
        this.skillMatrix.find((smu) => user._id === smu.user)?.status === status
      );
    });
  }
}
