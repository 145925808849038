// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCompensationPools = () => "/compensation/pools";

export type TSpecHandler_getCompensationPools = RequestHandler<TSpecPathGetCompensationPools, any, TSpecBodyGetCompensationPools, TSpecQueryGetCompensationPools, {}>;

export interface TSpecPathGetCompensationPools {  }

export interface TSpecBodyGetCompensationPools {}

export interface TSpecQueryGetCompensationPools {
  AppVersion?: string;
  AppUser?: string;
}
