// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteSuggestionCategory = (path: TSpecPathDeleteSuggestionCategory) => {
  let res = "/suggestion-categories/:suggestionCategoryId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteSuggestionCategory = RequestHandler<TSpecPathDeleteSuggestionCategory, any, TSpecBodyDeleteSuggestionCategory, TSpecQueryDeleteSuggestionCategory, {}>;

export interface TSpecPathDeleteSuggestionCategory { suggestionCategoryId: string; }

export interface TSpecBodyDeleteSuggestionCategory {}

export interface TSpecQueryDeleteSuggestionCategory {
  AppVersion?: string;
  AppUser?: string;
}
