<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Pulse </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item
        [lines]="
          dailySurveySettings.dailySurveyMode !==
          dailySurveyMode.PUSH_NOTIFICATION
            ? 'none'
            : undefined
        "
      >
        <ion-select
          labelPlacement="floating"
          aria-label="Survey Mode"
          interface="popover"
          label="Survey Mode"
          [value]="dailySurveySettings.dailySurveyMode"
          (ionChange)="onDailySurveyModeChange($event)"
          appHaptics
        >
          <ion-select-option [value]="dailySurveyMode.REQUIRED">
            On Start
          </ion-select-option>
          <ion-select-option [value]="dailySurveyMode.PUSH_NOTIFICATION">
            End of Shift
          </ion-select-option>
          <ion-select-option [value]="dailySurveyMode.OFF">
            Off
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item
        [attr.data-hidden]="
          dailySurveySettings.dailySurveyMode !==
          dailySurveyMode.PUSH_NOTIFICATION
        "
        button
        appHaptics
        id="selectSurveyTime"
      >
        Prompt users {{ currentSurveyTimeText }} prior shift end
      </ion-item>
      <ion-item
        [attr.data-hidden]="
          dailySurveySettings.dailySurveyMode !==
          dailySurveyMode.PUSH_NOTIFICATION
        "
      >
        <ion-toggle
          appHaptics
          [(ngModel)]="emailFlags.isSendingDailySurveyEmailsEnabled"
          (ionChange)="
            onEmailFlagChange($event, 'isSendingDailySurveyEmailsEnabled')
          "
        >
          <ion-label> Email the users to complete the survey </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>
    <app-list-post-line [text]="postText" />

    <ion-list
      [attr.data-hidden]="
        dailySurveySettings.dailySurveyMode === dailySurveyMode.OFF
      "
      [inset]="true"
    >
      <ion-item>
        <ion-select
          labelPlacement="floating"
          aria-label="Survey Frequency"
          interface="popover"
          label="Survey Frequency"
          [value]="dailySurveySettings.frequency"
          (ionChange)="onDailySurveyFrequencyChange($any($event))"
          appHaptics
        >
          <ion-select-option [value]="dailySurveyFrequency.EVERY_DAY">
            Every day
          </ion-select-option>
          <ion-select-option [value]="dailySurveyFrequency.EVERY_WEEK">
            Every week
          </ion-select-option>
          <ion-select-option [value]="dailySurveyFrequency.EVERY_TWO_WEEKS">
            Every two weeks
          </ion-select-option>
          <ion-select-option [value]="dailySurveyFrequency.ONCE_PER_MONTH">
            Once per month
          </ion-select-option>
        </ion-select>
      </ion-item>
      <app-date-form-control
        *ngIf="dailySurveySettings.frequency !== dailySurveyFrequency.EVERY_DAY"
        [isItem]="true"
        label="The next feedback pulse will occur on"
        [(ngModel)]="dailySurveySettings.date1"
        [dateTransformer]="
          dailySurveySettings.frequency | transformDailySurveyFrequency
        "
        [min]="dailySurveyFrequencyMin"
        [max]="dailySurveyFrequecnyMax"
        (ngModelChange)="saveDailySurveyDates()"
      />
    </ion-list>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />

<ion-picker
  trigger="selectSurveyTime"
  [columns]="surveyTimePickerColumns"
  [buttons]="surveyTimePickerButtons"
  (willDismiss)="onDailySurveyPNTimeChange($event)"
/>
