<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Security & Permissions </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <div [attr.data-hidden]="!tenantFeatures.insights">
      <app-list-pre-line title="Permissions" />
      <ion-list [inset]="true">
        <ion-item button (click)="onSelectInsightsUsersClick()" appHaptics>
          <ion-label>Which users can access the insights page?</ion-label>
          <ion-note slot="end">
            <ng-container *ngIf="allUsers | appAllowedInsights as allowList">
              <ng-container
                *ngIf="
                  allowList.length === allUsers.length;
                  else someUsersCaption
                "
              >
                All Users
              </ng-container>
              <ng-template #someUsersCaption>
                {{ allowList.length }} users selected
              </ng-template>
            </ng-container>
          </ion-note>
        </ion-item>
      </ion-list>
      <app-list-post-line
        text="Only selected users will be allowed to view the insights page"
      />
    </div>

    <div
      [attr.data-hidden]="
        !(tenantFeatures.multiUserMode || isMultiUserModeActive)
      "
    >
      <app-list-pre-line title="Multi-User Mode" />
      <ion-list [inset]="true">
        <ion-item button appHaptics id="showPasscodes">
          Show Passcodes
        </ion-item>
        <ion-item
          button
          appHaptics
          (click)="toggleMultiUserMode()"
          [color]="isMultiUserModeActive ? 'danger' : 'primary'"
        >
          <ion-label>{{
            isMultiUserModeActive
              ? 'Deactivate Multi-User Mode'
              : 'Activate Multi-User Mode'
          }}</ion-label>
        </ion-item>
        <ion-item *ngIf="!isMultiUserModeActive">
          <ion-select
            labelPlacement="floating"
            aria-label="After Entering The Passcode, User Goes to"
            interface="popover"
            label="After Entering The Passcode, User Goes to"
            [value]="passcodeRedirectsTo"
            (ionChange)="onPasscodeRedirectsToChange($any($event))"
            appHaptics
          >
            <ion-select-option value="/i/recognition">
              Recognition
            </ion-select-option>
            <ion-select-option
              value="/i/checklist"
              *ngIf="tenantFeatures.checklist"
            >
              Checklist
            </ion-select-option>
            <ion-select-option value="/i/tags" *ngIf="tenantFeatures.tags">
              Tags
            </ion-select-option>
            <ion-select-option
              value="/i/announcements"
              *ngIf="tenantFeatures.announcements"
            >
              Announcements
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item *ngIf="!isMultiUserModeActive">
          <ion-select
            labelPlacement="floating"
            aria-label="Passcode Timeout"
            interface="popover"
            label="Passcode Timeout"
            [value]="passcodeTimeout"
            (ionChange)="onPasscodeTimeoutChange($any($event))"
            appHaptics
          >
            <ion-select-option [value]="60"> 1 minute </ion-select-option>
            <ion-select-option [value]="180"> 3 minutes </ion-select-option>
            <ion-select-option [value]="300"> 5 minutes </ion-select-option>
            <ion-select-option [value]="600"> 10 minutes </ion-select-option>
            <ion-select-option [value]="900"> 15 minutes </ion-select-option>
            <ion-select-option [value]="1200"> 20 minutes </ion-select-option>
            <ion-select-option [value]="1800"> 30 minutes </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <app-list-post-line text="Access the app by unique user passcode" />
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..."></ion-loading>

<ion-modal
  trigger="showPasscodes"
  #passcodesListModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-passcodes-list-modal
      class="ion-page"
      [passcodeRedirectsTo]="passcodeRedirectsTo"
      [passcodeTimeout]="passcodeTimeout"
    />
  </ng-template>
</ion-modal>
