import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { formatDate } from '@angular/common';
import { ModalClass } from '@app/core/class/modal.class';

@Component({
  selector: 'app-acknowledgments-modal',
  templateUrl: './acknowledgments-modal.component.html',
  styleUrls: ['./acknowledgments-modal.component.scss']
})
export class AcknowledgmentsModalComponent extends ModalClass {
  @Input()
  public readonly announcement: AnnouncementModel;

  @Input()
  public readonly title: string = 'Acknowledgments';

  @Input()
  public readonly acknowledgedLabel: string = 'Acknowledged';

  public readonly allUsers$: Observable<UserModel[]> =
    this._userListService.users$;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService
  ) {
    super();
  }

  get acknowledged() {
    return this.announcement.recipients.filter((a) => a.acknowledged);
  }

  get read() {
    return this.announcement.recipients.filter(
      (a) => a.read && !a.acknowledged
    );
  }

  get unread() {
    return this.announcement.recipients.filter(
      (a) => !a.read && !a.acknowledged
    );
  }

  protected fDateAndTime(date: Date) {
    return formatDate(date, 'MMM dd, yyyy h:mm a', 'en-US');
  }

  protected onCloseClick() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }
}
