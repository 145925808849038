<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Select Value</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="learnMore()" color="primary" appHaptics>
        Learn More
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-searchbar
      #ionSearchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    />
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true" [isFullHeight]="true">
    <ion-grid *ngIf="isLoading; else list">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>
  </app-full-height-container>
</ng-template>

<ng-template #list>
  <ion-list>
    <ion-item
      button
      *ngFor="
        let behavior of behaviors | filterBehaviors : filter;
        trackBy: trackItems
      "
      (click)="itemClick(behavior._id)"
      appHaptics
      [ngClass]="{ 'highlighted-item': getFirst()?._id === behavior._id }"
    >
      <ion-label>
        {{ behavior.name }}
      </ion-label>
    </ion-item>
  </ion-list>
</ng-template>
