// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_readAnnouncement = (path: TSpecPathReadAnnouncement) => {
  let res = "/announcements/:id/read";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_readAnnouncement = RequestHandler<TSpecPathReadAnnouncement, any, TSpecBodyReadAnnouncement, TSpecQueryReadAnnouncement, {}>;

export interface TSpecPathReadAnnouncement { id: string; }

export interface TSpecBodyReadAnnouncement {}

export interface TSpecQueryReadAnnouncement {
  AppVersion?: string;
  AppUser?: string;
}
