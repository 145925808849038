<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Task History</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <div
      *ngFor="let performance of taskPerformances; trackBy: trackItems"
      class="ion-padding"
    >
      <div class="performance-main">
        <div class="performance-date">
          {{ performance.completeTime | date : 'M/d/yy h:mm a' }}
        </div>
        <div class="performance-user">
          {{ performance.user.firstName }} {{ performance.user.lastName }}
        </div>
      </div>
      <p class="performance-note" *ngIf="performance.note">
        Note: <span>{{ performance.note.body | wysiwygPreview }}</span>
      </p>
    </div>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="!(taskPerformances.length === 0 || isLoading || !hasNextPage)"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content />
    </ion-infinite-scroll>

    <ng-container *ngIf="taskPerformances.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList" />
    </ng-container>
  </app-full-height-container>
</ng-template>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty" />
</ng-template>
