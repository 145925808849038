import { Component, Input, OnInit } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ModalController } from '@ionic/angular';
import {
  ICompensationPool,
  ECompensationPoolType
} from '@backend/models/types/compensation-pool';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { UserListService } from '@app/core/service/user-list.service';
import fastDeepEqual from 'fast-deep-equal';
import { ModalClass } from '@app/core/class/modal.class';
import { showUserSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-compensation-pool-modal',
  templateUrl: './compensation-pool-modal.component.html'
})
export class CompensationPoolModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public readonly compensationPool: MongoStoredObject<ICompensationPool> = null;

  protected readonly compensationPoolType = ECompensationPoolType;

  protected allUsers: UserModel[] = [];

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    type: new FormControl<ECompensationPoolType>(
      ECompensationPoolType.CURRENCY,
      Validators.required
    ),
    users: new FormControl<ObjectId[]>([])
  });

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });

    if (this.compensationPool) {
      this.form.reset({
        name: this.compensationPool.name,
        type: this.compensationPool.type,
        users: this.compensationPool.users.map((u) => u.toString() as ObjectId)
      });
    }
  }

  protected get selectedUserIds() {
    return this.form.controls.users.value;
  }

  protected get formattedRecipients(): string {
    if (this.selectedUserIds.length === 0) {
      return 'Select Recipients';
    } else if (this.selectedUserIds.length === 1) {
      const user = this.allUsers.find(
        (user) => this.selectedUserIds[0] === user._id
      );
      return user ? `${user.firstName} ${user.lastName}` : '';
    } else if (
      this.selectedUserIds.length > 1 &&
      this.selectedUserIds.length !== this.allUsers.length
    ) {
      return `${this.selectedUserIds.length} recipients`;
    } else if (this.selectedUserIds.length === this.allUsers.length) {
      return 'All Users';
    }
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected save(): void {
    this._modalCtrl.dismiss(this.form.value, 'confirm', this._modalName);
  }

  protected delete(): void {
    this._modalCtrl.dismiss(this.form.value, 'delete', this._modalName);
  }

  protected get canSave() {
    if (this.form.invalid) return false;

    if (this.compensationPool) {
      return !fastDeepEqual(
        {
          name: this.compensationPool.name,
          type: this.compensationPool.type,
          users: this.compensationPool.users
        },
        this.form.value
      );
    }

    return true;
  }

  protected async selectUsers() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.selectedUserIds,
        multiple: true
      },
      this._modalCtrl,
      { parentModalName: this._modalName }
    );

    if (success) {
      this.form.controls.users.setValue(selectedUsers);
    }
  }
}
