import {
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding
} from '@angular/core';
import { OpportunityModel } from '@app/core/model/suggestion.model';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '@app/core';
import { ESuggestionStatus } from '@backend/models/types/suggestion';

@Component({
  selector: 'app-opportunity-item',
  templateUrl: './opportunity-item.component.html',
  styleUrls: ['./opportunity-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityItemComponent {
  @Input()
  public opportunity!: OpportunityModel;

  @Input()
  public animationOrder?: number = -1;

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @HostBinding('class.unread')
  public get isUnread() {
    return !this.opportunity.readBy.find(
      (r) => r.toString() === this._authenticationService.user._id.toString()
    );
  }

  public constructor(
    private readonly _sanitizer: DomSanitizer,
    private readonly _authenticationService: AuthenticationService
  ) {}

  protected get authorName(): string {
    return this.opportunity.author
      ? `${this.opportunity.author.firstName} ${this.opportunity.author.lastName}`
      : 'Anonymous';
  }

  protected get solutionsText() {
    if (this.opportunity.solutionsCount === 1) {
      return '1 solution';
    }
    return `${this.opportunity.solutionsCount} solutions`;
  }

  protected get statusColor() {
    switch (this.opportunity.status) {
      case ESuggestionStatus.AWAITING_REVIEW:
        return 'primary';
      case ESuggestionStatus.IN_QUEUE:
        return 'tertiary';
      case ESuggestionStatus.IN_PROGRESS:
        return 'warning';
      case ESuggestionStatus.RESOLVED:
        return 'success';
    }
  }
}
