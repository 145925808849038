// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllUsersWithPinCodes = (path: TSpecPathGetAllUsersWithPinCodes) => {
  let res = "/tenants/:tenantId/users-with-pincodes";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAllUsersWithPinCodes = RequestHandler<TSpecPathGetAllUsersWithPinCodes, any, TSpecBodyGetAllUsersWithPinCodes, TSpecQueryGetAllUsersWithPinCodes, {}>;

export interface TSpecPathGetAllUsersWithPinCodes { tenantId: string; }

export interface TSpecBodyGetAllUsersWithPinCodes {}

export interface TSpecQueryGetAllUsersWithPinCodes {
  AppVersion?: string;
  AppUser?: string;
}
