<form [formGroup]="form">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-select
          formControlName="scheduleType"
          fill="solid"
          aria-label="Task repeat"
          interface="popover"
          class="app-w100 input-with-background select-round-fix"
          (ionChange)="onScheduleTypeChange($event)"
          appHaptics
        >
          <ion-select-option [value]="taskScheduleType.NEVER">
            As needed
          </ion-select-option>
          <ion-select-option [value]="taskScheduleType.DAILY">
            Daily
          </ion-select-option>
          <ion-select-option [value]="taskScheduleType.WEEKLY">
            Weekly
          </ion-select-option>
          <ion-select-option [value]="taskScheduleType.SPECIFIC_DATES">
            On specific dates
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="no-padding">
        <ng-container [ngSwitch]="form.get('scheduleType').value">
          <app-schedule-never-form
            *ngSwitchCase="taskScheduleType.NEVER"
            [form]="form"
            class="ion-padding-top"
          />
          <app-schedule-weekly
            *ngSwitchCase="taskScheduleType.WEEKLY"
            [form]="form"
            class="ion-padding-top"
          />
          <app-schedule-specific-dates
            *ngSwitchCase="taskScheduleType.SPECIFIC_DATES"
            [form]="form"
            class="ion-padding-top"
          />
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
