// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getRecognitionReminders = () => "/recognition/reminders";

export type TSpecHandler_getRecognitionReminders = RequestHandler<TSpecPathGetRecognitionReminders, any, TSpecBodyGetRecognitionReminders, TSpecQueryGetRecognitionReminders, {}>;

export interface TSpecPathGetRecognitionReminders {  }

export interface TSpecBodyGetRecognitionReminders {}

export interface TSpecQueryGetRecognitionReminders {
  AppVersion?: string;
  AppUser?: string;
}
