// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateSuggestionCategory = (path: TSpecPathUpdateSuggestionCategory) => {
  let res = "/suggestion-categories/:suggestionCategoryId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateSuggestionCategory = RequestHandler<TSpecPathUpdateSuggestionCategory, any, TSpecBodyUpdateSuggestionCategory, TSpecQueryUpdateSuggestionCategory, {}>;

export interface TSpecPathUpdateSuggestionCategory { suggestionCategoryId: string; }

export interface TSpecBodyUpdateSuggestionCategory {
  name: string;
  notifyUsersOnNewOpportunities?: string[];
  notifyUsersOnNewSolutions?: string[];
  notifyUsersOnOpportunityStatusChange?: string[];
}

export interface TSpecQueryUpdateSuggestionCategory {
  AppVersion?: string;
  AppUser?: string;
}
