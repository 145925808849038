// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateCompensationPool = (path: TSpecPathUpdateCompensationPool) => {
  let res = "/compensation/pools/:compensationPoolId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateCompensationPool = RequestHandler<TSpecPathUpdateCompensationPool, any, TSpecBodyUpdateCompensationPool, TSpecQueryUpdateCompensationPool, {}>;

export interface TSpecPathUpdateCompensationPool { compensationPoolId: string; }

export interface TSpecBodyUpdateCompensationPool {
  name: string;
  type: ECompensationPoolType;
  users: string[];
}

export enum ECompensationPoolType {
  CURRENCY = "CURRENCY",
  HOURS = "HOURS",
  OTHER = "OTHER"
}

export interface TSpecQueryUpdateCompensationPool {
  AppVersion?: string;
  AppUser?: string;
}
