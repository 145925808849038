// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_softDelete = (path: TSpecPathSoftDelete) => {
  let res = "/users/:id/soft-delete";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_softDelete = RequestHandler<TSpecPathSoftDelete, any, TSpecBodySoftDelete, TSpecQuerySoftDelete, {}>;

export interface TSpecPathSoftDelete { id: string; }

export interface TSpecBodySoftDelete {
  isDeleted: boolean;
}

export interface TSpecQuerySoftDelete {
  AppVersion?: string;
  AppUser?: string;
}
