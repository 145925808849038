<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true" [withVerticalPadding]="true">
    <div *ngIf="allUsers$ | async as allUsers" class="ion-padding-horizontal">
      <h4 class="acknowledgments-modal__list-title" *ngIf="acknowledged.length">
        {{ acknowledgedLabel }}
      </h4>
      <ul class="acknowledgments-modal__list">
        <li
          class="acknowledgments-modal__list-item"
          *ngFor="let person of acknowledged; let i = index"
        >
          <ion-icon
            name="checkmark-circle"
            class="acknowledgments-modal__list-item-icon acknowledgments-modal__list-item-icon--accent"
          />
          <span class="acknowledgments-modal__list-item-text">
            {{ person.id | personName$ : person.recipientName | async }}
          </span>
          <span class="acknowledgments-modal__list-item-date">
            {{ fDateAndTime(person.acknowledged) }}
          </span>
        </li>
      </ul>
      <h4 class="acknowledgments-modal__list-title" *ngIf="read.length">
        Read
      </h4>
      <ul class="acknowledgments-modal__list">
        <li
          class="acknowledgments-modal__list-item"
          *ngFor="let person of read; let i = index"
        >
          <ion-icon name="eye" class="acknowledgments-modal__list-item-icon" />
          <span class="acknowledgments-modal__list-item-text">
            {{ person.id | personName$ : person.recipientName | async }}
          </span>
          <span class="acknowledgments-modal__list-item-date">
            {{ fDateAndTime(person.read) }}
          </span>
        </li>
      </ul>
      <h4 class="acknowledgments-modal__list-title" *ngIf="unread.length">
        Unread
      </h4>
      <ul class="acknowledgments-modal__list">
        <li
          class="acknowledgments-modal__list-item"
          *ngFor="let person of unread; let i = index"
        >
          <ion-icon
            name="eye-off"
            class="acknowledgments-modal__list-item-icon"
          />
          <span class="acknowledgments-modal__list-item-text">
            {{ person.id | personName$ : person.recipientName | async }}
          </span>
        </li>
      </ul>
    </div>
  </app-full-height-container>
</ng-template>
