import { Component, OnInit, NgZone } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { ModalController } from '@ionic/angular';
import { WarnIeDialogComponent } from './modules/warn-ie-dialog/warn-ie-dialog.component';
import { BackdoorApiService } from './core/service/api/backdoor.api.service';
import { BackdoorInfoModalComponent } from '@app/modals/backdoor-info-modal/backdoor-info-modal.component';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { AuthenticationService, LocalStorageService } from './core';
import { Router } from '@angular/router';
import { PasscodeTimeoutService } from './core/service/passcode-timeout.service';
import { WhatsNewService } from './core/service/whats-new.service';
import { TodaysShiftApiService } from './core/service/api/todaysShift.api.service';
import { TenantApiService } from './core/service/api/tenant.api.service';
import { add } from 'date-fns';
import { filter, interval, switchMap, take } from 'rxjs';
import { EDailySurveyMode } from '@backend/types/daily-survey-definition';
import { BackdoorEulaModalComponent } from '@app/modals/backdoor-eula-modal/backdoor-eula-modal.component';
import { IBackdoorEula } from '@backend/models/types/backdoor-eula';
import { Device } from '@capacitor/device';
import { EnvironmentService } from './core/service/environment.service';
import { showModal } from './utils/modal';
import { MongoStoredObject } from './types/mongo-stored-object';

const WHITELISTED_IDLE_PAGES = ['/login', '/pin', '/404'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  protected timeToIdle = 5;
  protected timeToIdleLeft = this.timeToIdle;
  protected whatsNewShown = false;
  protected isInstantFeedbackEnabled = false;
  protected lastPauseTime: Date = null;
  protected appIsFocused = false;
  protected deviceInfo = {
    version: '',
    platform: '',
    manufacturer: '',
    model: '',
    osVersion: '',
    operatingSystem: ''
  };

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _backdoorApiService: BackdoorApiService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _router: Router,
    private readonly _passcodeTimeoutService: PasscodeTimeoutService,
    private readonly _whatsNewService: WhatsNewService,
    private readonly _todaysShiftApiService: TodaysShiftApiService,
    private readonly _ngZone: NgZone,
    private readonly _tenantApiService: TenantApiService,
    private readonly _environmentService: EnvironmentService
  ) {
    this._passcodeTimeoutService.timeToIdle$.subscribe((timeToIdle) => {
      this.timeToIdle = timeToIdle;
    });
    this._passcodeTimeoutService.timeToIdleLeft$.subscribe((timeToIdleLeft) => {
      this.timeToIdleLeft = timeToIdleLeft;
    });

    this._passcodeTimeoutService.initPasscodeTimeout();

    App.addListener('pause', () => {
      this.appIsFocused = false;
      this.lastPauseTime = new Date();
    });

    App.addListener('resume', () => {
      this.appIsFocused = true;

      if (
        new Date().valueOf() > add(this.lastPauseTime, { hours: 1 }).valueOf()
      ) {
        if (this._authenticationService.user) {
          this._tenantApiService
            .getTenantById({
              path: { id: this._authenticationService.user.tenant }
            })
            .pipe(take(1))
            .subscribe(async (tenant) => {
              if (tenant.features.askTodaysShiftTime) {
                await this._todaysShiftApiService.askTodaysShift();
              }
            });

          this._whatsNewService.checkWhatsNew();
        }
      }

      this._backdoorApiService
        .getBackdoorState({ query: this.deviceInfo })
        .pipe(take(1))
        .subscribe(({ currentEula, currentInfo }) => {
          this._showBackdoorEula(currentEula);
          this._showBackdoorInfo(currentInfo);
        });
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._ngZone.run(() => {
        try {
          const domain = 'valchemy.app';
          let queryParams: object = {};

          const pathArray = event.url.split(domain);

          let appPath = pathArray.pop();

          if (appPath) {
            if (appPath.includes('?')) {
              const pathArray = appPath.split('?');

              if (pathArray.length > 1) {
                appPath = pathArray.shift();

                const params = new URLSearchParams(pathArray.pop());
                const entries = params.entries();
                queryParams = Object.fromEntries(entries);
              }
            }

            if (appPath.includes('#')) {
              const pathArray = appPath.split('#');

              if (pathArray.length > 1) {
                appPath = pathArray.pop();
              }
            }

            this._router.navigate([appPath], { queryParams });
          }
        } catch (e) {
          console.log('appUrlOpen error', e);
        }
      });
    });
  }

  protected get showIdleTimer() {
    const multiUserMode = !!this._localStorageService.getItem('multiUserMode');

    return multiUserMode && !WHITELISTED_IDLE_PAGES.includes(this._router.url);
  }

  protected get timeToIdleProgress() {
    return this.timeToIdleLeft / this.timeToIdle;
  }

  public async ngOnInit() {
    Device.getInfo().then(async (deviceInfo) => {
      this.deviceInfo = {
        version: this._environmentService.version,
        platform: deviceInfo.platform,
        manufacturer: deviceInfo.manufacturer,
        model: deviceInfo.model,
        osVersion: deviceInfo.osVersion,
        operatingSystem: deviceInfo.operatingSystem
      };

      if (Capacitor.getPlatform() !== 'web') {
        await this._initStatusBar();

        if (Capacitor.getPlatform() === 'ios') {
          await Keyboard.setScroll({ isDisabled: true });
        }
      } else {
        const browserSupported = await this._checkBrowserSupport();

        if (browserSupported) {
          await defineCustomElements(window);
        }
      }

      this.appIsFocused = true;
      interval(60 * 1000) // 1 minute
        .pipe(
          filter(() => this.appIsFocused),
          switchMap(() =>
            this._backdoorApiService.getBackdoorState({
              query: this.deviceInfo
            })
          )
        )
        .subscribe(({ currentEula, currentInfo }) => {
          this._showBackdoorEula(currentEula);
          this._showBackdoorInfo(currentInfo);
        });

      this._authenticationService.user$.subscribe(async (user) => {
        if (user) {
          this._authenticationService.refreshNotificationToken();

          this._tenantApiService
            .getTenantById({ path: { id: user.tenant } })
            .pipe(take(1))
            .subscribe(async (tenant) => {
              if (tenant.features.askTodaysShiftTime) {
                await this._todaysShiftApiService.askTodaysShift(
                  tenant.features.survey &&
                    tenant.settings.dailySurvey?.dailySurveyMode ===
                      EDailySurveyMode.PUSH_NOTIFICATION
                    ? tenant.settings.dailySurvey?.dailySurveyPNTime
                    : undefined
                );
              }

              this.isInstantFeedbackEnabled = tenant.features.instantFeedback;
            });

          this._whatsNewService.checkWhatsNew();

          setTimeout(() => {
            this._backdoorApiService
              .getBackdoorState({ query: this.deviceInfo })
              .pipe(take(1))
              .subscribe(({ currentEula, currentInfo }) => {
                this._showBackdoorEula(currentEula);
                this._showBackdoorInfo(currentInfo);
              });
          }, 1000);
        }
      });
    });
  }

  private async _showBackdoorInfo(
    backdoorInfo?: MongoStoredObject<IBackdoorInfo>
  ) {
    if (backdoorInfo) {
      showModal(
        {
          component: BackdoorInfoModalComponent,
          props: { backdoorInfo }
        },
        this._modalCtrl,
        {
          useTopModalAsParent: true,
          canDismiss: backdoorInfo.canDismiss,
          backdropDismiss: backdoorInfo.canDismiss
        }
      );
    }
  }

  private async _showBackdoorEula(
    backdoorEula?: MongoStoredObject<IBackdoorEula>
  ) {
    if (backdoorEula) {
      showModal(
        {
          component: BackdoorEulaModalComponent,
          props: { backdoorEula }
        },
        this._modalCtrl,
        {
          useTopModalAsParent: true,
          canDismiss: async (_, role) => role === 'confirm',
          backdropDismiss: false
        }
      );
    }
  }

  private async _checkBrowserSupport() {
    try {
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
        window.navigator.userAgent
      );

      if (isIEOrEdge) {
        const modal = await this._modalCtrl.create({
          id: 'warn-ie-dialog',
          component: WarnIeDialogComponent,
          cssClass: 'modal-auto-height',
          breakpoints: [0, 1],
          initialBreakpoint: 1,
          backdropDismiss: false,
          handle: false
        });
        modal.present();

        console.log('IE detected. Browser not supported.');

        return false;
      }

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  private async _initStatusBar() {
    try {
      await StatusBar.setOverlaysWebView({ overlay: true });
      await StatusBar.setStyle({ style: Style.Light });

      const { insets } = await SafeArea.getSafeAreaInsets();

      if (Capacitor.getPlatform() === 'android') {
        document.documentElement.style.setProperty(
          '--ion-safe-area-top',
          insets.top + 'px'
        );
        document.documentElement.style.setProperty(
          '--ion-safe-area-bottom',
          insets.bottom + 'px'
        );
        document.documentElement.style.setProperty(
          '--ion-safe-area-left',
          insets.left + 'px'
        );
        document.documentElement.style.setProperty(
          '--ion-safe-area-right',
          insets.right + 'px'
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
}
