// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCurrentPsychologicalSurvey = () => "/psychological-survey/current";

export type TSpecHandler_getCurrentPsychologicalSurvey = RequestHandler<TSpecPathGetCurrentPsychologicalSurvey, any, TSpecBodyGetCurrentPsychologicalSurvey, TSpecQueryGetCurrentPsychologicalSurvey, {}>;

export interface TSpecPathGetCurrentPsychologicalSurvey {  }

export interface TSpecBodyGetCurrentPsychologicalSurvey {}

export interface TSpecQueryGetCurrentPsychologicalSurvey {
  AppVersion?: string;
  AppUser?: string;
}
