// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getLedgerById = (path: TSpecPathGetLedgerById) => {
  let res = "/tenant/:tenantId/ledger/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getLedgerById = RequestHandler<TSpecPathGetLedgerById, any, TSpecBodyGetLedgerById, TSpecQueryGetLedgerById, {}>;

export interface TSpecPathGetLedgerById { tenantId: string; id: string; }

export interface TSpecBodyGetLedgerById {}

export interface TSpecQueryGetLedgerById {
  AppVersion?: string;
  AppUser?: string;
}
