// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_softDeleteLibraryDoc = (path: TSpecPathSoftDeleteLibraryDoc) => {
  let res = "/library/docs/:libraryDocId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_softDeleteLibraryDoc = RequestHandler<TSpecPathSoftDeleteLibraryDoc, any, TSpecBodySoftDeleteLibraryDoc, TSpecQuerySoftDeleteLibraryDoc, {}>;

export interface TSpecPathSoftDeleteLibraryDoc { libraryDocId: string; }

export interface TSpecBodySoftDeleteLibraryDoc {}

export interface TSpecQuerySoftDeleteLibraryDoc {
  AppVersion?: string;
  AppUser?: string;
}
