<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!parentId">
      {{ suggestion ? 'Edit Suggestion' : 'Share Feedback' }}
    </ion-title>
    <ion-title *ngIf="parentId">
      {{ suggestion ? 'Edit Comment' : 'Add Comment' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container
    [isFullHeight]="true"
    [withVerticalPadding]="true"
    [formGroup]="mainFormGroup"
  >
    <app-wysiwyg-editor
      [(value)]="body"
      [disableAttachments]="disableAttachments"
      toolbarMode="image-only"
      bodyPlaceholder="Please describe your question, concern, or idea."
    />

    <ion-list *ngIf="isAdmin" [inset]="true">
      <ion-item button appHaptics (click)="selectAuthor()">
        <ion-label>Author</ion-label>
        <ion-note slot="end">{{ formattedAuthor || 'Select' }}</ion-note>
      </ion-item>
      <ion-item *ngIf="!parentId">
        <ion-select
          label="Status"
          interface="popover"
          placeholder="Select Status"
          aria-label="Status"
          formControlName="status"
          labelPlacement="floating"
          appHaptics
        >
          <ion-select-option
            *ngFor="let option of statuses"
            [value]="option.value"
          >
            {{ option.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="!parentId && categories.length > 0">
        <ion-select
          label="Category"
          interface="popover"
          placeholder="Select Category"
          aria-label="Category"
          formControlName="category"
          labelPlacement="floating"
          appHaptics
        >
          <ion-select-option [value]="null">No Category</ion-select-option>
          <ion-select-option
            *ngFor="let category of categories"
            [value]="category._id"
          >
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div *ngIf="isAdmin">
      <ion-list [inset]="true">
        <ion-item>
          <ion-toggle appHaptics formControlName="isPublic">
            <ion-label> Publish to Team </ion-label>
          </ion-toggle>
        </ion-item>
      </ion-list>
      <app-list-post-line [text]="publicInfo" />
    </div>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="suggestion"
        color="danger"
        fill="outline"
        appHaptics
        (click)="onDeleteClick()"
        expand="block"
        class="ion-no-margin"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        appHaptics
        (click)="onFormSubmit()"
        expand="block"
        class="ion-no-margin"
      >
        {{ suggestion ? 'Update' : 'Submit' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
