// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUserById = (path: TSpecPathGetUserById) => {
  let res = "/tenants/:id/users/:userId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getUserById = RequestHandler<TSpecPathGetUserById, any, TSpecBodyGetUserById, TSpecQueryGetUserById, {}>;

export interface TSpecPathGetUserById { id: string; userId: string; }

export interface TSpecBodyGetUserById {}

export interface TSpecQueryGetUserById {
  AppVersion?: string;
  AppUser?: string;
}
