// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getValueBehaviors = (path: TSpecPathGetValueBehaviors) => {
  let res = "/tenants/:tenantId/ValueBehaviors";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getValueBehaviors = RequestHandler<TSpecPathGetValueBehaviors, any, TSpecBodyGetValueBehaviors, TSpecQueryGetValueBehaviors, {}>;

export interface TSpecPathGetValueBehaviors { tenantId: string; }

export interface TSpecBodyGetValueBehaviors {}

export interface TSpecQueryGetValueBehaviors {
  AppVersion?: string;
  AppUser?: string;
}
