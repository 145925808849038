import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserScheduleEditorModalComponent } from './user-schedule-editor-modal.component';
import { UserScheduleEditorModule } from '@app/modules/user-schedule-editor/user-schedule-editor.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UserScheduleEditorModule,
    FullHeightContainerModule,
    DirectivesModule
  ],
  exports: [UserScheduleEditorModalComponent],
  declarations: [UserScheduleEditorModalComponent]
})
export class UserScheduleEditorModalModule {}
