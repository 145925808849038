// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_inviteUser = () => "/users/invite";

export type TSpecHandler_inviteUser = RequestHandler<TSpecPathInviteUser, any, TSpecBodyInviteUser, TSpecQueryInviteUser, {}>;

export interface TSpecPathInviteUser {  }

export interface TSpecBodyInviteUser {
  email: string;
  supervisorId?: string;
  department?: string;
  roles: UserRoles[];
  compensationEmployeeId?: string;
  compensationPools?: string[];
}

export enum UserRoles {
  Admin = "Admin",
  Manager = "Manager"
}

export interface TSpecQueryInviteUser {
  AppVersion?: string;
  AppUser?: string;
}
