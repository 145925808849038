<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Backdoor</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button />
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-segment [value]="currentTab" (ionChange)="onCurrentTabChange($event)">
      <ion-segment-button value="infos">
        <ion-label>Messages</ion-label>
      </ion-segment-button>
      <ion-segment-button value="eulas">
        <ion-label>EULAs</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading; else list">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>
  </app-width-restricted-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onFabClick()" appHaptics>
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ng-template #list>
  <ng-container *ngIf="currentTab === 'infos'; else eulas">
    <ng-container *ngTemplateOutlet="infos" />
  </ng-container>
</ng-template>

<ng-template #infos>
  <ion-card *ngFor="let message of backdoorInfos" class="card">
    <ion-card-header>
      <ion-card-title *ngIf="message.title">
        {{ message.title }}
      </ion-card-title>
      <ion-card-subtitle>
        {{ message.versionRange }}
        ({{ message.platformsToShow | formatPlatforms }})
      </ion-card-subtitle>
    </ion-card-header>

    <ion-list>
      <ion-item>
        <ion-label>Message: {{ message.message }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label *ngIf="message.showToAllTenants"> Tenants: All </ion-label>
        <ion-label *ngIf="!message.showToAllTenants">
          Tenants: {{ message | backdoorTenants : tenants }}
        </ion-label>
      </ion-item>
      <ion-button
        class="action"
        fill="clear"
        color="primary"
        (click)="updateBackdoorInfo(message)"
        appHaptics
      >
        Edit
      </ion-button>
      <ion-button
        class="action"
        fill="clear"
        color="danger"
        (click)="deleteBackdoorInfo(message)"
        appHaptics
      >
        Delete
      </ion-button>
    </ion-list>
  </ion-card>

  <ng-container *ngIf="backdoorInfos.length === 0 && !isLoading">
    <app-empty-stub text="No Messages" />
  </ng-container>
</ng-template>

<ng-template #eulas>
  <ion-card *ngFor="let eula of backdoorEulas" class="card">
    <ion-card-header>
      <ion-card-title
        *ngIf="eula.title"
        [ngClass]="{
          archived: eula.isDeleted,
          current: currentEula._id.toString() === eula._id.toString()
        }"
      >
        {{ eula.title }}
        <span *ngIf="eula.isDeleted"> (Archived) </span>
        <span *ngIf="currentEula._id.toString() === eula._id.toString()">
          (Current)
        </span>
      </ion-card-title>
      <ion-card-subtitle>
        {{ eula.date | date : 'M/d/yy h:mm a' }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-list>
      <ion-item>
        <ion-label *ngIf="eula.showToAllTenants"> Tenants: All </ion-label>
        <ion-label *ngIf="!eula.showToAllTenants">
          Tenants: {{ eula | backdoorTenants : tenants }}
        </ion-label>
      </ion-item>
      <ion-button
        class="action"
        fill="clear"
        color="primary"
        (click)="updateBackdoorEula(eula)"
        appHaptics
      >
        Edit
      </ion-button>
      <ion-button
        *ngIf="!eula.isDeleted"
        class="action"
        fill="clear"
        color="danger"
        (click)="deleteBackdoorEula(eula)"
        appHaptics
      >
        Delete
      </ion-button>
    </ion-list>
  </ion-card>

  <ng-container *ngIf="backdoorInfos.length === 0 && !isLoading">
    <app-empty-stub text="No Messages" />
  </ng-container>
</ng-template>
