import { Types } from 'mongoose';

export enum ESkillMatrixStatus {
  CANNOT_PERFORM = 'CANNOT_PERFORM',
  CAN_PERFORM_WITH_SUPERVISON = 'CAN_PERFORM_WITH_SUPERVISON',
  CAN_PERFORM_WITH_REVIEW = 'CAN_PERFORM_WITH_REVIEW',
  CAN_PERFORM_WITHOUT_REVIEW = 'CAN_PERFORM_WITHOUT_REVIEW',
  CAN_PERFORM_AND_TRAIN = 'CAN_PERFORM_AND_TRAIN',
  MUST_REVIEW_NEW_VERSION = 'MUST_REVIEW_NEW_VERSION',
  REVOKED = 'REVOKED'
}

export enum EActionType {
  REQUEST_PROCESS_FEEDBACK = 'REQUEST_PROCESS_FEEDBACK',
  PROMPT_USER_TO_LEAVE_NOTE = 'PROMPT_USER_TO_LEAVE_NOTE'
}

export enum EShowMoreRecentNote {
  ONLY_ONCE_TO_EACH_USER = 'ONLY_ONCE_TO_EACH_USER',
  UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE = 'UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE',
  EVERY_TIME_TASK_IS_OPENED = 'EVERY_TIME_TASK_IS_OPENED'
}

export interface IRequestProcessFeedback {
  actionType: EActionType.REQUEST_PROCESS_FEEDBACK;
  completeThreshold: string;
  delayInDays: string;
  mandatory: boolean;
}

export interface IPromptUserToLeaveNote {
  actionType: EActionType.PROMPT_USER_TO_LEAVE_NOTE;
  visibleToEveryone: boolean;
  visibleToUsers: Types.ObjectId[];
  mandatory: boolean;
  showMoreRecentNote: EShowMoreRecentNote;
}

export type ITaskAtionUponTaskCompletion =
  | IRequestProcessFeedback
  | IPromptUserToLeaveNote;

export interface ITaskDetails {
  description?: string;
  attachments?: Types.ObjectId[];
}

export enum TaskPointsEarns {
  PER_COMPLETION = 'PER_COMPLETION',
  PER_UNIT = 'PER_UNIT'
}

export enum TaskScheduleType {
  NEVER = 'NEVER',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  SPECIFIC_DATES = 'SPECIFIC_DATES'
}

export enum TaskPerformanceEvaluationType {
  STANDARD = 'STANDARD',
  AVERAGE = 'AVERAGE'
}

export enum TaskTimeAttackType {
  OFF = 'OFF',
  VOLUNTARY = 'VOLUNTARY',
  REQUIRED = 'REQUIRED'
}

export interface TaskScheduleNever {
  scheduleType: TaskScheduleType.NEVER;
  date: string | null;
}

export interface TaskScheduleDaily {
  scheduleType: TaskScheduleType.DAILY;
}

export interface TaskScheduleSpecificDates {
  scheduleType: TaskScheduleType.SPECIFIC_DATES;
  dates: string[];
  repeatEveryYear: boolean;
  adjustWeekdays: boolean;
}

export interface TaskScheduleWeekly {
  scheduleType: TaskScheduleType.WEEKLY;
  weekDays: [boolean, boolean, boolean, boolean, boolean, boolean, boolean]; // starting Sunday
}

export type ITaskSchedule =
  | TaskScheduleNever
  | TaskScheduleDaily
  | TaskScheduleSpecificDates
  | TaskScheduleWeekly;

export interface ITask {
  title: string;
  details: ITaskDetails;
  points: number;
  pointsUnit: string;
  verbBase: string;
  verbPastSimple: string;
  schedule: ITaskSchedule[];
  shouldReappear: boolean;
  appearsAt: string;
  disappearsAt: string;
  performanceEvaluation: TaskPerformanceEvaluationType;
  performanceDuration: string | null;
  isDeleted: boolean;
  tenant: Types.ObjectId;
  isCritical: boolean;
  orderIndex: number;
  skillMatrix: { status: ESkillMatrixStatus; user: Types.ObjectId }[];
  notifyEveryoneUponCompletion: boolean;
  notifyUsersUponCompletion: Types.ObjectId[];
  timeAttack: TaskTimeAttackType;
  timeAttackRewardPoints: number;
  timeAttackAttemptPoints: number;
  isSystemGenerated?: boolean;
  extraAppearDate: string | null;
  taskFolder?: string;
  actionsUponTaskCompletion: ITaskAtionUponTaskCompletion[];
  isAuditTask: boolean;
  auditSuccessCriteria: string[];
  tasksForAudit: Types.ObjectId[];
  auditReviewers: Types.ObjectId[];
  pointsEarns: TaskPointsEarns;
  verbPastParticiple?: string;
  nounPlural?: string;
}

export type ITaskInput = Omit<ITask, 'orderIndex' | 'tenant'>;
