import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { Observable } from 'rxjs';
import { TSpecRoute_getAllBackdoorInfos } from '@backend/types/generated/Backdoor/getAllBackdoorInfos';
import {
  TSpecBodyAddBackdoorInfo,
  TSpecRoute_addBackdoorInfo
} from '@backend/types/generated/Backdoor/addBackdoorInfo';
import {
  TSpecBodyUpdateBackdoorInfo,
  TSpecPathUpdateBackdoorInfo,
  TSpecRoute_updateBackdoorInfo
} from '@backend/types/generated/Backdoor/updateBackdoorInfo';
import {
  TSpecPathDeleteBackdoorInfo,
  TSpecRoute_deleteBackdoorInfo
} from '@backend/types/generated/Backdoor/deleteBackdoorInfo';
import { TSpecRoute_getAllBackdoorEulas } from '@backend/types/generated/Backdoor/getAllBackdoorEulas';
import { IBackdoorEula } from '@backend/models/types/backdoor-eula';
import {
  TSpecPathDeleteBackdoorEula,
  TSpecRoute_deleteBackdoorEula
} from '@backend/types/generated/Backdoor/deleteBackdoorEula';
import {
  TSpecBodyUpdateBackdoorEula,
  TSpecPathUpdateBackdoorEula,
  TSpecRoute_updateBackdoorEula
} from '@backend/types/generated/Backdoor/updateBackdoorEula';
import {
  TSpecBodyAddBackdoorEula,
  TSpecRoute_addBackdoorEula
} from '@backend/types/generated/Backdoor/addBackdoorEula';
import {
  TSpecQueryGetBackdoorState,
  TSpecRoute_getBackdoorState
} from '@backend/types/generated/Backdoor/getBackdoorState';
import {
  TSpecBodyAcceptEula,
  TSpecPathAcceptEula,
  TSpecRoute_acceptEula
} from '@backend/types/generated/Backdoor/acceptEula';

@Injectable()
export class BackdoorApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public acceptEula(params: {
    path: TSpecPathAcceptEula;
    body: TSpecBodyAcceptEula;
  }): Observable<null> {
    return this._httpClient.post<null>(
      TSpecRoute_acceptEula(params.path),
      params.body
    );
  }

  public getBackdoorState(params: {
    query: TSpecQueryGetBackdoorState;
  }): Observable<{
    currentInfo: MongoStoredObject<IBackdoorInfo> | null;
    currentEula: MongoStoredObject<IBackdoorEula> | null;
  }> {
    return this._httpClient.get<{
      currentInfo: MongoStoredObject<IBackdoorInfo> | null;
      currentEula: MongoStoredObject<IBackdoorEula> | null;
    }>(TSpecRoute_getBackdoorState(), {
      params: { ...params.query }
    });
  }

  public getAllBackdoorInfos(): Observable<MongoStoredObject<IBackdoorInfo>[]> {
    return this._httpClient.get<MongoStoredObject<IBackdoorInfo>[]>(
      TSpecRoute_getAllBackdoorInfos()
    );
  }

  public getAllBackdoorEulas(): Observable<MongoStoredObject<IBackdoorEula>[]> {
    return this._httpClient.get<MongoStoredObject<IBackdoorEula>[]>(
      TSpecRoute_getAllBackdoorEulas()
    );
  }

  public addBackdoorInfo(params: {
    body: TSpecBodyAddBackdoorInfo;
  }): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.post<MongoStoredObject<IBackdoorInfo>>(
      TSpecRoute_addBackdoorInfo(),
      params.body
    );
  }

  public addBackdoorEula(params: {
    body: TSpecBodyAddBackdoorEula;
  }): Observable<MongoStoredObject<IBackdoorEula>> {
    return this._httpClient.post<MongoStoredObject<IBackdoorEula>>(
      TSpecRoute_addBackdoorEula(),
      params.body
    );
  }

  public updateBackdoorInfo(params: {
    path: TSpecPathUpdateBackdoorInfo;
    body: TSpecBodyUpdateBackdoorInfo;
  }): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.put<MongoStoredObject<IBackdoorInfo>>(
      TSpecRoute_updateBackdoorInfo(params.path),
      params.body
    );
  }

  public updateBackdoorEula(params: {
    path: TSpecPathUpdateBackdoorEula;
    body: TSpecBodyUpdateBackdoorEula;
  }): Observable<MongoStoredObject<IBackdoorEula>> {
    return this._httpClient.put<MongoStoredObject<IBackdoorEula>>(
      TSpecRoute_updateBackdoorEula(params.path),
      params.body
    );
  }

  public deleteBackdoorInfo(params: { path: TSpecPathDeleteBackdoorInfo }) {
    return this._httpClient.delete(TSpecRoute_deleteBackdoorInfo(params.path));
  }

  public deleteBackdoorEula(params: { path: TSpecPathDeleteBackdoorEula }) {
    return this._httpClient.delete(TSpecRoute_deleteBackdoorEula(params.path));
  }
}
