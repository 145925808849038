<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button />
    </ion-buttons>
    <ion-title>Account Settings</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container
    [isFullHeight]="true"
    [withVerticalPadding]="true"
    [formGroup]="mainFormGroup"
  >
    <app-content-title> Account Settings </app-content-title>

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="First Name"
          labelPlacement="floating"
          formControlName="firstName"
          autocapitalize="on"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Last Name"
          labelPlacement="floating"
          formControlName="lastName"
          autocapitalize="on"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Email"
          labelPlacement="floating"
          formControlName="email"
          [readonly]="true"
        />
        <ion-button
          *ngIf="!userParent"
          slot="end"
          fill="clear"
          size="default"
          appHaptics
          class="ion-no-margin"
          (click)="requestEmailChange()"
        >
          Change
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-input
          label="Phone (optional)"
          labelPlacement="floating"
          inputmode="numeric"
          formControlName="phone"
          type="tel"
          appPhoneMask
        />
      </ion-item>
      <ion-item *ngIf="!userParent">
        <ion-input
          label="Password"
          labelPlacement="floating"
          fill="solid"
          [readonly]="true"
          value="******"
        />
        <ion-button
          *ngIf="!isResetPasswordEmailSent"
          slot="end"
          fill="clear"
          size="default"
          appHaptics
          class="ion-no-margin"
          (click)="requestResetPassword()"
        >
          Reset
        </ion-button>
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="formError" [text]="formError" color="danger" />

    <ion-list [inset]="true">
      <ion-item
        *ngIf="tenantFeatures.userSchedule"
        button
        appHaptics
        (click)="openSchedule()"
      >
        <ion-icon slot="start" name="calendar-outline" />
        <ion-label>Schedule</ion-label>
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        (click)="submit()"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        expand="block"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
