import { Component, Input } from '@angular/core';
import { ModalClass } from '@app/core/class/modal.class';
import { ToastService } from '@app/core/service/toast.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  IBackdoorInfo,
  IBackdoorInfoLink
} from '@backend/models/types/backdoor-info';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-backdoor-info-modal',
  templateUrl: './backdoor-info-modal.component.html'
})
export class BackdoorInfoModalComponent extends ModalClass {
  @Input()
  public backdoorInfo: MongoStoredObject<IBackdoorInfo>;

  @Input()
  public preview: boolean;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _toastService: ToastService
  ) {
    super();
  }

  protected async goToUrl(link: IBackdoorInfoLink) {
    try {
      await Browser.open({
        url: encodeURI(link.url)
      });
    } catch (e) {
      this._toastService.presentToast('Unable to open link');
      console.log('Error opening link', e);
    }
  }

  protected dismiss() {
    this._modalCtrl.dismiss(
      {},
      this.preview ? 'cancel-preview' : 'cancel',
      this._modalName
    );
  }
}
