// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getSysGenBehaviorsAndTasks = () => "/tenants/sys-gen-behaviors-and-tasks";

export type TSpecHandler_getSysGenBehaviorsAndTasks = RequestHandler<TSpecPathGetSysGenBehaviorsAndTasks, any, TSpecBodyGetSysGenBehaviorsAndTasks, TSpecQueryGetSysGenBehaviorsAndTasks, {}>;

export interface TSpecPathGetSysGenBehaviorsAndTasks {  }

export interface TSpecBodyGetSysGenBehaviorsAndTasks {}

export interface TSpecQueryGetSysGenBehaviorsAndTasks {
  AppVersion?: string;
  AppUser?: string;
}
