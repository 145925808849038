// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_acceptUserInvitation = () => "/users/invite/accept";

export type TSpecHandler_acceptUserInvitation = RequestHandler<TSpecPathAcceptUserInvitation, any, TSpecBodyAcceptUserInvitation, TSpecQueryAcceptUserInvitation, {}>;

export interface TSpecPathAcceptUserInvitation {  }

export interface TSpecBodyAcceptUserInvitation {
  firstName: string;
  lastName: string;
  password: string;
}

export interface TSpecQueryAcceptUserInvitation {
  signupToken: string;
  AppVersion?: string;
  AppUser?: string;
}
