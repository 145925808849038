// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTag = (path: TSpecPathGetTag) => {
  let res = "/tags/:tagId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTag = RequestHandler<TSpecPathGetTag, any, TSpecBodyGetTag, TSpecQueryGetTag, {}>;

export interface TSpecPathGetTag { tagId: string; }

export interface TSpecBodyGetTag {}

export interface TSpecQueryGetTag {
  AppVersion?: string;
  AppUser?: string;
}
