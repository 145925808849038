<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="close()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title> Schedule </ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="submit()"
        color="primary"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
      >
        Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <app-user-schedule-editor
      [userId]="userId"
      [scheduleFormGroup]="scheduleFormGroup"
      [weekTimeFormGroup]="weekTimeFormGroup"
    />
  </app-full-height-container>
</ng-template>
