// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTaskPerformance = () => "/task-performance";

export type TSpecHandler_getTaskPerformance = RequestHandler<TSpecPathGetTaskPerformance, any, TSpecBodyGetTaskPerformance, TSpecQueryGetTaskPerformance, {}>;

export interface TSpecPathGetTaskPerformance {  }

export interface TSpecBodyGetTaskPerformance {}

export interface TSpecQueryGetTaskPerformance {
  start: string;
  end: string;
  AppVersion?: string;
  AppUser?: string;
}
