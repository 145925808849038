// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getConnectedAccountsGroups = () => "/connected-accounts/groups";

export type TSpecHandler_getConnectedAccountsGroups = RequestHandler<TSpecPathGetConnectedAccountsGroups, any, TSpecBodyGetConnectedAccountsGroups, TSpecQueryGetConnectedAccountsGroups, {}>;

export interface TSpecPathGetConnectedAccountsGroups {  }

export interface TSpecBodyGetConnectedAccountsGroups {}

export interface TSpecQueryGetConnectedAccountsGroups {
  AppVersion?: string;
  AppUser?: string;
}
