<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title>Users</ion-title>
    <ion-buttons slot="end" [attr.data-hidden]="!hasEditRights">
      <ion-button id="popover-button" appHaptics>
        <ion-icon slot="icon-only" name="options-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar ion-no-padding">
    <app-width-restricted-container>
      <ion-searchbar (ionInput)="handleSearchInput($event)" />
    </app-width-restricted-container>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isVirtualScroll]="true">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <cdk-virtual-scroll-viewport
      [itemSize]="cdkItemSize"
      [minBufferPx]="cdkItemSize * 12"
      [maxBufferPx]="cdkItemSize * 12"
    >
      <ion-list [inset]="true">
        <ion-item
          *cdkVirtualFor="
            let user of users | filterUsers : searchTerm : showDisabledUsers;
            trackBy: trackItems;
            let i = index
          "
          [detail]="hasEditRights"
          [button]="hasEditRights"
          (click)="hasEditRights && editUser(user._id)"
          appHaptics
          [disableHaptics]="!hasEditRights"
        >
          <ion-label [ngClass]="{ 'deleted-user': user.isDeleted }">
            <h2 class="user-name">{{ user.firstName }} {{ user.lastName }}</h2>
            <p *ngIf="user.email" class="subtext">
              {{ user.email }}
            </p>
            <p *ngIf="user.phone" class="subtext">
              {{ user.phone }}
            </p>
            <p *ngIf="user.supervisor" class="subtext">
              Supervisor:
              {{ user.supervisor.firstName }}
              {{ user.supervisor.lastName }}
            </p>
            <p class="subtext" *ngIf="user.roles.length > 0">
              Role:
              {{ user.roles | formatRoles }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </app-full-height-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    class="main-fab"
    *ngIf="hasEditRights"
  >
    <ion-fab-button color="dark" (click)="inviteUser()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ion-popover trigger="popover-button">
  <ng-template>
    <ion-list lines="none">
      <ion-item>
        <ion-toggle
          [checked]="showDisabledUsers"
          (ionChange)="handleDisabledUsersToggle($event)"
          appHaptics
        >
          Show Disabled Users
        </ion-toggle>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
