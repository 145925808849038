<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="tag">Tag #{{ tag.tagNumber }}</ion-title>
    <ion-buttons *ngIf="tag" slot="end">
      <ion-button color="primary" (click)="editTag()" appHaptics>
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-grid *ngIf="isLoading; else tagInfo">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>
  </app-full-height-container>
</ion-content>

<ng-template #tagInfo>
  <ion-list [inset]="true">
    <ion-item>
      <ion-label class="ion-text-capitalize">
        {{ tagsSettings.pickupDateFieldName }}
      </ion-label>
      <ion-note slot="end">
        {{ tag.pickupDate }}
      </ion-note>
    </ion-item>
  </ion-list>

  <ion-list [inset]="true">
    <ion-item>
      <ion-label>Server</ion-label>
      <ion-note slot="end">
        {{ server.firstName }} {{ server.lastName }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Uploaded By</ion-label>
      <ion-note slot="end">
        {{ tag.user.firstName }} {{ tag.user.lastName }}
      </ion-note>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="tag.customerCell || tag.customerName" [inset]="true">
    <ion-item *ngIf="tag.customerCell">
      <ion-label>Customer Cell</ion-label>
      <ion-note slot="end">
        {{ tag.customerCell }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="tag.customerName">
      <ion-label>Customer Name</ion-label>
      <ion-note slot="end" class="ion-text-capitalize">
        {{ tag.customerName }}
      </ion-note>
    </ion-item>
  </ion-list>

  <ion-list [inset]="true">
    <ion-item>
      <ion-label>Number of Items</ion-label>
      <ion-note slot="end">
        {{ tag.count }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="tag.notes">
      <ion-textarea
        label="Notes"
        [value]="tag.notes"
        labelPlacement="floating"
        [autoGrow]="true"
        autocapitalize="on"
        [readonly]="true"
      />
    </ion-item>
  </ion-list>

  <ion-list [inset]="true">
    <ion-item>
      <ion-select
        labelPlacement="floating"
        label="Status"
        interface="popover"
        placeholder="Select Status"
        aria-label="Status"
        [value]="tag.pickupStatus"
        (ionChange)="onStatusChange($event)"
      >
        <ion-select-option
          *ngFor="let option of tag.possiblePickupStatuses"
          [value]="option"
        >
          {{ option }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>

  <app-list-pre-line
    *ngIf="tag.pickupStatusHistory && tag.pickupStatusHistory.length"
    title="History"
  />
  <ion-list
    *ngIf="tag.pickupStatusHistory && tag.pickupStatusHistory.length"
    [inset]="true"
  >
    <ion-item *ngFor="let status of tag.pickupStatusHistory">
      <ion-label>{{ status.pickupStatus }}</ion-label>
      <ion-note slot="end">
        {{ status.date | date : 'M/d/yy h:mm a' }}
      </ion-note>
    </ion-item>
  </ion-list>

  <app-list-pre-line *ngIf="images.length" title="Photos" />
  <div class="tag-images">
    <button
      *ngFor="let image of images; let i = index"
      class="image-btn"
      (click)="viewPhoto(i)"
      appHaptics
    >
      <img class="image" [src]="image.url" />
    </button>
  </div>
</ng-template>
