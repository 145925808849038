// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateBackdoorEula = (path: TSpecPathUpdateBackdoorEula) => {
  let res = "/backdoor/eula/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateBackdoorEula = RequestHandler<TSpecPathUpdateBackdoorEula, any, TSpecBodyUpdateBackdoorEula, TSpecQueryUpdateBackdoorEula, {}>;

export interface TSpecPathUpdateBackdoorEula { id: string; }

export interface TSpecBodyUpdateBackdoorEula {
  title: string;
  iframeCode: string;
  canDismiss: boolean;
  tenantsToShow: string[];
  showToAllTenants: boolean;
}

export interface TSpecQueryUpdateBackdoorEula {
  AppVersion?: string;
  AppUser?: string;
}
