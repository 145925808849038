// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_resendInvitationLink = (path: TSpecPathResendInvitationLink) => {
  let res = "/users/:id/resend-invitation";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_resendInvitationLink = RequestHandler<TSpecPathResendInvitationLink, any, TSpecBodyResendInvitationLink, TSpecQueryResendInvitationLink, {}>;

export interface TSpecPathResendInvitationLink { id: string; }

export interface TSpecBodyResendInvitationLink {}

export interface TSpecQueryResendInvitationLink {
  AppVersion?: string;
  AppUser?: string;
}
