import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { IonNav, ModalController, IonLoading } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { AuthenticationService } from '@app/core';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import fastDeepEqual from 'fast-deep-equal';
import { IUserSchedule, UserRoles } from '@backend/models/types/user';
import { ManageUserTasksModalComponent } from '@app/modals/manage-user-tasks-modal/manage-user-tasks-modal.component';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TenantService } from '@app/core/service/tenant.service';
import { CompensationApiService } from '@app/core/service/api/compensation.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { combineLatest, take } from 'rxjs';
import { SkillMatrixForUserModalComponent } from '@app/modals/skill-matrix-for-user-modal/skill-matrix-for-user-modal.component';
import { EMAIL_REGEX } from '@app/utils/email';
import { UserScheduleEditorModalComponent } from '@app/modals/user-schedule-editor-modal/user-schedule-editor-modal.component';
import { DepartmentApiService } from '@app/core/service/api/department.api.service';
import { IDepartment } from '@backend/models/types/department';
import { showModal, showUserSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements AfterViewInit {
  @Input()
  public readonly userId: string;

  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected initialUserData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    supervisorId: string | null;
    department: string;
    role: UserRoles | 'None';
    compensationEmployeeId: string;
    compensationPools: string[];
  } = null;
  protected userSchedule: IUserSchedule;
  protected mainFormGroup: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX)
    ]),
    phone: new FormControl<string>(''),
    supervisorId: new FormControl<string | null>(''),
    department: new FormControl<ObjectId>(null),
    role: new FormControl<UserRoles | 'None'>('None'),
    compensationEmployeeId: new FormControl<string>(''),
    compensationPools: new FormControl<string[]>([])
  });
  protected isDeleted = false;
  protected allUsers: UserModel[] = [];
  protected roleOptions: (UserRoles | 'None')[] = [
    'None',
    ...Object.values(UserRoles)
  ];
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected tenantFeatures: Pick<
    ITenantFeatures,
    'userSchedule' | 'calculateRewards' | 'checklist'
  > = {
    userSchedule: false,
    calculateRewards: false,
    checklist: false
  };
  protected departments: MongoStoredObject<IDepartment>[] = [];
  protected isInvitationInProgress = false;
  protected isInvitationLinkSent = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _userListService: UserListService,
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService,
    private readonly _userEntityService: UserEntityService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _modalCtrl: ModalController,
    private readonly _tenantService: TenantService,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _departmentApiService: DepartmentApiService
  ) {}

  public ngAfterViewInit(): void {
    this._loadInfo();
  }

  private _loadInfo() {
    this.loading.present();
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this.tenantFeatures = tenant.features;

      combineLatest([
        this._userListService.users$,
        this._compensationApiService.getCompensationPools(),
        this._departmentApiService.getDepartments(),
        this._userApiService.getUserById({
          path: { id: tenant._id, userId: this.userId }
        })
      ])
        .pipe(take(1))
        .subscribe({
          next: ([users, compensationPools, departments, res]) => {
            this.allUsers = users;
            this.compensationPools = compensationPools;
            this.departments = departments;

            this.initialUserData = {
              firstName: res.firstName,
              lastName: res.lastName,
              email: res.email,
              phone: res.phone || '',
              supervisorId: res.supervisor ? res.supervisor._id : null,
              department: res.department,
              role: res.roles.length ? res.roles[0] : 'None',
              compensationEmployeeId: res.compensationEmployeeId || '',
              compensationPools: this.compensationPools.reduce((prev, cur) => {
                if (cur.users.find((u) => u.toString() === res._id)) {
                  return [...prev, cur._id];
                }
                return prev;
              }, [])
            };
            this.userSchedule = res.schedule;
            this.isDeleted = res.isDeleted;
            this.isInvitationInProgress = !!res.signupToken;
            this.mainFormGroup.reset(this.initialUserData);

            this.loading.dismiss();
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to load data'
            );
          }
        });
    });
  }

  protected get canSave() {
    if (!this.initialUserData) return false;

    const isValid = this.mainFormGroup.valid;
    const mainDataChanged = !fastDeepEqual(
      {
        firstName: this.initialUserData.firstName,
        lastName: this.initialUserData.lastName,
        email: this.initialUserData.email,
        phone: this.initialUserData.phone,
        supervisorId: this.initialUserData.supervisorId,
        department: this.initialUserData.department,
        role: this.initialUserData.role,
        compensationEmployeeId: this.initialUserData.compensationEmployeeId,
        compensationPools: this.initialUserData.compensationPools
      },
      {
        firstName: this.mainFormGroup.get('firstName').value,
        lastName: this.mainFormGroup.get('lastName').value,
        email: this.mainFormGroup.get('email').value,
        phone: this.mainFormGroup.get('phone').value,
        supervisorId: this.mainFormGroup.get('supervisorId').value,
        department: this.mainFormGroup.get('department').value,
        role: this.mainFormGroup.get('role').value,
        compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
          .value,
        compensationPools: this.mainFormGroup.get('compensationPools').value
      }
    );

    return isValid && mainDataChanged;
  }

  protected get firstNameError() {
    if (
      this.mainFormGroup.get('firstName').dirty &&
      this.mainFormGroup.get('firstName').errors
    ) {
      if (this.mainFormGroup.get('firstName').errors.required) {
        return 'First name is required';
      } else if (this.mainFormGroup.get('firstName').errors.pattern) {
        return 'First name contains invalid characters';
      }
    }
    return '';
  }

  protected get lastNameError() {
    if (
      this.mainFormGroup.get('lastName').dirty &&
      this.mainFormGroup.get('lastName').errors
    ) {
      if (this.mainFormGroup.get('lastName').errors.required) {
        return 'Last name is required';
      } else if (this.mainFormGroup.get('lastName').errors.pattern) {
        return 'Last name contains invalid characters';
      }
    }
    return '';
  }

  protected get formError() {
    return this.firstNameError || this.lastNameError;
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.mainFormGroup.get('supervisorId').value];
  }

  protected get formattedSupervisor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected handleSupervisorClearClick(): void {
    this.mainFormGroup.controls.supervisorId.setValue('');
  }

  protected async updateUser() {
    this.loading.present();

    const supervisor = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );

    const user = {
      firstName: this.mainFormGroup.get('firstName').value,
      lastName: this.mainFormGroup.get('lastName').value,
      email: this.mainFormGroup.get('email').value.toLowerCase(),
      phone: this.mainFormGroup.get('phone').value,
      department: this.mainFormGroup.get('department').value,
      roles:
        this.mainFormGroup.get('role').value === 'None'
          ? []
          : [this.mainFormGroup.get('role').value],
      compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
        .value,
      compensationPools: this.mainFormGroup.get('compensationPools').value,
      supervisorId: supervisor ? supervisor._id : undefined
    };

    this._userApiService
      .updateUser({ path: { id: this.userId }, body: user })
      .subscribe({
        next: () => {
          this.loading.dismiss();
          this._userListService.update();
          this._userEntityService.update();
          this._toastService.presentToast('Changes Saved Successfully!');
          this._ionNav.pop();
        },
        error: (e) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to update user'
          );
        }
      });
  }

  protected async toggleSoftDelete() {
    this.loading.present();

    this._userApiService
      .softDelete({
        path: { id: this.userId },
        body: { isDeleted: !this.isDeleted }
      })
      .subscribe({
        next: () => {
          this.loading.dismiss();
          this._toastService.presentToast(
            this.isDeleted
              ? 'User Reactivated Successfully!'
              : 'User Deactivated Successfully!'
          );
          this.isDeleted = !this.isDeleted;
          this._userListService.update();
        },
        error: (e) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to delete user'
          );
        }
      });
  }

  protected async manageTasks() {
    await showModal(
      {
        component: ManageUserTasksModalComponent,
        props: {
          userId: this.userId
        }
      },
      this._modalCtrl
    );
  }

  protected async openSkillMatrix() {
    await showModal(
      {
        component: SkillMatrixForUserModalComponent,
        props: {
          userId: this.userId
        }
      },
      this._modalCtrl
    );
  }

  protected async openSchedule() {
    const { role } = await showModal(
      {
        component: UserScheduleEditorModalComponent,
        props: {
          userId: this.userId,
          userSchedule: this.userSchedule
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this._loadInfo();
    }
  }

  protected resendInvitationLink() {
    this.loading.present();

    this._userApiService
      .resendInvitationLink({ path: { id: this.userId } })
      .subscribe({
        next: () => {
          this.isInvitationLinkSent = true;
          this.loading.dismiss();
          this._toastService.presentToast('Invitation Link Sent!');
        },
        error: (e) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Cannot Send Invitation Link'
          );
        }
      });
  }

  protected async selectSupervisor(e: MouseEvent) {
    e.preventDefault();

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        title: 'Supervisor'
      },
      this._modalCtrl
    );

    if (success) {
      this.mainFormGroup.controls.supervisorId.setValue(selectedUsers[0]);
    }
  }
}
