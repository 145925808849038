// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_respondToAnnouncement = (path: TSpecPathRespondToAnnouncement) => {
  let res = "/announcements/:id/respond";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_respondToAnnouncement = RequestHandler<TSpecPathRespondToAnnouncement, any, TSpecBodyRespondToAnnouncement, TSpecQueryRespondToAnnouncement, {}>;

export interface TSpecPathRespondToAnnouncement { id: string; }

export interface TSpecBodyRespondToAnnouncement {
  body: string;
}

export interface TSpecQueryRespondToAnnouncement {
  AppVersion?: string;
  AppUser?: string;
}
