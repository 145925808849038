import { NgModule } from '@angular/core';
import { TaskSelectorModalComponent } from './task-selector-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { FilterTasksPipe } from './filter-tasks.pipe';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FilterTasksPipe,
    FullHeightContainerModule,
    EmptyStubModule
  ],
  exports: [TaskSelectorModalComponent],
  declarations: [TaskSelectorModalComponent]
})
export class TaskSelectorModalModule {}
