<ion-header class="ion-no-border" />

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <div class="container {{ _isMobile ? 'mobile' : 'desktop' }}">
      <ion-card-header>
        <ion-card-title>
          {{ backdoorEula.title }}
        </ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <iframe> </iframe>
      </ion-card-content>
    </div>

    <div bottom class="modal-actions">
      <ion-button
        expand="block"
        (click)="accept()"
        appHaptics
        class="ion-no-margin"
      >
        I Agree
      </ion-button>
      <ion-button
        expand="block"
        *ngIf="preview"
        color="dark"
        fill="outline"
        (click)="dismiss()"
        appHaptics
        class="ion-no-margin"
      >
        Close (Only in Preview)
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>

<ion-loading #loading message="Loading..."></ion-loading>
