// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchTenantFeatures = (path: TSpecPathPatchTenantFeatures) => {
  let res = "/tenants/:tenantId/features";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_patchTenantFeatures = RequestHandler<TSpecPathPatchTenantFeatures, any, TSpecBodyPatchTenantFeatures, TSpecQueryPatchTenantFeatures, {}>;

export interface TSpecPathPatchTenantFeatures { tenantId: string; }

export interface TSpecBodyPatchTenantFeatures {
  checklist?: boolean;
  actionsUponTaskCompletion?: boolean;
  requestTaskFeedbackAttachments?: boolean;
  leaveTaskNoteAttachments?: boolean;
  timeAttack?: boolean;
  auditTasks?: boolean;
  auditAttachments?: boolean;
  tags?: boolean;
  announcements?: boolean;
  survey?: boolean;
  surveyFeedback?: boolean;
  insights?: boolean;
  dailyReport?: boolean;
  multiUserMode?: boolean;
  library?: boolean;
  announcementAttachments?: boolean;
  taskAttachments?: boolean;
  libraryDocAttachments?: boolean;
  connectedAccounts?: boolean;
  calculateRewards?: boolean;
  lottery?: boolean;
  userSchedule?: boolean;
  askTodaysShiftTime?: boolean;
  instantFeedback?: boolean;
  suggestions?: boolean;
  suggestionAttachments?: boolean;
}

export interface TSpecQueryPatchTenantFeatures {
  AppVersion?: string;
  AppUser?: string;
}
