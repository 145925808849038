<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button />
    </ion-buttons>
    <ion-title>Activity</ion-title>
    <ion-buttons slot="end">
      <ion-button
        id="filter-popover-button"
        appHaptics
        [attr.data-hidden]="!hasEditRights"
      >
        <ion-icon slot="icon-only" name="options-outline" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-pull-to-search
  #pullToSearch
  (searchInput)="handleSearchInput($event)"
  [searchDebounce]="700"
>
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="announcementsGroupedByDate.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList" />
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div
        *ngFor="
          let dataItem of announcementsGroupedByDate;
          let groupIndex = index;
          trackBy: trackDates
        "
        class="announcements__day"
      >
        <div class="announcements__date">{{ dataItem.date }}</div>
        <app-announcement-item
          *ngFor="
            let announcement of dataItem.items;
            let postIndex = index;
            trackBy: trackPosts
          "
          [announcement]="announcement"
          (click)="onAnnouncementClick(announcement._id)"
          appHaptics
          [animationOrder]="
            page === 1 ? postIndex + firstPagePostsCount(groupIndex) : -1
          "
          [showViewCount]="true"
        />
      </div>
    </ng-container>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="
        !(announcementsGroupedByDate.length === 0 || isLoading || !hasNextPage)
      "
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content />
    </ion-infinite-scroll>
  </app-full-height-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    *ngIf="hasEditRights"
    class="main-fab"
  >
    <ion-fab-button color="dark" (click)="onAddClick()" appHaptics>
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</app-pull-to-search>

<ng-template #emptyList>
  <app-empty-stub
    [icon]="searchTerm ? 'search-outline' : 'checkmark-circle-outline'"
    [text]="searchTerm ? 'Nothing Found' : 'There are no announcements for you'"
  />
</ng-template>

<ion-popover trigger="filter-popover-button" scroll>
  <ng-template>
    <ion-list lines="none">
      <ion-item>
        <ion-toggle
          [checked]="filters.showRewardReports"
          (ionChange)="handleFilter('showRewardReports', $event)"
          appHaptics
        >
          Show Reward Reports
        </ion-toggle>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
