// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_createTaskFolder = () => "/task-folders";

export type TSpecHandler_createTaskFolder = RequestHandler<TSpecPathCreateTaskFolder, any, TSpecBodyCreateTaskFolder, TSpecQueryCreateTaskFolder, {}>;

export interface TSpecPathCreateTaskFolder {  }

export interface TSpecBodyCreateTaskFolder {
  title: string;
  notifyUsersOnAllCriticalTasksCompleted: string[];
  emailUsersOnAllCriticalTasksCompleted: boolean;
}

export interface TSpecQueryCreateTaskFolder {
  AppVersion?: string;
  AppUser?: string;
}
