<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up" *ngIf="!input">
      <app-slim-menu-button />
    </ion-buttons>
    <ion-buttons slot="start" *ngIf="input">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!input">Upload a Tag</ion-title>
    <ion-title *ngIf="input">Edit Tag #{{ input.tag.tagNumber }}</ion-title>
    <ion-buttons slot="end" *ngIf="!input">
      <ion-button color="primary" (click)="viewTags()" appHaptics>
        View Tags
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar" *ngIf="!input">
    <ion-card-title
      class="ion-text-center ticket-number"
      *ngIf="tag; else reserveTagButton"
    >
      #{{ tag.tagNumber }}
    </ion-card-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [formGroup]="mainFormGroup">
    <ion-list [inset]="true">
      <ion-item button appHaptics (click)="onPickupDateInputClick($event)">
        <ion-label class="ion-text-capitalize">
          {{ pickupDateFieldName }}
        </ion-label>
        <ion-note slot="end">{{ pickupDate || 'Select' }}</ion-note>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item button appHaptics (click)="selectServer()">
        <ion-label>Server</ion-label>
        <ion-note slot="end">{{ formattedServer }}</ion-note>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Customer Cell"
          labelPlacement="floating"
          inputmode="numeric"
          formControlName="customerCell"
          type="tel"
          appPhoneMask
        />
      </ion-item>
      <ion-item *ngIf="customerNameEnabled">
        <ion-input
          label="Customer Name"
          labelPlacement="floating"
          formControlName="customerName"
        />
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="phoneError" [text]="phoneError" color="danger" />

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Number of Items"
          labelPlacement="floating"
          pattern="[1-9][0-9]*"
          formControlName="count"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
        />
      </ion-item>
      <ion-item>
        <ion-textarea
          label="Notes (optional)"
          formControlName="notes"
          labelPlacement="floating"
          [autoGrow]="true"
          autocapitalize="on"
        />
      </ion-item>
    </ion-list>

    <div bottom class="image-scroller">
      <div class="images">
        <div class="image-card">
          <div class="image-card-text">Tag</div>
          <button
            class="capture-btn"
            (click)="takePicture('TAG')"
            *ngIf="!tagImage"
            appHaptics
          >
            <ion-icon name="camera" size="large" />
          </button>
          <button
            *ngIf="tagImage"
            class="image-btn"
            (click)="viewPhoto(-1)"
            appHaptics
          >
            <img class="image" [src]="tagImage.url" />
          </button>
          <ion-fab-button
            *ngIf="tagImage"
            size="small"
            class="delete-btn"
            (click)="deletePhoto(tagImage.blobName)"
            color="danger"
            appHaptics
          >
            <ion-icon name="trash" />
          </ion-fab-button>
        </div>
        <div class="image-card">
          <div class="image-card-text">Items</div>
          <button
            class="capture-btn"
            (click)="takePicture('PRODUCT')"
            appHaptics
          >
            <ion-icon name="camera" size="large" />
          </button>
        </div>
        <div
          *ngFor="
            let image of productImages;
            trackBy: productImageTrackBy;
            let i = index
          "
          class="image-card"
        >
          <button class="image-btn" (click)="viewPhoto(i)" appHaptics>
            <img class="image" [src]="image.url" />
          </button>
          <ion-fab-button
            size="small"
            class="delete-btn"
            (click)="deletePhoto(image.blobName)"
            color="danger"
            appHaptics
          >
            <ion-icon name="trash" />
          </ion-fab-button>
        </div>
      </div>
    </div>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="!input"
        color="dark"
        expand="block"
        [disabled]="!canSave || !tag"
        (click)="uploadTag()"
        appHaptics
        [disableHaptics]="!canSave || !tag"
        class="ion-no-margin"
      >
        Upload
      </ion-button>
      <ion-button
        *ngIf="input"
        color="dark"
        expand="block"
        [disabled]="!canSave"
        (click)="updateTag()"
        appHaptics
        [disableHaptics]="!canSave"
        class="ion-no-margin"
      >
        Update
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      (ionChange)="onCalendarChange($event)"
    />
  </ng-template>
</ion-popover>

<ng-template #reserveTagButton>
  <ion-row class="ion-justify-content-center">
    <ion-button
      fill="clear"
      (click)="reserveTag()"
      class="ion-no-margin reserve-tag-btn"
    >
      <ion-icon slot="icon-only" name="refresh-outline" />
    </ion-button>
  </ion-row>
</ng-template>
