<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button />
    </ion-buttons>
    <ion-title> Suggestions </ion-title>
  </ion-toolbar>
</ion-header>

<app-pull-to-search
  #pullToSearch
  (searchInput)="handleSearchInput($event)"
  [searchDebounce]="700"
>
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <app-opportunity-item
      *ngFor="let opp of opportunities; trackBy: trackItems; let i = index"
      [opportunity]="opp"
      [animationOrder]="page === 1 ? i : -1"
      (click)="viewDoc(opp._id)"
    />

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      *ngIf="!(opportunities.length === 0 || isLoading || !hasNextPage)"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content />
    </ion-infinite-scroll>

    <ng-container *ngIf="opportunities.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList" />
    </ng-container>
  </app-full-height-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onAddClick()" appHaptics>
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</app-pull-to-search>

<ng-template #emptyList>
  <app-empty-stub text="No Suggestions Found" />
</ng-template>
