import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { ModalClass } from '@app/core/class/modal.class';
import { ObjectId } from '@app/types/object-id';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { IonModal } from '@ionic/angular/common';
import { FilterBehaviorsPipe } from './filter-behaviors.pipe';
import { showModal } from '@app/utils/modal';
import { ValuesModalComponent } from '../values-modal/values-modal.component';
import { BehaviorApiService } from '@app/core/service/api/behavior.api.service';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-behavior-selector-modal',
  templateUrl: './behavior-selector-modal.component.html'
})
export class BehaviorSelectorModalComponent
  extends ModalClass
  implements AfterViewInit
{
  @Input()
  public readonly focusSearchbar?: boolean = false;

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  @ViewChild('learnMoreModal')
  public learnMoreModal!: IonModal;

  protected selectedBehaviorId: ObjectId;
  protected filter = '';
  protected behaviors: BehaviorModel[];
  protected isLoading = true;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _behaviorApiService: BehaviorApiService,
    private readonly _tenantService: TenantService,
    private readonly _authenticationService: AuthenticationService
  ) {
    super();

    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      this._behaviorApiService
        .getNonSysGenBehaviors({
          path: { tenantId: tenant._id }
        })
        .subscribe((behaviorData) => {
          this.behaviors = behaviorData.filter(
            ({ isSystemGenerated, eligible }) =>
              isSystemGenerated === false &&
              (eligible.length === 0 ||
                eligible.find((e) =>
                  this._authenticationService.user.roles.includes(e)
                ))
          );
          this.isLoading = false;
        });
    });
  }

  public ngAfterViewInit(): void {
    if (this.focusSearchbar) {
      setTimeout(() => {
        this.ionSearchbar.setFocus();
      }, 600);
    }
  }

  protected trackItems(_: number, item: BehaviorModel) {
    return item._id;
  }

  protected cancelChanges() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected confirmChanges() {
    this._modalCtrl.dismiss(
      this.behaviors.find((t) => t._id === this.selectedBehaviorId),
      'confirm',
      this._modalName
    );
  }

  protected itemClick(value: ObjectId) {
    this.selectedBehaviorId = value;
    this.confirmChanges();
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected getFirst() {
    if (!this.filter) return null;

    const behaviors = new FilterBehaviorsPipe().transform(
      this.behaviors,
      this.filter
    );

    if (behaviors.length > 0) {
      return behaviors[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      this.selectedBehaviorId = first._id;

      this.confirmChanges();
    }
  }

  protected async learnMore() {
    await showModal({ component: ValuesModalComponent }, this._modalCtrl, {
      parentModalName: this._modalName
    });
  }
}
