import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ModalClass } from '@app/core/class/modal.class';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-wysiwyg-modal',
  templateUrl: './wysiwyg-modal.component.html',
  styleUrls: ['./wysiwyg-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WysiwygModalComponent extends ModalClass implements OnInit {
  @Input()
  public readonly mode?: 'view' | 'edit' = 'edit';

  @Input()
  public readonly body?: string = '';

  @Input()
  public readonly bodyPlaceholder?: string = 'Body';

  @Input()
  public readonly disableAttachments?: boolean = false;

  @Input()
  public readonly saveBtnText?: string = 'Done';

  @Input()
  public readonly cancelBtnText?: string = 'Cancel';

  @Input()
  public readonly dismissable?: boolean = true;

  @Input()
  public readonly title?: string = '';

  @Input()
  public readonly info?: { start?: string; end?: string };

  @Input()
  public readonly toolbarMode?: 'full' | 'image-only' = 'full';

  @Input()
  public readonly allowEmptyValue?: boolean = false;

  protected value = '';

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  public ngOnInit(): void {
    this.value = this.body;
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected onSaveClick(): void {
    this._modalCtrl.dismiss(this.value, 'confirm', this._modalName);
  }
}
