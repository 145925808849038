<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Behavior </ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-segment [value]="currentTab" (ionChange)="onCurrentTabChange($event)">
      <ion-segment-button value="behaviors">
        <ion-label>Recognition</ion-label>
      </ion-segment-button>
      <ion-segment-button value="system">
        <ion-label>System</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div [attr.data-hidden]="isLoading">
      <div
        class="behaviors-edit-component ion-padding-horizontal"
        [attr.data-hidden]="currentTab !== 'behaviors'"
      >
        <ejs-grid
          #normalgrid
          [dataSource]="behaviorSource"
          [editSettings]="editSettings"
          [toolbar]="toolbar"
          (actionBegin)="actionBegin($event)"
          (rowDrop)="rowDrop($event)"
          [allowRowDragAndDrop]="true"
          [enableImmutableMode]="false"
          [enableColumnVirtualization]="false"
          [allowPaging]="false"
          [allowSorting]="false"
          [allowFiltering]="false"
          [allowTextWrap]="true"
        >
          <e-columns>
            <e-column
              field="name"
              headerText="Name"
              textAlign="Left"
              width="175"
              [validationRules]="requiredValidation"
            ></e-column>
            <e-column
              field="points"
              headerText="Points"
              editType="numericedit"
              [edit]="numericParams"
              width="90px"
              textAlign="center"
              [validationRules]="requiredValidation"
            ></e-column>
            <e-column
              field="isValue"
              headerText="Value"
              width="75"
              type="boolean"
              [displayAsCheckBox]="true"
              editType="booleanedit"
            >
            </e-column>
            <e-column
              field="description"
              headerText="Description"
              textAlign="Left"
              width="150"
            >
              <ng-template let-data #template>
                <div class="disable-text-wrap">{{ data.description }}</div>
              </ng-template>
            </e-column>
            <e-column field="whoEligible" headerText="Available to" width="150">
              <ng-template let-data #editTemplate>
                <ion-select
                  *ngIf="!data.isSystemGenerated"
                  interface="action-sheet"
                  [(ngModel)]="data.whoEligible"
                  (ngModelChange)="onEligibleChange($event)"
                >
                  <ion-select-option value="Everyone">
                    Everyone
                  </ion-select-option>
                  <ion-select-option value="Only Manager and Admin">
                    Only Manager and Admin
                  </ion-select-option>
                  <ion-select-option value="Only Admin">
                    Only Admin
                  </ion-select-option>
                </ion-select>
                <ng-template #whoEligibleFallback>
                  {{ data.whoEligible }}
                </ng-template>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>

      <div>
        <div *ngIf="currentTab === 'system'" class="behaviors-list">
          <ion-list [inset]="true">
            <ion-item class="behaviors-list__item behaviors-list__head">
              <div class="behaviors-list__item-text">
                <ion-label>System Behavior</ion-label>
              </div>
              <div class="behaviors-list__item-module" slot="end">
                <ion-label>Module</ion-label>
              </div>
            </ion-item>
            <ion-item
              *ngFor="let behavior of sysGen.behaviors"
              class="behaviors-list__item"
              button
              (click)="onSystemBehaviorClick(behavior)"
            >
              <div class="behaviors-list__item-text">
                <ion-label>
                  {{ behavior.name }}
                </ion-label>
                <ion-label>Points: {{ behavior.points }}</ion-label>
              </div>
              <div
                *ngIf="behavior.module"
                class="behaviors-list__item-module"
                slot="end"
              >
                <ion-chip [color]="behavior.module.chipColor" disabled>
                  {{ behavior.module.chipText }}
                </ion-chip>
              </div>
            </ion-item>
            <ion-item
              *ngFor="let task of sysGen.tasks"
              class="behaviors-list__item"
              button
              (click)="onSystemTaskClick(task)"
            >
              <div class="behaviors-list__item-text">
                <ion-label> {{ task.title }}</ion-label>
                <ion-label class="behaviors-list__item-subtext">
                  Points: {{ task.points }}
                </ion-label>
              </div>
              <div
                *ngIf="task.module"
                class="behaviors-list__item-module"
                slot="end"
              >
                <ion-chip [color]="task.module.chipColor" disabled>
                  {{ task.module.chipText }}
                </ion-chip>
              </div>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </div>
  </app-full-height-container>
</ion-content>
