import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { ToastService } from '@app/core/service/toast.service';
import { confirmPasswordValidator } from '@app/utils/form-validators';
import { IonLoading } from '@ionic/angular';

@Component({
  selector: 'app-signup-user',
  templateUrl: './signup-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupUserComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected mainFormGroup: FormGroup = new FormGroup(
    {
      firstName: new FormControl<string>('', [
        Validators.required,
        Validators.pattern('[a-zA-Z -]*')
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.pattern('[a-zA-Z -]*')
      ]),
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6)
      ])
    },
    {
      validators: [confirmPasswordValidator]
    }
  );
  protected signupToken = '';
  protected noPassword = false;

  public constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService
  ) {
    this._route.params.subscribe((params) => {
      if (params.signupToken && params.signupToken.length) {
        this._userApiService
          .getInvitationStatus({
            query: { signupToken: params.signupToken }
          })
          .subscribe({
            next: () => {
              this.signupToken = params.signupToken;
            },
            error: ({ error }) => {
              this._toastService.presentToast(
                error.message || 'Something went wrong'
              );
              this._router.navigate(['/login']);
            }
          });
      } else {
        this._router.navigate(['/login']);
      }
    });

    this._route.queryParams.subscribe((params) => {
      if (params.noPassword) {
        this.noPassword = true;
        this.mainFormGroup.patchValue({
          password: 'Valchemy',
          confirmPassword: 'Valchemy'
        });
      }
    });
  }

  protected async submit() {
    const formValue = this.mainFormGroup.value;

    this.loading.present();

    this._userApiService
      .acceptUserInvitation({
        query: {
          signupToken: this.signupToken
        },
        body: {
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          password: formValue.password
        }
      })
      .subscribe({
        next: () => {
          this.loading.dismiss();
          this._toastService.presentToast(
            'You have been successfully signed up, now try to log in'
          );
          this._router.navigate(['/login']);
        },
        error: ({ error }) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
        }
      });
  }

  protected get formError(): string {
    if (
      this.mainFormGroup.get('firstName').dirty &&
      this.mainFormGroup.get('firstName').errors
    ) {
      if (this.mainFormGroup.get('firstName').errors.required) {
        return 'First name is required';
      } else if (this.mainFormGroup.get('firstName').errors.pattern) {
        return 'First name contains invalid characters';
      }
    }

    if (
      this.mainFormGroup.get('lastName').dirty &&
      this.mainFormGroup.get('lastName').errors
    ) {
      if (this.mainFormGroup.get('lastName').errors.required) {
        return 'Last name is required';
      } else if (this.mainFormGroup.get('lastName').errors.pattern) {
        return 'Last name contains invalid characters';
      }
    }

    if (
      this.mainFormGroup.get('password').dirty &&
      this.mainFormGroup.get('password').errors
    ) {
      if (this.mainFormGroup.get('password').errors.required) {
        return 'Password is required';
      } else if (this.mainFormGroup.get('password').errors.minlength) {
        return 'Password is too short';
      }
    }

    if (
      this.mainFormGroup.get('password').dirty &&
      this.mainFormGroup.get('confirmPassword').dirty &&
      this.mainFormGroup.errors &&
      this.mainFormGroup.errors['PasswordNoMatch']
    ) {
      return 'Passwords do not match';
    }

    return '';
  }

  protected onCloseClick() {
    this._router.navigate(['/']);
  }
}
