import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { IonTitle } from '@ionic/angular';

@Component({
  selector: 'app-content-title',
  templateUrl: './content-title.component.html',
  styleUrls: ['./content-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTitleComponent implements AfterViewInit {
  @Input()
  public readonly showLogo?: boolean = false;

  @Input()
  public readonly subtitle?: string;

  @Input()
  public readonly titleSize?: 'default' | 'extra-large' = 'default';

  @Input()
  public readonly align?: 'left' | 'center' = 'left';

  @ViewChild('title', { static: true })
  public title!: IonTitle;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const c = (this.title as any).el.shadowRoot.querySelector(
        '.toolbar-title'
      );

      if (c) {
        c.style.overflow = 'initial';
        c.style.whiteSpace = 'initial';
      }
    });
  }
}
