// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteMyReservedTag = (path: TSpecPathDeleteMyReservedTag) => {
  let res = "/tags/:tagId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteMyReservedTag = RequestHandler<TSpecPathDeleteMyReservedTag, any, TSpecBodyDeleteMyReservedTag, TSpecQueryDeleteMyReservedTag, {}>;

export interface TSpecPathDeleteMyReservedTag { tagId: string; }

export interface TSpecBodyDeleteMyReservedTag {}

export interface TSpecQueryDeleteMyReservedTag {
  AppVersion?: string;
  AppUser?: string;
}
