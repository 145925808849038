import { Types } from 'mongoose';

export enum UserRoles {
  // eslint-disable-next-line no-unused-vars
  Admin = 'Admin',
  // eslint-disable-next-line no-unused-vars
  Manager = 'Manager'
}

export enum EUserScheduleType {
  // eslint-disable-next-line no-unused-vars
  FIXED = 'FIXED',
  // eslint-disable-next-line no-unused-vars
  VARIABLE = 'VARIABLE'
}

export interface IUserSchedule {
  scheduleType: EUserScheduleType;
  weekDays: boolean[];
  weekTime: { from: string; to: string }[];
}

export interface IUser {
  parent?: Types.ObjectId;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone?: string;
  pin?: string;
  fcmToken?: string;
  receivePNs: boolean;
  sent: {
    points: number;
    count: number;
  };
  received: {
    points: number;
    count: number;
  };
  supervisor?: Types.ObjectId;
  department: Types.ObjectId | null;
  dateCreated: Date;
  roles: UserRoles[];
  isDeleted: boolean;
  tenant: Types.ObjectId;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;
  isInsightsHidden?: boolean;
  receiveDailyReports?: boolean;
  schedule: IUserSchedule;
  compensationEmployeeId?: string;
  signupToken?: string;
  changeEmailToken?: string;
  changeEmailNewEmail?: string;
}
