// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUserLatestShiftTime = () => "/users/latest-shift-time";

export type TSpecHandler_getUserLatestShiftTime = RequestHandler<TSpecPathGetUserLatestShiftTime, any, TSpecBodyGetUserLatestShiftTime, TSpecQueryGetUserLatestShiftTime, {}>;

export interface TSpecPathGetUserLatestShiftTime {  }

export interface TSpecBodyGetUserLatestShiftTime {}

export interface TSpecQueryGetUserLatestShiftTime {
  userId?: string;
  AppVersion?: string;
  AppUser?: string;
}
