// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getUnreadCount = () => "/instant-feedback/unread-count";

export type TSpecHandler_getUnreadCount = RequestHandler<TSpecPathGetUnreadCount, any, TSpecBodyGetUnreadCount, TSpecQueryGetUnreadCount, {}>;

export interface TSpecPathGetUnreadCount {  }

export interface TSpecBodyGetUnreadCount {}

export interface TSpecQueryGetUnreadCount {
  AppVersion?: string;
  AppUser?: string;
}
