import { NgModule } from '@angular/core';
import { AcknowledgmentsModalComponent } from './acknowledgments-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { PersonNamePipe } from './person-name.pipe';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    PersonNamePipe,
    FullHeightContainerModule
  ],
  exports: [AcknowledgmentsModalComponent],
  declarations: [AcknowledgmentsModalComponent]
})
export class AcknowledgmentsModalModule {}
