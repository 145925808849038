<div
  [class]="
    'wysiwyg-editor wysiwyg-editor--toolbar-' +
    toolbarMode +
    ' wysiwyg-editor--' +
    mode +
    '-mode'
  "
  (click)="setFocus()"
  [attr.data-currentFontSize]="currentFontSize"
>
  <angular-editor
    #wysiwygEditor
    id="wysiwyg-editor"
    [(ngModel)]="body"
    (ngModelChange)="onChange($event)"
    [config]="editorConfig"
  />
</div>
