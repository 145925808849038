// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTimeAttackStats = (path: TSpecPathGetTimeAttackStats) => {
  let res = "/tasks/:id/time-attack-stats";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTimeAttackStats = RequestHandler<TSpecPathGetTimeAttackStats, any, TSpecBodyGetTimeAttackStats, TSpecQueryGetTimeAttackStats, {}>;

export interface TSpecPathGetTimeAttackStats { id: string; }

export interface TSpecBodyGetTimeAttackStats {}

export interface TSpecQueryGetTimeAttackStats {
  AppVersion?: string;
  AppUser?: string;
}
