import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'app-full-height-container',
  templateUrl: './full-height-container.component.html',
  styleUrls: ['./full-height-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullHeightContainerComponent implements AfterViewInit {
  @Input()
  public readonly maxWidth: 'default' | 'modal' = 'default';

  @Input()
  public readonly isModal: boolean = false;

  @Input()
  public readonly isFullHeight: boolean = false;

  @Input()
  public readonly isVirtualScroll: boolean = false;

  @Input()
  public readonly withVerticalPadding: boolean = false;

  @HostBinding('class.full-height')
  public get fullHeight() {
    return this.isFullHeight || this.isVirtualScroll;
  }

  @HostBinding('class.is-modal')
  public get modal() {
    return this.isModal;
  }

  public ngAfterViewInit(): void {
    if (this.isVirtualScroll && this.isModal) {
      const scrollcontainer = document.querySelector<HTMLDivElement>(
        '.virtual-scroll-container'
      );

      if (scrollcontainer) {
        scrollcontainer.ontouchmove = function (e) {
          e.stopPropagation();
        };
      }
    }
  }
}
