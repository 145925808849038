import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { HapticsService } from '@app/core/service/haptics.service';
import { TenantApiService } from '@app/core/service/api/tenant.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { ITenant } from '@backend/models/types/tenant';
import { IonModal, IonNav } from '@ionic/angular';
import {
  Observable,
  Subject,
  defer,
  of,
  repeat,
  shareReplay,
  switchMap
} from 'rxjs';

interface SourceTaskListItem {
  task: MongoStoredObject<
    TaskDto & { taskFolder: MongoStoredObject<ITaskFolder> }
  >;
  checked: boolean;
}

@Component({
  selector: 'app-tenant-tasks',
  templateUrl: 'tenant-tasks.component.html',
  styleUrls: ['./tenant-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantTasksComponent {
  @Input()
  public tenant: MongoStoredObject<ITenant>;

  @Input()
  public tenants: MongoStoredObject<ITenant>[];

  @ViewChild(IonModal)
  public modal: IonModal;
  protected copySource: string | null = null;
  protected skipSameNameTasks = true;

  private _update$ = new Subject<void>();
  public taskList$: Observable<
    MongoStoredObject<
      TaskDto & { taskFolder: MongoStoredObject<ITaskFolder> }
    >[]
  > = defer(() => of(this.tenant)).pipe(
    switchMap((tenant) =>
      this._api
        .getTenantTasks({ path: { id: tenant._id } })
        .pipe(repeat({ delay: () => this._update$ }))
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public sourceTaskList: SourceTaskListItem[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _api: TenantApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _hapticsService: HapticsService
  ) {}

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected taskTrackBy(_index: number, item: MongoStoredObject<TaskDto>) {
    return item._id;
  }

  protected onSkipSameNameTasksChanged(event: unknown) {
    console.log(event);
  }

  protected cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  protected confirm() {
    this._api
      .copyTasksFrom({
        path: { id: this.tenant._id },
        body: {
          tasks: this.sourceTaskList
            .filter(({ checked }) => checked)
            .map(({ task }) => task._id),
          skipSameNameTasks: this.skipSameNameTasks
        }
      })
      .subscribe({
        next: () => {
          this._update$.next(), this.modal.dismiss(null, 'confirm');
        },
        error: (e) => console.error(e)
      });
  }

  protected onTaskSourceChange() {
    this._hapticsService.hapticsImpact();

    this._api.getTenantTasks({ path: { id: this.copySource } }).subscribe({
      next: (tasks) => {
        this.sourceTaskList = tasks.map((task) => ({ task, checked: true }));
        this._cdr.markForCheck();
      }
    });
  }
}
