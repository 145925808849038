import { Component, Input, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable, take } from 'rxjs';
import { AuthenticationService } from '@app/core';
import { AnnouncementApiService } from '@app/core/service/api/announcement.api.service';
import {
  ActionSheetController,
  IonNav,
  IonPopover,
  LoadingController,
  ModalController,
  ViewDidEnter,
  ViewWillEnter
} from '@ionic/angular';
import { AnnouncementAddComponent } from '@app/pages/announcements/announcement-add/announcement-add.component';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { AnnouncementListService } from '@app/core/service/announcement-list.service';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { ToastService } from '@app/core/service/toast.service';
import { AcknowledgmentsModalComponent } from '@app/modals/acknowledgments-modal/acknowledgments-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '@app/core/service/environment.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import {
  EAnnouncementAcknowledgeButtonText,
  EAnnouncementActions,
  EAnnouncementRespondButtonText
} from '@backend/models/types/announcement';
import { AnnouncementRespondModalComponent } from '@app/modals/announcement-respond-modal/announcement-respond-modal.component';
import { TenantService } from '@app/core/service/tenant.service';
import { showModal } from '@app/utils/modal';

@Component({
  selector: 'app-announcement-view',
  templateUrl: './announcement-view.component.html',
  styleUrls: ['./announcement-view.component.scss']
})
export class AnnouncementViewComponent implements ViewWillEnter, ViewDidEnter {
  @Input()
  public id: string;

  @ViewChild('options', { static: true })
  public options!: IonPopover;

  protected readonly announcementActions = EAnnouncementActions;
  protected readonly announcementAcknowledgeButtonText =
    EAnnouncementAcknowledgeButtonText;
  protected readonly announcementRespondButtonText =
    EAnnouncementRespondButtonText;

  protected announcement: AnnouncementModel = {
    _id: '',
    title: '',
    body: '',
    recipients: [],
    date: new Date(),
    author: '',
    authorName: '',
    isCritical: false,
    attachments: [],
    actions: EAnnouncementActions.ACKNOWLEDGE_AND_RESPOND,
    acknowledgeButtonText:
      EAnnouncementAcknowledgeButtonText.UNDERSTAND_AND_AGREE,
    respondButtonText: EAnnouncementRespondButtonText.REQUEST_CLARIFICATION,
    libraryDoc: null,
    taskAudit: null
  };
  protected body: string;
  protected user: UserModel | null;
  protected isLoading = true;
  protected hasEditRights = false;
  protected showOptions = false;
  protected thread: AnnouncementModel[] = [];

  public readonly allUsers$: Observable<UserModel[]> =
    this._userListService.users$;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _ionNav: IonNav,
    private readonly _announcementList: AnnouncementListService,
    private readonly _userListService: UserListService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _announcementApiService: AnnouncementApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _tenantService: TenantService
  ) {
    this.user = this._authenticationService.user;

    this._tenantService.tenant$.subscribe((tenant) => {
      this.hasEditRights =
        tenant.settings.announcements.whoCanSendAnnouncements.reduce(
          (prev, role) =>
            prev || this._authenticationService.user.roles.includes(role),
          false
        );
    });
  }

  public ionViewWillEnter(): void {
    this._fetchAnnouncement();
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  private _fetchAnnouncement() {
    this.isLoading = true;
    this._route.params.subscribe((params) => {
      const queryId = params['id'];

      let announcementId = this.id;

      if (!announcementId) {
        if (queryId !== '' && queryId !== undefined) {
          announcementId = queryId;
        } else {
          this._router.navigate(['/i/announcements'], { replaceUrl: true });
        }
      }

      this._announcementList.readAnnouncement(announcementId).subscribe({
        next: () => {
          this._announcementApiService
            .getAnnouncement({ path: { id: announcementId } })
            .subscribe((announcement) => {
              this.announcement = announcement;
              this.body = formatUploadedBody(
                announcement.body,
                announcement.attachments,
                this._environmentService.serverUrl
              );
              this.isLoading = false;
            });
        },
        error: () => {
          this._router.navigate(['/i/announcements'], { replaceUrl: true });
        }
      });

      this._announcementList
        .getThreadOfAnnouncement(announcementId)
        .subscribe((thread) => {
          this.thread = thread;
        });
    });
  }

  protected get respondButtonText() {
    switch (this.announcement.respondButtonText) {
      case EAnnouncementRespondButtonText.RESPOND:
        return 'Respond';
      case EAnnouncementRespondButtonText.REPORT_ISSUE:
        return 'Report Issue';
      default:
        return 'Share Feedback';
    }
  }

  protected get acknowledged() {
    return this.announcement.recipients.filter((a) => a.acknowledged);
  }

  protected get read() {
    return this.announcement.recipients.filter(
      (a) => a.read && !a.acknowledged
    );
  }

  protected get unread() {
    return this.announcement.recipients.filter(
      (a) => !a.read && !a.acknowledged
    );
  }

  protected async showAcknowledgments() {
    await showModal(
      {
        component: AcknowledgmentsModalComponent,
        props: {
          announcement: this.announcement
        }
      },
      this._modalCtrl
    );
  }

  protected get alreadyAcknowledged() {
    const me = this.announcement.recipients.find(
      (recipient) => recipient.id == this.user._id
    );
    return !me || me.acknowledged;
  }

  protected async acknowledge() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._announcementList
      .acknowledgeAnnouncement(this.announcement._id)
      .subscribe(() => {
        loading.dismiss();
        this._toastService.presentToast(
          'Announcement acknowledged successfully!'
        );
        this._fetchAnnouncement();
      });
  }

  protected fDate(date: Date) {
    return formatDate(date, 'MMM dd, yyyy', 'en-US');
  }

  protected fDateAndTime(date: Date) {
    return formatDate(date, 'MMM dd, yyyy h:mm a', 'en-US');
  }

  protected editAnnouncement() {
    this.allUsers$.pipe(take(1)).subscribe((users) => {
      this._ionNav.push(AnnouncementAddComponent, {
        announcement: this.announcement,
        users
      });
    });
  }

  protected async askDeleteAnnouncement() {
    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Are you sure?',
      subHeader:
        "This announcement will be deleted immediately. You can't undo this action.",
      buttons: [
        {
          role: 'destructive',
          text: 'Delete'
        },
        {
          role: 'cancel',
          text: 'Cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      this.deleteAnnouncement();
    }
  }

  protected async deleteAnnouncement() {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._announcementList.deleteAnnouncement(this.announcement._id).subscribe({
      next: () => {
        this._toastService.presentToast('Announcement deleted successfully!');
        this._ionNav.pop();
      },
      error: () => {
        loading.dismiss();
        this._toastService.presentToast(
          'Unable to delete announcement, try again later'
        );
      }
    });
  }

  protected get editedString() {
    return this.announcement.edited
      ? `Last edit: ${this.fDateAndTime(this.announcement.edited)}`
      : '';
  }

  protected onBackButtonClick(): void {
    if (this.id) {
      this._ionNav.pop();
    } else {
      this._router.navigate(['/i/announcements']);
    }
  }

  protected openOptions(e: Event): void {
    this.options.event = e;
    this.showOptions = true;
  }

  protected async respond() {
    await showModal(
      {
        component: AnnouncementRespondModalComponent,
        props: {
          announcement: this.announcement
        }
      },
      this._modalCtrl
    );
  }
}
