import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';

import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterUsers',
  standalone: true
})
export class FilterUsersPipe implements PipeTransform {
  public transform(
    users: UserModel[],
    filter: string,
    showDisabledUsers: boolean
  ): UserModel[] {
    const filteredUsers = showDisabledUsers
      ? users
      : users.filter((user) => !user.isDeleted);

    if (filter.length === 0) {
      return filteredUsers;
    }
    const searcher = new FuzzySearch(
      filteredUsers,
      ['firstName', 'lastName', 'email', 'roles'],
      {
        caseSensitive: false
      }
    );
    return searcher.search(filter);
  }
}
