import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy
} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ModalClass } from '@app/core/class/modal.class';
import { HapticsService } from '@app/core/service/haptics.service';
import { InsightsDateRangeHelperService } from '@app/core/service/insights-date-range-helper.service';
import { RangePresets } from '@app/types/range-presets';
import { DateInterval } from '@backend/types/date-interval';
import { ModalController } from '@ionic/angular';
import { endOfDay, startOfDay } from 'date-fns';
import { Subscription, filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-insights-range-modal',
  templateUrl: 'insights-range-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsRangeModalComponent
  extends ModalClass
  implements OnDestroy
{
  protected rangePresets = [
    [RangePresets.ThisPayPeriod, 'This Pay Period'],
    [RangePresets.LastPayPeriod, 'Last Pay Period'],
    [RangePresets.Today, 'Today'],
    [RangePresets.Yesterday, 'Yesterday'],
    [RangePresets.Last7Days, 'Last 7 Days'],
    [RangePresets.Last30Days, 'Last 30 Days'],
    [RangePresets.Last90Days, 'Last 90 Days'],
    [RangePresets.LastMonth, 'Last Month'],
    [RangePresets.ThisYear, 'This Year'],
    [RangePresets.LastYear, 'Last Year'],
    [RangePresets.AllTime, 'All Time'],
    [RangePresets.Custom, 'Custom']
  ];

  public set range(value: DateInterval) {
    this.form.reset(value, { emitEvent: false });
    this._rangeHelper.detectPreset(value).subscribe({
      next: (preset) => {
        this.selectedPreset = preset;
        this._cdr.markForCheck();
      },
      error: (e) => console.log('error detecting preset', e)
    });
  }
  protected selectedPreset: RangePresets;
  public form = new FormGroup({
    start: new FormControl(null, Validators.required),
    end: new FormControl(null, Validators.required)
  });

  private _subscriptions = new Subscription();

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _rangeHelper: InsightsDateRangeHelperService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _hapticsService: HapticsService
  ) {
    super();

    this._subscriptions.add(
      this.form.valueChanges
        .pipe(
          filter(
            ({ start, end }) =>
              start !== null && end !== null && start.getTime() > end.getTime()
          )
        )
        .subscribe(({ start, end }) => {
          this.form.setValue({ start: startOfDay(end), end: endOfDay(start) });
          this.form.updateValueAndValidity();
        })
    );
    this._subscriptions.add(
      this.form.valueChanges
        .pipe(
          filter(
            (range): range is DateInterval =>
              range.start !== null && range.end !== null
          ),
          switchMap((range) => this._rangeHelper.detectPreset(range))
        )
        .subscribe((preset) => {
          this.selectedPreset = preset;
        })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected onModelSelect(preset: RangePresets): void {
    this._hapticsService.hapticsImpact();

    if (preset !== RangePresets.Custom) {
      this._rangeHelper.getIntervalFromPreset(preset).subscribe((range) => {
        this.form.setValue(range);
        this.form.updateValueAndValidity();
        this.submit();
      });
    }
  }

  protected submit(): void {
    this._modalCtrl.dismiss(this.form.value, 'confirm', this._modalName);
  }
}
