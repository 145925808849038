import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { isMobile } from './is-mobile';
import { ObjectId } from '@app/types/object-id';
import { UserSelectorModalComponent } from '@app/modals/user-selector-modal/user-selector-modal.component';
import { UserModel, UserNameModel } from '@app/core/model/user.model';
import { BehaviorSelectorModalComponent } from '@app/modals/behavior-selector-modal/behavior-selector-modal.component';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { TaskDto } from '@app/types/task-dto';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskSelectorModalComponent } from '@app/modals/task-selector-modal/task-selector-modal.component';
import { WysiwygModalComponent } from '@app/modals/wysiwyg-modal/wysiwyg-modal.component';
import { InsightsRangeModalComponent } from '@app/modals/insights-range-modal/insights-range-modal.component';
import { DateInterval } from '@backend/types/date-interval';

export interface IModalOptions<T> {
  parentModalName?: string;
  useTopModalAsParent?: boolean;
  canDismiss?: boolean | ((data: T, role: string) => Promise<boolean>);
  backdropDismiss?: boolean;
  onDidDismiss?: () => void;
}

export const showModal = async <T>(
  params: {
    component: ComponentRef;
    props?: ComponentProps<ComponentRef>;
  },
  modalCtrl: ModalController,
  options?: IModalOptions<T>
): Promise<{
  role: string;
  data: T;
}> => {
  const isM = isMobile();
  const modalName = (params.component as any).name;

  const exists = document.getElementById(modalName);
  if (exists) return;

  let parentModalName = options?.parentModalName;

  if (options?.useTopModalAsParent) {
    const topModal = await modalCtrl.getTop();
    if (topModal) {
      parentModalName = topModal.id;
    }
  }

  const modal = await modalCtrl.create({
    id: modalName,
    cssClass: isM ? undefined : 'modal-auto-height',
    component: params.component,
    componentProps: params.props,
    handle: false,
    presentingElement: isM
      ? parentModalName
        ? document.getElementById(parentModalName)
        : document.querySelector('ion-split-pane')
      : undefined,
    canDismiss: options?.canDismiss,
    backdropDismiss: options?.backdropDismiss
  });
  modal.present();

  const { role, data } = await modal.onWillDismiss();

  modal.onDidDismiss().then(() => {
    if (options?.onDidDismiss) {
      options.onDidDismiss();
    }
  });

  return {
    role,
    data
  };
};

export const showUserSelectorModal = async (
  modalProps: {
    recipients?: ObjectId[];
    hideSelectAllButton?: boolean;
    title?: string;
    users: (UserModel | UserNameModel)[];
    hiddenUsers?: ObjectId[];
    multiple?: boolean;
    focusSearchbar?: boolean;
    doneBtnText?: string;
  },
  modalCtrl: ModalController,
  options?: IModalOptions<ObjectId[]>
): Promise<{
  success: boolean;
  selectedUsers: ObjectId[];
}> => {
  const { role, data } = await showModal<ObjectId[]>(
    {
      component: UserSelectorModalComponent,
      props: modalProps
    },
    modalCtrl,
    options
  );

  if (role === 'confirm') {
    return { success: true, selectedUsers: data };
  }

  return { success: false, selectedUsers: [] };
};

export const showTaskSelectorModal = async (
  modalProps: {
    selectedTasks?: ObjectId[];
    title?: string;
    tasks: MongoStoredObject<TaskDto>[];
    hiddenTasks?: ObjectId[];
    multiple?: boolean;
    focusSearchbar?: boolean;
    emptyText?: string;
  },
  modalCtrl: ModalController,
  options?: IModalOptions<ObjectId[]>
): Promise<{
  success: boolean;
  selectedTasks: ObjectId[];
}> => {
  const { role, data } = await showModal<ObjectId[]>(
    {
      component: TaskSelectorModalComponent,
      props: modalProps
    },
    modalCtrl,
    options
  );

  if (role === 'confirm') {
    return { success: true, selectedTasks: data };
  }

  return { success: false, selectedTasks: [] };
};

export const showBehaviorSelectorModal = async (
  modalProps: {
    behaviors?: BehaviorModel[];
    focusSearchbar?: boolean;
  },
  modalCtrl: ModalController,
  options?: IModalOptions<BehaviorModel>
): Promise<{
  success: boolean;
  selectedBehavior: BehaviorModel;
}> => {
  const { role, data } = await showModal<BehaviorModel>(
    {
      component: BehaviorSelectorModalComponent,
      props: modalProps
    },
    modalCtrl,
    options
  );

  if (role === 'confirm') {
    return { success: true, selectedBehavior: data };
  }

  return { success: false, selectedBehavior: null };
};

export const showWysiwygModal = async (
  modalProps: {
    mode?: 'view' | 'edit';
    body?: string;
    bodyPlaceholder?: string;
    disableAttachments?: boolean;
    saveBtnText?: string;
    cancelBtnText?: string;
    dismissable?: boolean;
    title?: string;
    info?: { start?: string; end?: string };
    toolbarMode?: 'full' | 'image-only';
    allowEmptyValue?: boolean;
  },
  modalCtrl: ModalController,
  options?: Omit<IModalOptions<string>, 'canDismiss' | 'backdropDismiss'>
): Promise<{
  success: boolean;
  body: string;
}> => {
  const { role, data } = await showModal<string>(
    {
      component: WysiwygModalComponent,
      props: modalProps
    },
    modalCtrl,
    {
      ...options,
      canDismiss:
        typeof modalProps.dismissable === 'boolean' && !modalProps.dismissable
          ? async (_, role) => role === 'confirm'
          : true,
      backdropDismiss:
        typeof modalProps.dismissable === 'boolean'
          ? modalProps.dismissable
          : true
    }
  );

  if (role === 'confirm') {
    return { success: true, body: data };
  }

  return { success: false, body: '' };
};

export const showDateRangeModal = async (
  modalProps: {
    range: DateInterval;
  },
  modalCtrl: ModalController,
  options?: IModalOptions<DateInterval>
): Promise<{
  success: boolean;
  range: DateInterval;
}> => {
  const { role, data } = await showModal<DateInterval>(
    {
      component: InsightsRangeModalComponent,
      props: modalProps
    },
    modalCtrl,
    options
  );

  if (role === 'confirm') {
    return { success: true, range: data };
  }

  return { success: false, range: null };
};
