<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> General </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Company Name"
          type="text"
          [ngModel]="companyName"
          [ngModelOptions]="{ standalone: true }"
          [debounce]="700"
          (ionInput)="onCompanyInput($any($event))"
          labelPlacement="floating"
          required
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
      <ion-item button (click)="selectTimezone()" appHaptics>
        <ion-label>Team Timezone</ion-label>
        <ion-note slot="end">{{ timezone }}</ion-note>
      </ion-item>
      <ion-item>
        <ion-select
          labelPlacement="floating"
          aria-label="After Login, User Goes to"
          interface="popover"
          label="After Login, User Goes to"
          [value]="loginRedirectsTo"
          (ionChange)="onLoginRedirectsToChange($any($event))"
          appHaptics
        >
          <ion-select-option value="/i/recognition">
            Recognition
          </ion-select-option>
          <ion-select-option
            value="/i/checklist"
            *ngIf="tenantFeatures.checklist"
          >
            Checklist
          </ion-select-option>
          <ion-select-option value="/i/tags" *ngIf="tenantFeatures.tags">
            Tags
          </ion-select-option>
          <ion-select-option
            value="/i/announcements"
            *ngIf="tenantFeatures.announcements"
          >
            Announcements
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <app-list-pre-line
      title="Departments"
      [showAddButton]="true"
      (addClicked)="addDepartment()"
    />
    <ion-list [inset]="true">
      <ion-item
        *ngFor="let department of departments; trackBy: trackDepartments"
        button
        (click)="updateDepartment(department)"
        appHaptics
      >
        <ion-label>{{ department.name }}</ion-label>
      </ion-item>
    </ion-list>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
