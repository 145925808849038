<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Pool Amounts</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container>
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="pools.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div class="layout-container" *ngIf="!isLoading && pools.length > 0">
      <div class="top-content">
        <ion-card
          *ngFor="let pool of pools; trackBy: itemTrackBy"
          class="pool-card"
        >
          <ion-card-header>
            <ion-card-title class="pool-card-title">
              {{ pool.name }}
            </ion-card-title>
          </ion-card-header>
          <div class="pool-body">
            <ion-input
              class="input-with-background"
              [label]="pool.type"
              label-placement="floating"
              [value]="pool.value"
              inputmode="decimal"
              (ionChange)="onInputChange($event, pool)"
              placeholder="0"
              fill="solid"
            ></ion-input>
          </div>
        </ion-card>
      </div>
      <div class="bottom-content">
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-padding">
            <ion-col size="4">
              <ion-button
                color="dark"
                (click)="next()"
                appHaptics
                expand="block"
              >
                Next
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
