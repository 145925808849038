// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addBackdoorEula = () => "/backdoor/eula";

export type TSpecHandler_addBackdoorEula = RequestHandler<TSpecPathAddBackdoorEula, any, TSpecBodyAddBackdoorEula, TSpecQueryAddBackdoorEula, {}>;

export interface TSpecPathAddBackdoorEula {  }

export interface TSpecBodyAddBackdoorEula {
  title: string;
  iframeCode: string;
  canDismiss: boolean;
  tenantsToShow: string[];
  showToAllTenants: boolean;
}

export interface TSpecQueryAddBackdoorEula {
  AppVersion?: string;
  AppUser?: string;
}
