// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_uploadTag = (path: TSpecPathUploadTag) => {
  let res = "/tags/:tagId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_uploadTag = RequestHandler<TSpecPathUploadTag, any, TSpecBodyUploadTag, TSpecQueryUploadTag, {}>;

export interface TSpecPathUploadTag { tagId: string; }

export interface TSpecBodyUploadTag {
  pickupDate: string;
  serverId: string;
  customerCell: string;
  customerName: string;
  notes: string;
  count: number;
  images: {
    blobName: string;
    originalname: string;
    url: string;
  }[];
}

export interface TSpecQueryUploadTag {
  AppVersion?: string;
  AppUser?: string;
}
