import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { FormatRolesPipe } from './format-roles.pipe';
import { FilterUsersPipe } from './filter-users.pipe';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ManageUserTasksModalModule } from '@app/modals/manage-user-tasks-modal/manage-user-tasks-modal.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SkillMatrixForUserModalModule } from '@app/modals/skill-matrix-for-user-modal/skill-matrix-for-user-modal.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { ListPreLineModule } from '@app/modules/list-pre-line/list-pre-line.module';
import { UserScheduleEditorModalModule } from '@app/modals/user-schedule-editor-modal/user-schedule-editor-modal.module';
import { DepartmentApiService } from '@app/core/service/api/department.api.service';
import { FullHeightContainerModule } from '../../modules/full-height-container/full-height-container.module';
import { UserSelectorModalModule } from '@app/modals/user-selector-modal/user-selector-modal.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Users',
      rootComponent: UsersComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    DirectivesModule,
    FormatRolesPipe,
    FilterUsersPipe,
    ManageUserTasksModalModule,
    ScrollingModule,
    UserScheduleEditorModalModule,
    SkillMatrixForUserModalModule,
    ListPostLineModule,
    ListPreLineModule,
    FullHeightContainerModule,
    UserSelectorModalModule
  ],
  declarations: [UsersComponent, InviteUserComponent, EditUserComponent],
  providers: [DepartmentApiService]
})
export class UsersModule {}
