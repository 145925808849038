// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_updateTag = (path: TSpecPathUpdateTag) => {
  let res = "/tags/:tagId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_updateTag = RequestHandler<TSpecPathUpdateTag, any, TSpecBodyUpdateTag, TSpecQueryUpdateTag, {}>;

export interface TSpecPathUpdateTag { tagId: string; }

export interface TSpecBodyUpdateTag {
  pickupDate: string;
  serverId: string;
  customerCell: string;
  customerName: string;
  notes: string;
  count: number;
  images: {
    blobName: string;
    originalname: string;
    url: string;
  }[];
}

export interface TSpecQueryUpdateTag {
  AppVersion?: string;
  AppUser?: string;
}
