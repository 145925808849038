// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getInsights = () => "/users/insights";

export type TSpecHandler_getInsights = RequestHandler<TSpecPathGetInsights, any, TSpecBodyGetInsights, TSpecQueryGetInsights, {}>;

export interface TSpecPathGetInsights {  }

export interface TSpecBodyGetInsights {}

export interface TSpecQueryGetInsights {
  start: string;
  end: string;
  AppVersion?: string;
  AppUser?: string;
}
