import { NgModule } from '@angular/core';
import { CompensationPoolModalComponent } from './compensation-pool-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { UserSelectorModalModule } from '../user-selector-modal/user-selector-modal.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UserSelectorModalModule,
    DirectivesModule,
    FullHeightContainerModule
  ],
  exports: [CompensationPoolModalComponent],
  declarations: [CompensationPoolModalComponent]
})
export class CompensationPoolModalModule {}
