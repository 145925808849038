import { Component } from '@angular/core';
import { BackdoorApiService } from '@app/core/service/api/backdoor.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { BackdoorInfoAddComponent } from './components/backdoor-info-add/backdoor-info-add.component';
import {
  IonNav,
  LoadingController,
  ViewDidEnter,
  ViewWillEnter
} from '@ionic/angular';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { combineLatest, Observable } from 'rxjs';
import { ITenant } from '@backend/models/types/tenant';
import { ToastService } from '@app/core/service/toast.service';
import { IBackdoorEula } from '@backend/models/types/backdoor-eula';
import { BackdoorEulaAddComponent } from './components/backdoor-eula-add/backdoor-eula-add.component';

@Component({
  selector: 'app-backdoor',
  templateUrl: './backdoor.component.html',
  styleUrls: ['./backdoor.component.scss']
})
export class BackdoorComponent implements ViewWillEnter, ViewDidEnter {
  protected backdoorInfos: MongoStoredObject<IBackdoorInfo>[] = [];
  protected backdoorEulas: MongoStoredObject<IBackdoorEula>[] = [];
  protected isLoading = false;
  protected tenantList$: Observable<MongoStoredObject<ITenant>[]> =
    this._tenantService.tenants$;
  protected tenants: MongoStoredObject<ITenant>[] = [];
  protected currentTab = 'infos';

  public constructor(
    private readonly _backdoorApiService: BackdoorApiService,
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantListService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService
  ) {}

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  public ionViewWillEnter() {
    this.isLoading = true;
    this.tenantList$.subscribe((tenants) => {
      this.tenants = tenants;

      combineLatest([
        this._backdoorApiService.getAllBackdoorInfos(),
        this._backdoorApiService.getAllBackdoorEulas()
      ]).subscribe(([backdoorInfos, backdoorEulas]) => {
        this.backdoorInfos = backdoorInfos;
        this.backdoorEulas = backdoorEulas;
        this.isLoading = false;
      });
    });
  }

  protected onFabClick() {
    if (this.currentTab === 'infos') {
      this._ionNav.push(BackdoorInfoAddComponent);
    } else {
      this._ionNav.push(BackdoorEulaAddComponent);
    }
  }

  protected async updateBackdoorInfo(
    backdoorInfo: MongoStoredObject<IBackdoorInfo>
  ) {
    this._ionNav.push(BackdoorInfoAddComponent, { backdoorInfo });
  }

  protected async updateBackdoorEula(
    backdoorEula: MongoStoredObject<IBackdoorEula>
  ) {
    this._ionNav.push(BackdoorEulaAddComponent, { backdoorEula });
  }

  protected async deleteBackdoorInfo(
    backdoorInfo: MongoStoredObject<IBackdoorInfo>
  ) {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._backdoorApiService
      .deleteBackdoorInfo({ path: { id: backdoorInfo._id.toString() } })
      .subscribe(() => {
        this.backdoorInfos = this.backdoorInfos.filter(
          (m) => m._id !== backdoorInfo._id
        );
        loading.dismiss();
        this._toastService.presentToast('Backdoor Message Deleted');
      });
  }

  protected async deleteBackdoorEula(
    backdoorEula: MongoStoredObject<IBackdoorEula>
  ) {
    const loading = await this._loadingCtrl.create({
      message: 'Deleting...'
    });
    loading.present();

    this._backdoorApiService
      .deleteBackdoorEula({ path: { id: backdoorEula._id.toString() } })
      .subscribe(() => {
        this.backdoorEulas = this.backdoorEulas.map((m) =>
          m._id === backdoorEula._id ? { ...m, isDeleted: true } : m
        );
        loading.dismiss();
        this._toastService.presentToast('Backdoor EULA Deleted');
      });
  }

  protected onCurrentTabChange(event: any): void {
    this.currentTab = event.detail.value;
  }

  protected get currentEula() {
    const eulas = this.backdoorEulas.filter((eula) => !eula.isDeleted);
    return eulas.length ? eulas[0] : null;
  }
}
