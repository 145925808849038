// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllBehaviors = (path: TSpecPathGetAllBehaviors) => {
  let res = "/tenants/:tenantId/behaviors";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getAllBehaviors = RequestHandler<TSpecPathGetAllBehaviors, any, TSpecBodyGetAllBehaviors, TSpecQueryGetAllBehaviors, {}>;

export interface TSpecPathGetAllBehaviors { tenantId: string; }

export interface TSpecBodyGetAllBehaviors {}

export interface TSpecQueryGetAllBehaviors {
  AppVersion?: string;
  AppUser?: string;
}
