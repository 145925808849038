<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Training</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" color="primary" appHaptics>
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <ion-list *ngFor="let status of statuses">
      <ng-container *ngIf="getUsersOfStatus(status.status) as users">
        <ion-item class="group-title">
          <ion-button
            (click)="selectUsersForGroup(status.status)"
            fill="clear"
            class="ion-no-margin"
            [color]="status.color"
          >
            {{ status.title }}
          </ion-button>
        </ion-item>
        <app-skill-matrix-status-select
          *ngFor="let user of users; trackBy: trackItems"
          [label]="user.firstName + ' ' + user.lastName"
          [status]="getUserStatus(user)"
          (statusChanged)="onStatusChange($event, user)"
        />
        <ion-note *ngIf="users.length === 0">
          No users with this status
        </ion-note>
      </ng-container>
    </ion-list>
  </app-full-height-container>
</ng-template>
