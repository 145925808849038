<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>{{ announcement.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <app-wysiwyg-editor
      [(value)]="body"
      [disableAttachments]="true"
      [bodyPlaceholder]="bodyPlaceholder"
    />

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        [disabled]="!body"
        [disableHaptics]="!body"
        appHaptics
        (click)="onRespondClick()"
        class="ion-no-margin"
      >
        {{ respondButtonText }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
