// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getAllBackdoorEulas = () => "/backdoor/eula";

export type TSpecHandler_getAllBackdoorEulas = RequestHandler<TSpecPathGetAllBackdoorEulas, any, TSpecBodyGetAllBackdoorEulas, TSpecQueryGetAllBackdoorEulas, {}>;

export interface TSpecPathGetAllBackdoorEulas {  }

export interface TSpecBodyGetAllBackdoorEulas {}

export interface TSpecQueryGetAllBackdoorEulas {
  AppVersion?: string;
  AppUser?: string;
}
