<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Date Range</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-select
          interface="action-sheet"
          placeholder="Click to select range"
          aria-label="Date range"
          [(ngModel)]="selectedPreset"
          (ngModelChange)="onModelSelect($event)"
        >
          <ion-select-option
            *ngFor="let option of rangePresets"
            [value]="option[0]"
          >
            {{ option[1] }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true" [formGroup]="form">
      <app-date-selector
        [isItem]="true"
        [itemLines]="true"
        label="Start Date"
        formControlName="start"
      />
      <app-date-selector
        [isItem]="true"
        label="End Date"
        formControlName="end"
        [endOfDay]="true"
      />
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        [disabled]="form.invalid"
        appHaptics
        [disableHaptics]="form.invalid"
        (click)="submit()"
        class="ion-no-margin"
      >
        Apply
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
