// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_readInstantFeedback = (path: TSpecPathReadInstantFeedback) => {
  let res = "/instant-feedback/:id/read";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_readInstantFeedback = RequestHandler<TSpecPathReadInstantFeedback, any, TSpecBodyReadInstantFeedback, TSpecQueryReadInstantFeedback, {}>;

export interface TSpecPathReadInstantFeedback { id: string; }

export interface TSpecBodyReadInstantFeedback {}

export interface TSpecQueryReadInstantFeedback {
  AppVersion?: string;
  AppUser?: string;
}
