import { Component, Input } from '@angular/core';
import {
  LoadingController,
  ModalController,
  ActionSheetController
} from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { EMAIL_REGEX } from '@app/utils/email';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { ModalClass } from '@app/core/class/modal.class';

export interface ISystemEntity {
  name: string;
  points: number;
}

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html'
})
export class ChangeEmailModalComponent extends ModalClass {
  @Input()
  public readonly oldEmail: string;

  protected form = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX)
    ])
  });

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _actionSheetCtrl: ActionSheetController
  ) {
    super();
  }

  protected get emailError() {
    if (this.oldEmail && this.form.get('email').value === this.oldEmail) {
      return 'This is your email already';
    }

    if (this.form.get('email').dirty && this.form.get('email').errors) {
      if (this.form.get('email').errors.required) {
        return 'Email is required';
      } else if (this.form.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }
    return '';
  }

  protected get canSave() {
    return this.form.value.email && !this.emailError;
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected async onSaveClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const email = this.form.value.email.toLowerCase();

    this._authenticationService.requestEmailChange(email).subscribe({
      next: () => {
        loading.dismiss();
        this._toastService.presentToast(
          'Verification link has been sent to your email. Follow the link to proceed with the email change.',
          { duration: 20000 }
        );
        this.onCloseClick();
      },
      error: async (e) => {
        loading.dismiss();

        if (e.status === 409) {
          const actionSheet = await this._actionSheetCtrl.create({
            header: `This email is already in use. Do you want to link this account to ${email}? Once it is linked it cannot be unlinked anymore.`,
            buttons: [
              {
                text: 'Link Account',
                role: 'confirm'
              },
              {
                text: 'Cancel',
                role: 'cancel'
              }
            ]
          });
          actionSheet.present();

          const { role } = await actionSheet.onWillDismiss();

          if (role === 'confirm') {
            this._authenticationService
              .requestEmailChange(email, true)
              .subscribe({
                next: () => {
                  loading.dismiss();
                  this._toastService.presentToast(
                    'Verification link has been sent to your email. Follow the link to proceed with the email change.',
                    { duration: 20000 }
                  );
                  this.onCloseClick();
                },
                error: (e) => {
                  loading.dismiss();
                  this._toastService.presentToast(
                    e.error?.message || 'Something went wrong'
                  );
                }
              });
          }
        } else {
          this._toastService.presentToast(
            e.error?.message || 'Something went wrong'
          );
        }
      }
    });
  }
}
