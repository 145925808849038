<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="suggestion" class="ion-text-capitalize">
      {{ suggestion.title.toLowerCase() }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin || isMine">
      <ion-button color="primary" (click)="editDoc()" appHaptics>
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ng-container *ngIf="!isLoading && suggestion; else loading">
      <app-content-title>
        {{ suggestion.title.toLowerCase() }}
      </app-content-title>

      <app-wysiwyg-editor [value]="body" mode="view" minHeight="56px" />

      <div class="opportunity-info">
        <div class="opportunity-info__item">
          {{ authorName }}
        </div>
        <div class="opportunity-info__item">
          {{ suggestion.dateCreated | date : 'MMM d, y' }}
        </div>
        <div
          *ngIf="suggestion.isPublic"
          class="opportunity-info__item--primary"
        >
          Published to Team
        </div>
      </div>

      <div *ngIf="solutions.length" class="subtitle">
        <ion-title>Comments</ion-title>
      </div>
      <app-solution-item
        *ngFor="let solution of solutions; trackBy: trackItems; let i = index"
        [solution]="solution"
        [opportunity]="suggestion"
        [showOptions]="isAdmin"
        [animationOrder]="i"
        (solutionDeleted)="solutionDeleted(solution._id)"
      />
    </ng-container>

    <div bottom class="modal-actions">
      <ion-button
        color="primary"
        fill="outline"
        expand="block"
        appHaptics
        (click)="addSolution()"
        [disableHaptics]="isLoading || !suggestion"
        [disabled]="isLoading || !suggestion"
        class="ion-no-margin"
      >
        Add Comment
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ng-template #loading>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-spinner name="lines-sharp-small" />
    </ion-row>
  </ion-grid>
</ng-template>
