import { NgModule } from '@angular/core';
import { ChangeEmailModalComponent } from './change-email-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FullHeightContainerModule,
    ListPostLineModule
  ],
  exports: [ChangeEmailModalComponent],
  declarations: [ChangeEmailModalComponent]
})
export class ChangeEmailModalModule {}
