<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ suggestionCategory ? 'Update Category' : 'Create Category' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name"
          formControlName="name"
          labelPlacement="floating"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
    </ion-list>

    <app-list-pre-line title="New Suggestion" />
    <ion-list [inset]="true">
      <ion-item
        button
        appHaptics
        (click)="onNotifyUsersOnNewOpportunitiesClick()"
      >
        <ion-label>
          {{ form.value.notifyUsersOnNewOpportunities.length }} users selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when new suggestion is posted (Push Notification and Email). This setting overwrites global setting"
    />

    <app-list-pre-line title="New Comment" />
    <ion-list [inset]="true">
      <ion-item button appHaptics (click)="onNotifyUsersOnNewSolutionsClick()">
        <ion-label>
          {{ form.value.notifyUsersOnNewSolutions.length }} users selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when new comment is posted (Push Notification and Email). This setting overwrites global setting"
    />

    <app-list-pre-line title="Suggestion Status" />
    <ion-list [inset]="true">
      <ion-item
        button
        appHaptics
        (click)="onNotifyUsersOnOpportunityStatusChangeClick()"
      >
        <ion-label>
          {{ form.value.notifyUsersOnOpportunityStatusChange.length }}
          users selected
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Notify selected users when suggestion status is changed (Push Notification and Email). This setting overwrites global setting"
    />

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="suggestionCategory"
        color="danger"
        expand="block"
        fill="outline"
        appHaptics
        (click)="delete()"
        class="ion-no-margin"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="form.invalid"
        appHaptics
        [disableHaptics]="form.invalid"
        (click)="save()"
        class="ion-no-margin"
      >
        {{ suggestionCategory ? 'Update' : 'Create' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
