import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { UploadTagComponent } from './upload-tag.component';
import { TagApiService } from '@app/core/service/api/tag.api.service';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { TagsComponent } from './components/tags/tags.components';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { TagItemModule } from '@app/modules/tag-item/tag-item.module';
import { TagViewComponent } from './components/tag-view/tag-view.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ListPreLineModule } from '@app/modules/list-pre-line/list-pre-line.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Upload a Tag',
      rootComponent: UploadTagComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    UserSelectorComponent,
    DirectivesModule,
    EmptyStubModule,
    TagItemModule,
    DigitOnlyModule,
    FullHeightContainerModule,
    ListPreLineModule,
    ListPostLineModule
  ],
  declarations: [UploadTagComponent, TagsComponent, TagViewComponent],
  providers: [TagApiService, UploadFileApiService]
})
export class UploadTagModule {}
