// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_requestUsersToSignLibraryDoc = (path: TSpecPathRequestUsersToSignLibraryDoc) => {
  let res = "/library/docs/:libraryDocId/request-to-sign";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_requestUsersToSignLibraryDoc = RequestHandler<TSpecPathRequestUsersToSignLibraryDoc, any, TSpecBodyRequestUsersToSignLibraryDoc, TSpecQueryRequestUsersToSignLibraryDoc, {}>;

export interface TSpecPathRequestUsersToSignLibraryDoc { libraryDocId: string; }

export interface TSpecBodyRequestUsersToSignLibraryDoc {
  users: string[];
}

export interface TSpecQueryRequestUsersToSignLibraryDoc {
  AppVersion?: string;
  AppUser?: string;
}
