<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">{{ announcement?.title }}</ion-title>
    <ion-buttons slot="end" *ngIf="!isLoading && announcement">
      <app-read-eye
        [button]="true"
        (click)="showAcknowledgments()"
        label="{{ acknowledged.length }}/{{ announcement.recipients.length }}"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-grid *ngIf="isLoading || !announcement">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small" />
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="!isLoading && announcement">
      <app-content-title>{{ announcement.title }}</app-content-title>

      <div class="announcement-view__info">
        <div class="announcement-view__header">
          <h2 class="announcement-view__subheading">
            <span>
              {{ fDateAndTime(announcement.date) }} |
              {{ announcement.authorName }}
            </span>
            <span>{{ editedString }}</span>
          </h2>
          <ion-button
            *ngIf="hasEditRights"
            fill="clear"
            size="small"
            appHaptics
            class="announcement-view__heading-action ion-no-margin"
            (click)="openOptions($event)"
          >
            <ion-icon
              slot="icon-only"
              name="ellipsis-horizontal"
              class="announcement-view__heading-action-icon"
            />
          </ion-button>
        </div>
        <div class="app-divider-line"></div>
      </div>

      <app-wysiwyg-editor [value]="body" mode="view" />

      <app-announcement-thread [announcements]="thread" />
    </ng-container>

    <div
      bottom
      class="modal-actions"
      *ngIf="!alreadyAcknowledged && !isLoading && announcement"
    >
      <ion-button
        *ngIf="announcement.actions !== announcementActions.ONLY_RESPOND"
        color="dark"
        (click)="acknowledge()"
        appHaptics
        expand="block"
        class="ion-no-margin"
      >
        {{
          announcement.acknowledgeButtonText ===
          announcementAcknowledgeButtonText.UNDERSTAND_AND_AGREE
            ? 'Understand and Agree'
            : 'Acknowledge'
        }}
      </ion-button>
      <ion-button
        *ngIf="
          announcement.actions !== announcementActions.ONLY_ACKNOWLEDGE &&
          announcement.author
        "
        color="dark"
        (click)="respond()"
        appHaptics
        expand="block"
        fill="outline"
        class="ion-no-margin"
      >
        {{ respondButtonText }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>

<ion-popover
  [dismissOnSelect]="true"
  [isOpen]="showOptions"
  (didDismiss)="showOptions = false"
  #options
>
  <ng-template>
    <ion-list lines="none">
      <ion-item button (click)="editAnnouncement()" appHaptics>
        <ion-label>Edit</ion-label>
      </ion-item>
      <ion-item button (click)="askDeleteAnnouncement()" appHaptics>
        <ion-label>Delete</ion-label>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
