// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getTenantById = (path: TSpecPathGetTenantById) => {
  let res = "/tenants/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getTenantById = RequestHandler<TSpecPathGetTenantById, any, TSpecBodyGetTenantById, TSpecQueryGetTenantById, {}>;

export interface TSpecPathGetTenantById { id: string; }

export interface TSpecBodyGetTenantById {}

export interface TSpecQueryGetTenantById {
  AppVersion?: string;
  AppUser?: string;
}
