import { NgModule } from '@angular/core';
import { AppMenuCloseDirective } from '@app/directives/menu-close.directive';
import { HapticsDirective } from '@app/directives/haptics.directive';
import { AutofillDirective } from '@app/directives/autofill.directive';
import { SwiperDirective } from '@app/directives/swiper.directive';
import { ClickOutsideDirective } from '@app/directives/click-outside.directive';
import { PhoneMaskDirective } from '@app/directives/phone-mask.directive';

@NgModule({
  declarations: [
    AppMenuCloseDirective,
    HapticsDirective,
    AutofillDirective,
    SwiperDirective,
    ClickOutsideDirective,
    PhoneMaskDirective
  ],
  exports: [
    AppMenuCloseDirective,
    HapticsDirective,
    AutofillDirective,
    SwiperDirective,
    ClickOutsideDirective,
    PhoneMaskDirective
  ]
})
export class DirectivesModule {}
