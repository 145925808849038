<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title> Reset Password </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light" [fullscreen]="true">
  <app-full-height-container maxWidth="modal">
    <app-content-title
      subtitle="Enter and verify your new password"
      [showLogo]="true"
      align="center"
    />

    <ion-list [inset]="true" [formGroup]="mainFormGroup">
      <ion-item class="hidden-control">
        <ion-input
          type="email"
          [value]="email"
          placeholder="Email"
          email
          [readonly]="true"
          [disabled]="true"
        />
      </ion-item>
      <ion-item>
        <ion-input
          type="password"
          formControlName="password"
          placeholder="New password"
          name="password"
        />
      </ion-item>
      <ion-item>
        <ion-input
          type="password"
          formControlName="confirmPassword"
          placeholder="Re-enter new password"
          name="confirmPassword"
        />
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="formError" [text]="formError" color="danger" />

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        class="ion-no-margin"
        (click)="submit()"
      >
        Confirm
      </ion-button>
    </div>
    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        fill="clear"
        size="small"
        appHaptics
        (click)="goToLogin()"
        expand="block"
        class="ion-no-margin"
      >
        Cancel and return to login
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
