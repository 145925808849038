import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChecklistItem } from '@app/types/checklist-item';
import {
  TaskPerformanceEvaluationType,
  TaskPointsEarns,
  TaskScheduleType,
  TaskTimeAttackType
} from '@backend/models/types/task';
import {
  IonNav,
  IonPopover,
  LoadingController,
  ViewWillEnter,
  ViewDidEnter,
  ModalController,
  IonModal,
  AlertController
} from '@ionic/angular';
import {
  ITaskPerformance,
  TaskPerformanceStatus
} from '@backend/models/types/task-performance';
import { TaskPerformanceService } from '@app/core/service/task-performance.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskCreateComponent } from '@app/dashboard/dashboard/task-management/components/task-create/task-create.component';
import { PromptUserToLeaveNoteDto, TaskDto } from '@app/types/task-dto';
import {
  endOfDay,
  format,
  formatDuration,
  intervalToDuration,
  isBefore,
  isSameDay,
  parseISO
} from 'date-fns';
import { TaskListService } from '@app/core/service/task-list.service';
import { ChecklistComponent } from '@app/dashboard/dashboard/checklist/checklist.component';
import { ToastService } from '@app/core/service/toast.service';
import { TaskHistoryModalComponent } from '@app/modals/task-history-modal/task-history-modal.component';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { TenantService } from '@app/core/service/tenant.service';
import { TimeAttackModalComponent } from '@app/modules/time-attack-modal/time-attack-modal.component';
import { PerformanceWithUser } from '@app/types/performance-with-user';
import { ApprovalsListService } from '@app/core/service/approvals-list.service';
import { TasksApiService } from '@app/core/service/api/tasks.api.service';
import { UserModel } from '@app/core/model/user.model';
import { Observable, combineLatest } from 'rxjs';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { IAttachment } from '@backend/models/types/attachment';
import { TaskForAuditModel } from '@app/core/model/task-for-audit.model';
import { CheckTaskCriteriaModalComponent } from '@app/modules/check-task-criteria-modal/check-task-criteria-modal.component';
import { ChecklistDefinition } from '@backend/types/checklist-definition';
import { TaskAuditApiService } from '@app/core/service/api/taskAudit.api.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { UserRoles } from '@backend/models/types/user';
import { showModal, showWysiwygModal } from '@app/utils/modal';

@Component({
  selector: 'app-checklist-item-pickup',
  templateUrl: './checklist-item-pickup.component.html',
  styleUrls: ['./checklist-item-pickup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistItemPickupComponent
  implements ViewWillEnter, ViewDidEnter, OnInit
{
  @Input()
  public item: ChecklistItem;

  @Input()
  public readonly addToChecklistMode?: boolean = false;

  @Input()
  public readonly isLastCriticalTask?: boolean = false;

  @ViewChild('popover', { static: true })
  protected popover!: IonPopover;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected readonly taskPointsEarns = TaskPointsEarns;
  protected readonly taskScheduleType = TaskScheduleType;
  protected readonly taskTimeAttackType = TaskTimeAttackType;
  protected readonly taskPerformanceStatus = TaskPerformanceStatus;
  protected readonly taskPerformanceEvaluationType =
    TaskPerformanceEvaluationType;

  protected selectedUnits = '1';
  protected isTaskLoading = true;
  protected isLastPerformanceLoading = true;
  protected isAdmin: boolean;
  protected isAdminOrManager: boolean;
  protected showAddToChecklistButton = false;
  protected addToChecklistDate = new Date();
  protected addToChecklistDateText = 'Today';
  protected isOpen = false;
  protected body = '';
  protected tasksForAudit: TaskForAuditModel[] = [];
  protected serverUrl = '';
  protected tenantFeatures: Pick<
    ITenantFeatures,
    'timeAttack' | 'requestTaskFeedbackAttachments' | 'leaveTaskNoteAttachments'
  > = {
    timeAttack: false,
    requestTaskFeedbackAttachments: false,
    leaveTaskNoteAttachments: false
  };
  protected checklistSettings: Pick<
    ChecklistDefinition,
    'showLastCompleteInTasksForAudit'
  > = {
    showLastCompleteInTasksForAudit: false
  };
  protected myInProgressPerformance: PerformanceWithUser = null;
  protected myInProgressTime = '';
  protected myInProgressIntervalObj: any = null;
  protected timeAttackStats: {
    averageDuration: Duration;
    currentRecord: {
      user: UserModel;
      duration: Duration;
    };
    rewardForBeatingTheAverage: number;
    rewardForAttempt: number;
  } = null;
  protected lastPerformance:
    | MongoStoredObject<ITaskPerformance> & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      } = null;
  protected lastPerformanceWithNote:
    | MongoStoredObject<ITaskPerformance> & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      } = null;
  protected allUsers: UserModel[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _taskPerformanceService: TaskPerformanceService,
    private readonly _userEntityService: UserEntityService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _authenticationService: AuthenticationService,
    private readonly _nav: IonNav,
    private readonly _taskListService: TaskListService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _modalCtrl: ModalController,
    private readonly _environmentService: EnvironmentService,
    private readonly _tenantService: TenantService,
    private readonly _approvalsListService: ApprovalsListService,
    private readonly _tasksApiService: TasksApiService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _taskAuditApiService: TaskAuditApiService,
    private readonly _userListService: UserListService,
    private readonly _alertCtrl: AlertController
  ) {
    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });

    this.serverUrl = this._environmentService.serverUrl;

    this.isAdmin =
      this._authenticationService.user?.roles.includes(UserRoles.Admin) ??
      false;
    this.isAdminOrManager =
      (this.isAdmin ||
        this._authenticationService.user?.roles.includes(UserRoles.Manager)) ??
      false;

    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
      this.checklistSettings = tenant.settings.checklist;
    });
  }

  public ngOnInit(): void {
    this._tasksApiService
      .getLastTaskPerformance({ path: { id: this.item.task._id } })
      .subscribe(
        async ({ lastPerformance, lastPerformanceWithNote, tasksForAudit }) => {
          this.lastPerformance = lastPerformance;
          this.lastPerformanceWithNote = lastPerformanceWithNote;
          this.tasksForAudit = tasksForAudit.map((t) => ({
            _id: t.task._id,
            title: t.task.title,
            criteria: t.task.auditSuccessCriteria.map((c) => ({
              title: c,
              value: 'NOT_RESPONDED',
              comment: ''
            })),
            lastPerformance: {
              _id: t.lastPerformance._id,
              completeTime: t.lastPerformance.completeTime,
              performer: {
                _id: t.lastPerformance.user._id,
                firstName: t.lastPerformance.user.firstName,
                lastName: t.lastPerformance.user.lastName
              }
            }
          }));
          this.isLastPerformanceLoading = false;
          this._cdr.markForCheck();

          const action = this.item.task.actionsUponTaskCompletion.find(
            (action) => action.actionType === 'PROMPT_USER_TO_LEAVE_NOTE'
          ) as PromptUserToLeaveNoteDto;

          const isUserInList =
            action &&
            (action.visibleToEveryone ||
              action.visibleToUsers.find(
                (u) =>
                  u.toString() ===
                  this._authenticationService.user._id.toString()
              ));

          const isUserAcknowledged =
            action && lastPerformanceWithNote
              ? action.showMoreRecentNote === 'ONLY_ONCE_TO_EACH_USER'
                ? lastPerformanceWithNote.note.acknowledgedBy.find(
                    (u) =>
                      u.toString() ===
                      this._authenticationService.user._id.toString()
                  )
                : action.showMoreRecentNote ===
                  'UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE'
                ? lastPerformanceWithNote.note.acknowledgedForAll
                : false
              : false;

          if (
            action &&
            lastPerformanceWithNote &&
            isUserInList &&
            !isUserAcknowledged
          ) {
            await showWysiwygModal(
              {
                title: 'Task Note',
                saveBtnText: 'OK',
                dismissable: false,
                body: formatUploadedBody(
                  lastPerformanceWithNote.note.body,
                  lastPerformanceWithNote.note.attachments,
                  this.serverUrl
                ),
                mode: 'view',
                info: {
                  start: `${lastPerformanceWithNote.user.firstName} ${lastPerformanceWithNote.user.lastName}`,
                  end: format(
                    new Date(lastPerformanceWithNote.completeTime),
                    'MMM dd, yyyy h:mm a'
                  )
                }
              },
              this._modalCtrl
            );

            if (action.showMoreRecentNote === 'ONLY_ONCE_TO_EACH_USER') {
              this._taskPerformanceService
                .acknowledgeTaskPerformanceNote(lastPerformanceWithNote._id)
                .subscribe();
            }
          }
        }
      );
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  public ionViewWillEnter() {
    this._loadData();
  }

  protected _loadData() {
    this.isTaskLoading = true;

    if (this.myInProgressIntervalObj) {
      clearInterval(this.myInProgressIntervalObj);
      this.myInProgressIntervalObj = null;
    }

    combineLatest([
      this._tasksApiService.getTimeAttackStats({
        path: { id: this.item.task._id }
      }),
      this._taskPerformanceService.getPerformanceForInterval(
        this.item.interval
      ),
      this._taskListService.getTaskById(this.item.task._id)
    ]).subscribe(async ([timeAttackStats, performances, task]) => {
      // timeAttackStats
      this.timeAttackStats = timeAttackStats;

      // performances
      this.myInProgressPerformance = performances.find(
        (performance) =>
          performance.task.toString() === this.item.task._id.toString() &&
          (performance.status === TaskPerformanceStatus.IN_PROGRESS ||
            performance.status === TaskPerformanceStatus.WAITING_FOR_APPROVAL ||
            performance.status === TaskPerformanceStatus.NOT_STARTED) &&
          performance.user._id === this._authenticationService.user._id
      );
      if (
        this.myInProgressPerformance &&
        this.myInProgressPerformance.status ===
          TaskPerformanceStatus.IN_PROGRESS &&
        !this.myInProgressIntervalObj
      ) {
        this._updateInProgressTime();
        this.myInProgressIntervalObj = setInterval(() => {
          this._updateInProgressTime();
        }, 1000);
      }

      // task
      this.item.task = task;
      this.body = formatUploadedBody(
        task.details.description,
        task.details.attachments,
        this._environmentService.serverUrl
      );
      this.showAddToChecklistButton = this.addToChecklistMode;
      this._setAddToChecklistDate(
        this.item.task.extraAppearDate
          ? new Date(this.item.task.extraAppearDate)
          : new Date()
      );
      if (
        this.item.task.extraAppearDate &&
        isBefore(endOfDay(new Date(this.item.task.extraAppearDate)), new Date())
      ) {
        this._clearExtraAppearDate();
      }

      // finalize
      this.isTaskLoading = false;
      this._cdr.markForCheck();
    });
  }

  private _updateInProgressTime() {
    if (
      this.myInProgressPerformance &&
      this.myInProgressPerformance.status === TaskPerformanceStatus.IN_PROGRESS
    ) {
      this.myInProgressTime = formatDuration(
        intervalToDuration({
          start: this.myInProgressPerformance.startTime,
          end: new Date()
        }),
        {
          // delimiter: ':'
        }
      ).replace(/(\d)/g, '<span class="number">$1</span>');
      // .replace(/[^0-9:]/g, '');
      this._cdr.markForCheck();
    }
  }

  protected get averageTaskTime() {
    return this.timeAttackStats
      ? formatDuration(this.timeAttackStats.averageDuration) || 'No Data'
      : 'No Data';
  }

  protected get minAddToChecklistDate() {
    return new Date().toISOString();
  }

  protected get perUnitQuestion() {
    if (
      this.item.task.pointsUnit &&
      this.item.task.verbBase &&
      (this.item.task as any).nounPlural
    ) {
      return `How many ${(this.item.task as any).nounPlural} did you ${
        this.item.task.verbBase
      }?`;
    } else if (this.item.task.pointsUnit) {
      return this.item.task.pointsUnit;
    }
  }

  protected onUnitsMinusClick(): void {
    this.selectedUnits = (Number(this.selectedUnits) - 1).toString();
  }

  protected onUnitsPlusClick(): void {
    if (this.selectedUnits) {
      this.selectedUnits = (Number(this.selectedUnits) + 1).toString();
    } else {
      this.selectedUnits = '2';
    }
  }

  protected async onCompleteClick() {
    this.isTaskLoading = true;

    const isRequestFeedbackSuccess = await this.requestProcessFeedback();

    if (isRequestFeedbackSuccess) {
      const isRequestNoteSuccess = await this.requestNote();

      if (isRequestNoteSuccess) {
        const isAcknowledgeSuccess = await this.acknowledgeNoteForAll();

        if (isAcknowledgeSuccess) {
          if (typeof isRequestNoteSuccess !== 'boolean') {
            this.completeTask({ note: isRequestNoteSuccess });
          } else {
            this.completeTask({});
          }
        }
      }
    }
  }

  protected async onCompleteBySomeoneClick() {
    this.userSelectorModal.present();
  }

  protected async onCompleteBySomeone(event: ObjectId[]) {
    if (event.length) {
      this.userSelectorModal.dismiss();

      const user = this.allUsers.find(({ _id }) => _id === event[0]);

      if (user) {
        const alert = await this._alertCtrl.create({
          cssClass: 'wide-alert',
          header: `Mark this task as completed by ${user.firstName} ${user.lastName}?`,
          buttons: [
            {
              role: 'confirm',
              text: 'Complete'
            },
            {
              role: 'destructive',
              text: 'Cancel'
            }
          ]
        });
        alert.present();

        const { role } = await alert.onWillDismiss();

        if (role === 'confirm') {
          this.completeTask({ user: user._id });
        }
      }
    }
  }

  protected requestProcessFeedback() {
    return new Promise<boolean>((resolve) => {
      this._tasksApiService
        .getTaskFeedbackStats({ path: { id: this.item.task._id } })
        .subscribe({
          next: async (taskFeedbackStats) => {
            if (taskFeedbackStats.isFeedbackNeeded) {
              const { success, body } = await showWysiwygModal(
                {
                  disableAttachments:
                    !this.tenantFeatures.requestTaskFeedbackAttachments,
                  title: 'Process Feedback',
                  bodyPlaceholder:
                    'How can we make this process more effective, efficient, or enjoyable?',
                  saveBtnText: 'Submit',
                  cancelBtnText: 'Dismiss',
                  dismissable: !taskFeedbackStats.mandatory,
                  toolbarMode: 'image-only'
                },
                this._modalCtrl
              );

              if (success) {
                this._uploadFileApiService
                  .extractAttachmentsFromBody(body, [])
                  .subscribe({
                    next: ({ body, newAttachments, keptAttachments }) => {
                      this._tasksApiService
                        .postTaskFeedback({
                          path: { id: this.item.task._id },
                          body: {
                            body,
                            attachments: [
                              ...keptAttachments,
                              ...newAttachments.map((a) => a._id.toString())
                            ]
                          }
                        })
                        .subscribe(() => {
                          resolve(true);
                        });
                    },
                    error: (e) => {
                      console.log(e);
                      this._toastService.presentToast(
                        'Unable to upload attachments'
                      );
                      resolve(false);
                    }
                  });
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }
          },
          error: (e) => {
            console.log(e);
            this._toastService.presentToast(
              'Unable to fetch task feedback data'
            );
            resolve(false);
          }
        });
    });
  }

  protected acknowledgeNoteForAll() {
    return new Promise<boolean>((resolve) => {
      const action = this.item.task.actionsUponTaskCompletion.find(
        (action) => action.actionType === 'PROMPT_USER_TO_LEAVE_NOTE'
      ) as PromptUserToLeaveNoteDto;

      if (
        action &&
        action.showMoreRecentNote === 'UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE'
      ) {
        this._taskPerformanceService
          .acknowledgeTaskPerformanceNote(
            this.lastPerformanceWithNote._id,
            true
          )
          .subscribe(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  protected requestNote() {
    return new Promise<boolean | { attachments: string[]; body: string }>(
      (resolve) => {
        (async () => {
          const action = this.item.task.actionsUponTaskCompletion.find(
            (action) => action.actionType === 'PROMPT_USER_TO_LEAVE_NOTE'
          ) as PromptUserToLeaveNoteDto;

          const isNoteNeeded =
            action &&
            (action.visibleToEveryone ||
              !!action.visibleToUsers.find(
                (u) =>
                  u.toString() ===
                  this._authenticationService.user._id.toString()
              ));

          if (isNoteNeeded) {
            let str = 'Only once to each user';
            switch (action.showMoreRecentNote) {
              case 'UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE':
                str = 'Until this task is completed again by anyone';
                break;
              case 'EVERY_TIME_TASK_IS_OPENED':
                str = 'Every time this task is opened';
                break;
            }

            const { success, body } = await showWysiwygModal(
              {
                disableAttachments:
                  !this.tenantFeatures.leaveTaskNoteAttachments,
                title: 'Leave a Task Note',
                bodyPlaceholder: `Your note will be shown ${str}`,
                saveBtnText: 'Submit',
                cancelBtnText: 'Dismiss',
                dismissable: !action.mandatory
              },
              this._modalCtrl
            );

            if (success) {
              this._uploadFileApiService
                .extractAttachmentsFromBody(body, [])
                .subscribe({
                  next: ({ body, newAttachments }) => {
                    resolve({
                      body,
                      attachments: newAttachments.map((a) => a._id.toString())
                    });
                  },
                  error: (e) => {
                    console.log(e);
                    this._toastService.presentToast(
                      'Unable to upload attachments'
                    );
                    resolve(false);
                  }
                });
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        })();
      }
    );
  }

  protected completeTask({
    note,
    user
  }: {
    note?: { attachments: string[]; body: string };
    user?: ObjectId;
  }) {
    this.isTaskLoading = true;

    const quantity =
      this.item.task.pointsEarns === TaskPointsEarns.PER_UNIT
        ? this.selectedUnits
          ? Number(this.selectedUnits)
          : 1
        : null;

    this._taskPerformanceService
      .setTaskPerformance(
        this.item.task._id,
        {
          status: TaskPerformanceStatus.COMPLETE,
          quantity,
          taskAppearTime: this.item.interval.start.toISOString(),
          note
        },
        user,
        this.isLastCriticalTask
      )
      .subscribe({
        next: () => {
          if (this.item.task.isAuditTask && this.tasksForAudit.length) {
            const observables: Observable<{
              newAttachments: MongoStoredObject<IAttachment>[];
              body: string;
              keptAttachments: string[];
            }>[] = [];

            this.tasksForAudit.map((t) => {
              t.criteria.map((c) => {
                observables.push(
                  this._uploadFileApiService.extractAttachmentsFromBody(
                    c.comment || '',
                    []
                  )
                );
              });
            });

            combineLatest(observables).subscribe((results) => {
              const tasksForAudit = this.tasksForAudit.map((t, taskIndex) => {
                return {
                  ...t,
                  criteria: t.criteria.map((c, criterionIndex) => {
                    const index = taskIndex + criterionIndex;

                    return {
                      ...c,
                      comment: results[index].body,
                      attachments: results[index].newAttachments.map((a) =>
                        a._id.toString()
                      )
                    };
                  })
                };
              });

              this._taskAuditApiService
                .submitTaskAudit({
                  body: {
                    auditTask: this.item.task._id,
                    tasksForAudit: tasksForAudit.map((t) => ({
                      title: t.title,
                      lastPerformance: t.lastPerformance
                        ? {
                            taskPerformance: t.lastPerformance._id,
                            performer:
                              t.lastPerformance.performer._id.toString()
                          }
                        : undefined,
                      criteria: t.criteria.map((c) => ({
                        title: c.title,
                        value: c.value === 'TRUE',
                        comment: c.comment,
                        attachments: c.attachments
                      }))
                    }))
                  }
                })
                .subscribe(() => {});
            });
          }

          this._userEntityService.update();
          this._toastService.presentToast('Task Completed!', {
            okText: `+${this.item.task.points * (quantity || 1)} points`
          });
          this._ionNav.pop();
        },
        error: () => {
          this._toastService.presentToast('Unable to Complete The Task');
          this.isTaskLoading = false;
          this._cdr.markForCheck();
        }
      });
  }

  protected async onTimeAttackClick() {
    const timeAttackModal = await this._modalCtrl.create({
      id: 'time-attack-modal',
      component: TimeAttackModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        checklistItem: this.item,
        timeAttackStats: this.timeAttackStats
      },
      handle: false
    });
    timeAttackModal.present();

    const { role } = await timeAttackModal.onWillDismiss();

    if (role === 'confirm') {
      this._loadData();
    }
  }

  protected onEditTaskClick(task: MongoStoredObject<TaskDto>): void {
    this._nav.push(TaskCreateComponent, { task });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onDateButtonClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected onCalendarChange(event: any) {
    if (event.detail.value && typeof event.detail.value === 'string') {
      const date = parseISO(event.detail.value);
      this._setAddToChecklistDate(date);
      this.isOpen = false;
    }
  }

  private async _clearExtraAppearDate() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._taskListService
      .patchTask(this.item.task._id, {
        ...this.item.task,
        details: {
          ...this.item.task.details,
          attachments: this.item.task.details.attachments.map((a) =>
            a._id.toString()
          )
        },
        extraAppearDate: null
      })
      .subscribe({
        next: (task) => {
          this.item.task = task;
          loading.dismiss();
          this._cdr.detectChanges();
        }
      });
  }

  protected addToChecklist() {
    this._taskListService
      .patchTask(this.item.task._id, {
        ...this.item.task,
        details: {
          ...this.item.task.details,
          attachments: this.item.task.details.attachments.map((a) =>
            a._id.toString()
          )
        },
        extraAppearDate: this.addToChecklistDate.toISOString()
      })
      .subscribe({
        next: (task) => {
          this.item.task = task;
          this._toastService.presentToast('Added to Checklist Successfully!');
          this._ionNav.push(ChecklistComponent);
        },
        error: () => {
          this._cdr.markForCheck();
        }
      });
  }

  protected removeFromChecklist() {
    this._taskListService
      .patchTask(this.item.task._id, {
        ...this.item.task,
        details: {
          ...this.item.task.details,
          attachments: this.item.task.details.attachments.map((a) =>
            a._id.toString()
          )
        },
        extraAppearDate: null
      })
      .subscribe({
        next: (task) => {
          this.item.task = task;
          this._toastService.presentToast(
            'Removed from Checklist Successfully!'
          );
          this._ionNav.push(ChecklistComponent);
        },
        error: () => {
          this._cdr.markForCheck();
        }
      });
  }

  private _setAddToChecklistDate = (date: Date) => {
    this.addToChecklistDate = date;
    this.addToChecklistDateText = isSameDay(date, new Date())
      ? 'Today'
      : format(date, 'MMM d');
  };

  protected async showTaskHistory() {
    showModal(
      {
        component: TaskHistoryModalComponent,
        props: { taskId: this.item.task._id }
      },
      this._modalCtrl
    );
  }

  protected async onCancelTaskPerformanceClick() {
    this.isTaskLoading = true;

    this._taskPerformanceService
      .cancelTaskPerformance(this.myInProgressPerformance._id)
      .subscribe({
        next: () => {
          this._toastService.presentToast('Time Attack Canceled');
          this._loadData();
        },
        error: () => {
          this._toastService.presentToast('Unable to Cancel the Task');
          this.isTaskLoading = false;
          this._cdr.markForCheck();
        }
      });
  }

  protected async onStopTaskPerformanceClick() {
    this.isTaskLoading = true;

    const quantity =
      this.item.task.pointsEarns === TaskPointsEarns.PER_UNIT
        ? this.selectedUnits
          ? Number(this.selectedUnits)
          : 1
        : null;

    this._taskPerformanceService
      .stopTaskPerformance(
        this.myInProgressPerformance._id,
        this.item.interval.start.toISOString(),
        quantity
      )
      .subscribe({
        next: () => {
          this._approvalsListService.update();
          this._toastService.presentToast('Sent for Approval!');
          this._ionNav.pop();
        },
        error: () => {
          this._toastService.presentToast('Unable to Finish the Task');
          this.isTaskLoading = false;
          this._cdr.markForCheck();
        }
      });
  }

  protected get cantMinus() {
    return Number(this.selectedUnits) <= 1;
  }

  protected get cantPlus() {
    return Number(this.selectedUnits) >= 999999;
  }

  protected async onTaskForAuditClick(taskForAudit: TaskForAuditModel) {
    const taskHistoryModal = await this._modalCtrl.create({
      id: 'check-task-criteria-modal',
      component: CheckTaskCriteriaModalComponent,
      componentProps: {
        taskForAudit: JSON.parse(JSON.stringify(taskForAudit)),
        showLastComplete: this.checklistSettings.showLastCompleteInTasksForAudit
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    taskHistoryModal.present();

    const { data, role } = await taskHistoryModal.onWillDismiss();

    if (role === 'confirm') {
      this.tasksForAudit = this.tasksForAudit.map((t) =>
        t._id === data._id ? data : t
      );
      this._cdr.markForCheck();
    }
  }

  protected isTaskForAuditReviewed(taskForAudit: TaskForAuditModel) {
    return (
      taskForAudit.criteria
        .map((c) => c.value === 'TRUE' || (c.value === 'FALSE' && c.comment))
        .filter(Boolean).length === taskForAudit.criteria.length
    );
  }

  protected get isAuditDone() {
    return (
      !this.item.task.isAuditTask ||
      this.tasksForAudit.reduce(
        (prev, cur) => (!prev ? prev : this.isTaskForAuditReviewed(cur)),
        true
      )
    );
  }
}
