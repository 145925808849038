// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getInvitationStatus = () => "/users/invitation-status";

export type TSpecHandler_getInvitationStatus = RequestHandler<TSpecPathGetInvitationStatus, any, TSpecBodyGetInvitationStatus, TSpecQueryGetInvitationStatus, {}>;

export interface TSpecPathGetInvitationStatus {  }

export interface TSpecBodyGetInvitationStatus {}

export interface TSpecQueryGetInvitationStatus {
  signupToken: string;
  AppVersion?: string;
  AppUser?: string;
}
