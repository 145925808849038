<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ backdoorInfo ? 'Update' : 'Add' }} Backdoor Message
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [formGroup]="mainFormGroup">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Title"
          formControlName="title"
          placeholder="New Update Available"
          autocapitalize="on"
          [spellcheck]="true"
          labelPlacement="floating"
        />
      </ion-item>
      <ion-item>
        <ion-input
          label="Message"
          formControlName="message"
          placeholder="Please update app to the latest version"
          autocapitalize="on"
          [spellcheck]="true"
          labelPlacement="floating"
        />
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Version Range"
          formControlName="versionRange"
          placeholder=">=1.2.7 <1.3.0"
          labelPlacement="floating"
        />
        <ion-button
          fill="clear"
          (click)="openVersionsHelp()"
          class="ion-no-margin"
        >
          <ion-icon slot="icon-only" name="information-circle" />
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-toggle formControlName="canDismiss"> User Can Dismiss </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-toggle formControlName="showToAllTenants">
          Show to All Tenants
        </ion-toggle>
      </ion-item>
      <ion-item *ngIf="!showToAllTenants">
        <ion-select
          label="Tenants"
          [multiple]="true"
          formControlName="tenantsToShow"
          appHaptics
          labelPlacement="floating"
        >
          <ion-select-option
            *ngFor="let tenant of tenantList$ | async as tenantList"
            [value]="tenant._id"
          >
            {{ tenant.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-select
          label="Platforms"
          [multiple]="true"
          formControlName="platformsToShow"
          appHaptics
          labelPlacement="floating"
        >
          <ion-select-option
            *ngFor="let platform of platforms"
            [value]="platform"
          >
            {{ platform }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <app-list-pre-line
      title="Links"
      [showAddButton]="true"
      (addClicked)="addLink()"
    />
    <ion-list [inset]="true">
      <ion-item *ngFor="let link of links; let i = index">
        <ion-label>{{ link.text }}</ion-label>
        <ion-button
          color="danger"
          fill="clear"
          (click)="removeLink(i)"
          appHaptics
        >
          <ion-icon slot="icon-only" name="close-circle" />
        </ion-button>
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        fill="outline"
        expand="block"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        (click)="preview()"
        class="ion-no-margin"
      >
        Preview
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="mainFormGroup.invalid"
        appHaptics
        [disableHaptics]="mainFormGroup.invalid"
        (click)="onFormSubmit()"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
