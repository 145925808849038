// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_readOpportunity = (path: TSpecPathReadOpportunity) => {
  let res = "/suggestions/opportunities/:opportunityId/read";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_readOpportunity = RequestHandler<TSpecPathReadOpportunity, any, TSpecBodyReadOpportunity, TSpecQueryReadOpportunity, {}>;

export interface TSpecPathReadOpportunity { opportunityId: string; }

export interface TSpecBodyReadOpportunity {}

export interface TSpecQueryReadOpportunity {
  AppVersion?: string;
  AppUser?: string;
}
