<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Daily Activity Report </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle
          appHaptics
          [(ngModel)]="dailyReportSettings.dailyReportEnabled"
          (ionChange)="onDailyReportEnabledChange($event)"
        >
          <ion-label> Send Daily Report </ion-label>
        </ion-toggle>
      </ion-item>
      <app-time-form-control
        [isItem]="true"
        [itemLines]="true"
        [(ngModel)]="dailyReportSettings.dailyReportTime"
        label="Send report each day at"
        (ngModelChange)="onDailyReportTimeChange($event)"
      />
      <ion-item button appHaptics (click)="onSelectDailyReportUsersClick()">
        <ion-label>
          <ng-container *ngIf="allUsers | appReceiveDailyReports as allowList">
            <ng-container
              *ngIf="
                allowList.length === allUsers.length;
                else someUsersCaption
              "
            >
              All Users
            </ng-container>
            <ng-template #someUsersCaption>
              {{ allowList.length }} users selected
            </ng-template>
          </ng-container>
        </ion-label>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Push Notification will be sent to selected users"
    />
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
