<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title class="ion-text-capitalize"> Change Email </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true" [formGroup]="form">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="New Email"
          labelPlacement="floating"
          formControlName="email"
          type="email"
          email
        />
      </ion-item>
    </ion-list>
    <app-list-post-line *ngIf="emailError" [text]="emailError" color="danger" />

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        (click)="onSaveClick()"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
