import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalClass } from '@app/core/class/modal.class';
import { ITimezone } from '@backend/types/timezone';
import { FilterTimezonesPipe } from './filter-timezones.pipe';

@Component({
  selector: 'app-timezone-selector-modal',
  templateUrl: './timezone-selector-modal.component.html',
  styleUrls: ['./timezone-selector-modal.component.scss']
})
export class TimezoneSelectorModalComponent extends ModalClass {
  @Input()
  public readonly timezones: ITimezone[];

  @Input()
  public readonly initialTzCode: string;

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  protected cdkItemSize = 69;
  public filter = '';

  protected trackItems(_: number, item: ITimezone) {
    return item.tzCode;
  }

  protected onCloseClick() {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected selectTimezone(value: ITimezone) {
    this._modalCtrl.dismiss(
      this.timezones.find((t) => t.tzCode === value.tzCode),
      'confirm',
      this._modalName
    );
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected getFirst() {
    if (!this.filter) return null;

    const timezones = new FilterTimezonesPipe().transform(
      this.timezones,
      this.filter
    );

    if (timezones.length > 0) {
      return timezones[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      this.selectTimezone(first);
    }
  }
}
