<ion-header class="ion-no-border" />

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true">
    <ion-card-header>
      <ion-card-title>
        {{ backdoorInfo.title }}
      </ion-card-title>
    </ion-card-header>

    <ion-card-content>
      {{ backdoorInfo.message }}
    </ion-card-content>

    <div bottom class="modal-actions">
      <ion-button
        *ngFor="let link of backdoorInfo.links"
        color="primary"
        (click)="goToUrl(link)"
        appHaptics
        expand="block"
        class="ion-no-margin"
      >
        {{ link.text }}
      </ion-button>
      <ion-button
        expand="block"
        *ngIf="backdoorInfo.canDismiss || preview"
        color="dark"
        fill="outline"
        (click)="dismiss()"
        appHaptics
        class="ion-no-margin"
      >
        {{
          preview && !backdoorInfo.canDismiss
            ? 'Close (Only in Preview)'
            : 'Close'
        }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
