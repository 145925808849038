import { Directive, HostListener, ElementRef } from '@angular/core';
import { formatPhoneNumber } from '@app/utils/phone-number';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {
  constructor(private _elementRef: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput() {
    const inputElement = this._elementRef.nativeElement as HTMLInputElement;

    // Strip non-numeric characters and limit to 10 digits
    const rawValue = inputElement.value.replace(/\D/g, '').substring(0, 10);

    // Format the value and set it back to the input
    inputElement.value = formatPhoneNumber(rawValue);
  }
}
