<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ compensationPool ? 'Update Pool' : 'Create Pool' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name"
          formControlName="name"
          labelPlacement="floating"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
      <ion-item>
        <ion-select
          formControlName="type"
          labelPlacement="floating"
          aria-label="Pool Type"
          interface="popover"
          label="Pool Type"
          appHaptics
        >
          <ion-select-option [value]="compensationPoolType.CURRENCY">
            Currency
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.HOURS">
            Hours
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.OTHER">
            Other
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item button appHaptics (click)="selectUsers()">
        {{ formattedRecipients }}
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="compensationPool"
        color="danger"
        expand="block"
        fill="outline"
        appHaptics
        (click)="delete()"
        class="ion-no-margin"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
        (click)="save()"
        class="ion-no-margin"
      >
        {{ compensationPool ? 'Update' : 'Create' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
