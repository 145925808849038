// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getBackdoorState = () => "/backdoor/current-info";

export type TSpecHandler_getBackdoorState = RequestHandler<TSpecPathGetBackdoorState, any, TSpecBodyGetBackdoorState, TSpecQueryGetBackdoorState, {}>;

export interface TSpecPathGetBackdoorState {  }

export interface TSpecBodyGetBackdoorState {}

export interface TSpecQueryGetBackdoorState {
  version: string;
  platform?: string;
  manufacturer?: string;
  model?: string;
  osVersion?: string;
  operatingSystem?: string;
  AppVersion?: string;
  AppUser?: string;
}
