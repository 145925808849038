<ion-icon
  *ngIf="showLogo"
  src="../../../assets/images/logo.svg"
  class="content-logo ion-padding"
/>

<ion-header
  collapse="condense"
  class="content-title content-title--{{ titleSize }} content-title--align-{{
    align
  }}"
>
  <ion-toolbar class="app-toolbar">
    <ion-title #title class="ion-text-capitalize" size="large">
      <ng-content />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-header> </ion-header>

<div
  *ngIf="subtitle"
  class="content-subtitle content-subtitle--align-{{ align }}"
>
  <ion-text>{{ subtitle }}</ion-text>
</div>
