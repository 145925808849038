// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getCriticalUnacknowledgedAnnouncements = () => "/announcements/critical-unacknowledged";

export type TSpecHandler_getCriticalUnacknowledgedAnnouncements = RequestHandler<TSpecPathGetCriticalUnacknowledgedAnnouncements, any, TSpecBodyGetCriticalUnacknowledgedAnnouncements, TSpecQueryGetCriticalUnacknowledgedAnnouncements, {}>;

export interface TSpecPathGetCriticalUnacknowledgedAnnouncements {  }

export interface TSpecBodyGetCriticalUnacknowledgedAnnouncements {}

export interface TSpecQueryGetCriticalUnacknowledgedAnnouncements {
  AppVersion?: string;
  AppUser?: string;
}
