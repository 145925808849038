// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_patchTenant = () => "/tenants";

export type TSpecHandler_patchTenant = RequestHandler<TSpecPathPatchTenant, any, TSpecBodyPatchTenant, TSpecQueryPatchTenant, {}>;

export interface TSpecPathPatchTenant {  }

export interface TSpecBodyPatchTenant {
  name?: string;
  settings?: {
    payroll?:
      | {
          frequency: PayFrequency.EVERY_WEEK;
          date1: string;
        }
      | {
          frequency: PayFrequency.EVERY_TWO_WEEKS;
          date1: string;
        }
      | {
          frequency: PayFrequency.ONCE_PER_MONTH;
          date1: string;
        }
      | {
          frequency: PayFrequency.TWICE_PER_MONTH;
          date1: string;
          date2: string;
        };
    compensation?: {
      includePointsFrom: EIncludePointsFrom[];
    };
    checklist?: {
      allowTasksToBeDisplayedOnlyDuringCertainHours: boolean;
      requireManagerToInitiateTimeAttack: boolean;
      showLastCompleteInTasksForAudit: boolean;
      showProgressBars: boolean;
    };
    dailySurvey?: {
      dailySurveyMode: EDailySurveyMode;
      dailySurveyPNTime: number;
      frequency: EDailySurveyFrequency;
      date1: string;
      date2: string | null;
    };
    recognition?: {
      encourageManagersAndAdminsToRecognizeSomeone: boolean;
      encourageManagersAndAdminsNumberOfDays: number;
      encourageNormalUsersToRecognizeSomeone: boolean;
      encourageNormalUsersNumberOfDays: number;
      encourageManagersAndAdminsPN: boolean;
      encourageManagersAndAdmins: string[];
      encourageManagersAndAdminsTime: string;
      encourageNormalUsersPN: boolean;
      encourageNormalUsers: string[];
      encourageNormalUsersTime: string;
    };
    tags?: {
      pickupDateFieldName: string;
      customerNameEnabled: boolean;
      defaultStatusName: string;
      readyForPickupStatusName: string;
      completedStatusName: string;
      reminder1stStatusName: string;
      reminder2ndStatusName: string;
    };
    announcements?: {
      whoCanSendAnnouncements: UserRoles[];
    };
    suggestions?: {
      notifyUsersOnNewOpportunities: string[];
      notifyUsersOnNewSolutions: string[];
      notifyUsersOnOpportunityStatusChange: string[];
    };
    dailyReport?: {
      dailyReportEnabled: boolean;
      dailyReportTime: string;
    };
  };
  pnFlags?: {
    isAdminsReceiveNotificationsWhenAnyUserIsRecognized: boolean;
    isManagersReceiveNotificationsWhenAnyUserIsRecognized: boolean;
    isUsersReceiveNotificationsForAnnouncements: boolean;
  };
  emailFlags?: {
    isSendingSupervisorEmailsRegardingLedger: boolean;
    isSendingLedgerEmailsEnabled: boolean;
    isSendingDailySurveyEmailsEnabled: boolean;
  };
  timezone?: string;
  loginRedirectsTo?: string;
  passcodeRedirectsTo?: string;
  passcodeTimeout?: number;
}

export enum EIncludePointsFrom {
  tasksCompleted = "tasksCompleted",
  pointsSent = "pointsSent",
  pointsReceived = "pointsReceived"
}
export enum EDailySurveyMode {
  OFF = "OFF",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  REQUIRED = "REQUIRED"
}
export enum EDailySurveyFrequency {
  EVERY_DAY = "EVERY_DAY",
  EVERY_WEEK = "EVERY_WEEK",
  EVERY_TWO_WEEKS = "EVERY_TWO_WEEKS",
  ONCE_PER_MONTH = "ONCE_PER_MONTH"
}
export enum UserRoles {
  Admin = "Admin",
  Manager = "Manager"
}
/**
 * This interface was referenced by `TSpecBodyPatchTenant`'s JSON-Schema
 * via the `definition` "PayFrequency".
 */
export enum PayFrequency {
  EVERY_WEEK = "EVERY_WEEK",
  EVERY_TWO_WEEKS = "EVERY_TWO_WEEKS",
  ONCE_PER_MONTH = "ONCE_PER_MONTH",
  TWICE_PER_MONTH = "TWICE_PER_MONTH"
}

export interface TSpecQueryPatchTenant {
  AppVersion?: string;
  AppUser?: string;
}
