// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_parseFile = () => "/compensation/parse-file";

export type TSpecHandler_parseFile = RequestHandler<TSpecPathParseFile, any, TSpecBodyParseFile, TSpecQueryParseFile, {}>;

export interface TSpecPathParseFile {  }

export interface TSpecBodyParseFile {}

export interface TSpecQueryParseFile {
  AppVersion?: string;
  AppUser?: string;
}
