// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_deleteBackdoorInfo = (path: TSpecPathDeleteBackdoorInfo) => {
  let res = "/backdoor/info/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_deleteBackdoorInfo = RequestHandler<TSpecPathDeleteBackdoorInfo, any, TSpecBodyDeleteBackdoorInfo, TSpecQueryDeleteBackdoorInfo, {}>;

export interface TSpecPathDeleteBackdoorInfo { id: string; }

export interface TSpecBodyDeleteBackdoorInfo {}

export interface TSpecQueryDeleteBackdoorInfo {
  AppVersion?: string;
  AppUser?: string;
}
