<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="close()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Notifications</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="save()"
        color="primary"
        appHaptics
        [disabled]="result.length === 0"
        [disableHaptics]="result.length === 0"
      >
        Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-searchbar (ionInput)="searchbarInput($event)" />
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [isModal]="true" [isFullHeight]="true">
    <ion-list *ngIf="tasks.length">
      <ion-item class="table-head">
        <div class="table-head-col">
          <ion-label>Task</ion-label>
        </div>
        <div class="table-head-col">
          <ion-label>Notify upon completion</ion-label>
        </div>
      </ion-item>
      <ion-item>
        <ion-checkbox
          [checked]="isAllNotificationChecked"
          [indeterminate]="isNotificationIndeterminate"
          (ionChange)="allNotificationChange($event)"
          appHaptics
          [disabled]="filter.length > 0"
          [disableHaptics]="filter.length > 0"
        >
          All Tasks
        </ion-checkbox>
      </ion-item>
      <ion-item
        *ngFor="let task of tasks | filterTasks : filter; trackBy: trackItems"
      >
        <ion-checkbox
          [checked]="task.isNotificationUponCompletion"
          (ionChange)="onNotificationChange($event, task)"
          appHaptics
        >
          {{ task.title }}
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </app-full-height-container>
</ng-template>
