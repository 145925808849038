// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_getOpportunityById = (path: TSpecPathGetOpportunityById) => {
  let res = "/suggestions/opportunities/:opportunityId";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_getOpportunityById = RequestHandler<TSpecPathGetOpportunityById, any, TSpecBodyGetOpportunityById, TSpecQueryGetOpportunityById, {}>;

export interface TSpecPathGetOpportunityById { opportunityId: string; }

export interface TSpecBodyGetOpportunityById {}

export interface TSpecQueryGetOpportunityById {
  AppVersion?: string;
  AppUser?: string;
}
