import { NgModule } from '@angular/core';
import { SkillMatrixForUserModalComponent } from './skill-matrix-for-user-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { SkillMatrixSelectModule } from '@app/modules/skill-matrix-status-select/skill-matrix-status-select.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { TaskSelectorModalModule } from '../task-selector-modal/task-selector-modal.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    FullHeightContainerModule,
    SkillMatrixSelectModule,
    TaskSelectorModalModule
  ],
  exports: [SkillMatrixForUserModalComponent],
  declarations: [SkillMatrixForUserModalComponent]
})
export class SkillMatrixForUserModalModule {}
