<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Compensation </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-select
          labelPlacement="floating"
          aria-label="Pay Frequency"
          interface="popover"
          label="Pay Frequency"
          [value]="frequency"
          (ionChange)="onFrequencyChange($any($event))"
          appHaptics
        >
          <ion-select-option [value]="payFrequency.EVERY_WEEK">
            Every week
          </ion-select-option>
          <ion-select-option [value]="payFrequency.EVERY_TWO_WEEKS">
            Every two weeks
          </ion-select-option>
          <ion-select-option [value]="payFrequency.ONCE_PER_MONTH">
            Once per month
          </ion-select-option>
          <ion-select-option [value]="payFrequency.TWICE_PER_MONTH">
            Twice per month
          </ion-select-option>
        </ion-select>
      </ion-item>
      <app-date-form-control
        *ngIf="date1"
        [isItem]="true"
        [itemLines]="!!date2"
        label="Next pay-period begins on"
        [(ngModel)]="date1"
        [dateTransformer]="frequency | transformPayrollFrequency"
        (ngModelChange)="savePayroll()"
        [min]="dateMin"
        [max]="dateMax"
      />
      <app-date-form-control
        *ngIf="date2"
        [isItem]="true"
        label="Second pay-period begins on"
        [(ngModel)]="date2"
        [dateTransformer]="frequency | transformPayrollFrequency"
        (ngModelChange)="savePayroll()"
        [min]="dateMin"
        [max]="dateMax"
      />
    </ion-list>

    <div *ngIf="tenantFeatures.calculateRewards">
      <app-list-pre-line
        title="Compensation Pools"
        [showAddButton]="true"
        (addClicked)="addCompensationPool()"
      />
      <ion-list [inset]="true">
        <ion-item
          *ngFor="
            let compensationPool of compensationPools;
            trackBy: trackCompensationPools
          "
          button
          (click)="updateCompensationPool(compensationPool)"
          appHaptics
        >
          <ion-label>{{ compensationPool.name }}</ion-label>
        </ion-item>
      </ion-list>

      <ion-list [inset]="true">
        <ion-item>
          <ion-select
            [multiple]="true"
            label="When calculating rewards, include points from"
            labelPlacement="floating"
            appHaptics
            [value]="compensationSettings.includePointsFrom"
            (ionChange)="onIncludePointsFromChange($any($event))"
          >
            <ion-select-option value="tasksCompleted">
              Tasks
            </ion-select-option>
            <ion-select-option value="pointsSent">
              Recognition Sent
            </ion-select-option>
            <ion-select-option value="pointsReceived">
              Recognition Received
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <app-list-pre-line
        title="Table Columns for Hours from Payroll Provider"
        [showAddButton]="true"
        (addClicked)="addCompensationField()"
      />
      <ion-list [inset]="true">
        <ion-item
          *ngFor="
            let tableField of onlyHoursCompensationFields;
            trackBy: trackCompensationFields
          "
          button
          (click)="updateCompensationField(tableField)"
          appHaptics
        >
          <ion-label>{{ tableField.name }}</ion-label>
        </ion-item>
      </ion-list>

      <ion-list [inset]="true">
        <ion-item>
          <ion-input
            label="Title of Employee ID Column"
            labelPlacement="floating"
            [value]="compensationEmployeeIdColumnName"
            appHaptics
            [debounce]="700"
            (ionInput)="onCompensationEmployeeIdColumnNameInput($any($event))"
          />
        </ion-item>
      </ion-list>
    </div>
  </app-full-height-container>
</ion-content>

<ion-loading #loading message="Loading..." />
