<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="askGoBack()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline" />
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ this.announcement ? 'Edit Announcement' : 'Create Announcement' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [withVerticalPadding]="true">
    <ion-list [inset]="true">
      <ion-item button (click)="selectUsers()" appHaptics>
        <ion-label>Recipients</ion-label>
        <ion-note slot="end">{{ formattedRecipients || 'Select' }}</ion-note>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          labelPlacement="floating"
          label="Subject"
          [(ngModel)]="subject"
          autocapitalize="on"
          [spellcheck]="true"
        />
      </ion-item>
    </ion-list>

    <app-wysiwyg-editor
      [(value)]="body"
      [disableAttachments]="disableAttachments"
    />

    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle [(ngModel)]="isCritical" appHaptics>
          <ion-label>Critical</ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>
    <app-list-post-line
      text="Require to acknowledge the announcement prior to gaining access to the app"
    />

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        (click)="askPublish()"
        [disabled]="!canNextStep"
        appHaptics
        [disableHaptics]="!canNextStep"
        expand="block"
        class="ion-no-margin"
      >
        {{ announcement ? 'Save' : 'Publish' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
