<ion-content class="content">
  <ion-header class="ion-no-border">
    <ion-toolbar class="app-common-background announcement-preview-toolbar">
      <ion-buttons slot="start">
        <ion-button (click)="onCancelClicked()" color="primary" appHaptics>
          Cancel
        </ion-button>
      </ion-buttons>
      <ion-title class="ion-text-capitalize">
        {{ announcement?.title }}
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <app-full-height-container [isFullHeight]="true">
    <div class="announcement-view__info">
      <div class="announcement-view__header">
        <h2 class="announcement-view__subheading">
          <span>
            {{ announcement ? fDateAndTime(announcement.date) : '' }} |
            {{ announcement?.authorName }}
          </span>
          <span>{{ editedString }}</span>
        </h2>
      </div>
      <div class="app-divider-line"></div>
    </div>

    <app-wysiwyg-editor *ngIf="announcement" [value]="body" mode="view" />

    <app-announcement-thread [announcements]="thread" />

    <div bottom class="modal-actions" *ngIf="announcement">
      <ion-button
        *ngIf="announcement.actions !== announcementActions.ONLY_RESPOND"
        color="dark"
        (click)="acknowledge()"
        appHaptics
        expand="block"
        [disabled]="announcementIndex === -1"
        [disableHaptics]="announcementIndex === -1"
        class="ion-no-margin"
      >
        {{
          announcement.acknowledgeButtonText ===
          announcementAcknowledgeButtonText.UNDERSTAND_AND_AGREE
            ? 'Understand and Agree'
            : 'Acknowledge'
        }}
      </ion-button>
      <ion-button
        *ngIf="
          announcement.actions !== announcementActions.ONLY_ACKNOWLEDGE &&
          announcement.author
        "
        color="dark"
        (click)="respond()"
        appHaptics
        expand="block"
        fill="outline"
        [disabled]="announcementIndex === -1"
        [disableHaptics]="announcementIndex === -1"
        class="ion-no-margin"
      >
        {{ respondButtonText }}
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
