// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_editAnnouncement = (path: TSpecPathEditAnnouncement) => {
  let res = "/announcements/:id";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_editAnnouncement = RequestHandler<TSpecPathEditAnnouncement, any, TSpecBodyEditAnnouncement, TSpecQueryEditAnnouncement, {}>;

export interface TSpecPathEditAnnouncement { id: string; }

export interface TSpecBodyEditAnnouncement {
  title: string;
  body: string;
  isCritical: boolean;
  recipients: {
    id: string;
  }[];
  attachments: string[];
  keepStats?: boolean;
}

export interface TSpecQueryEditAnnouncement {
  AppVersion?: string;
  AppUser?: string;
}
