import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { HapticsService } from '@app/core/service/haptics.service';

@Component({
  selector: 'app-performance-duration-form-control',
  templateUrl: './performance-duration-form-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PerformanceDurationFormControlComponent
    }
  ]
})
export class PerformanceDurationFormControlComponent
  implements ControlValueAccessor
{
  @Input()
  public label: string;

  protected value: string | null;

  private _onChange = (_value: string) => {};

  private _onTouched = () => {};

  protected disabled = false;

  protected get formattedValue(): string {
    const [hours, minutes] = this.value.split(':');
    let res = `${minutes} min`;
    if (Number.parseInt(hours) > 0) {
      res = `${hours} h   ${res}`;
    }
    return res;
  }

  constructor(
    private readonly _pickerCtrl: PickerController,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _hapticsService: HapticsService
  ) {}

  public writeValue(value: string) {
    this.value = value;
    this._cdr.markForCheck();
  }

  public registerOnChange(onChange: any) {
    this._onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this._onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  async openPicker() {
    let hoursIndex;
    let minutesIndex;

    if (this.value) {
      const [hours, minutes] = this.value.split(':');
      hoursIndex = Number.parseInt(hours);
      minutesIndex = Number.parseInt(minutes) / 5;
    }

    const picker = await this._pickerCtrl.create({
      columns: [
        {
          name: 'hours',
          options: [
            ...new Array(25)
              .fill(null)
              .map((_, i) => ({ value: i, text: `${i} h` }))
          ],
          selectedIndex: hoursIndex
        },
        {
          name: 'minutes',
          options: [
            ...new Array(12)
              .fill(null)
              .map((_, i) => ({ value: i * 5, text: `${i * 5} min` }))
          ],
          selectedIndex: minutesIndex
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Done',
          handler: (value) => {
            this._hapticsService.hapticsImpact();
            this.value =
              value.hours.value === 0 && value.minutes.value === 0
                ? null
                : `${value.hours.value}:${value.minutes.value}`;

            this._onChange(this.value);
            this._onTouched();
            this._cdr.markForCheck();
          }
        }
      ]
    });
    picker.present();

    const { role } = await picker.onWillDismiss();

    if (role === 'cancel') {
      this._hapticsService.hapticsImpact();
    }
  }
}
