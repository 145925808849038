// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_addLibraryDoc = () => "/library/docs";

export type TSpecHandler_addLibraryDoc = RequestHandler<TSpecPathAddLibraryDoc, any, TSpecBodyAddLibraryDoc, TSpecQueryAddLibraryDoc, {}>;

export interface TSpecPathAddLibraryDoc {  }

export interface TSpecBodyAddLibraryDoc {
  originalDoc: string;
  version: number;
  tenant: string;
  userCreated: string | null;
  dateCreated: string;
  isDeleted: boolean;
  signed: {
    user: string;
    date: string;
  }[];
}

export interface TSpecQueryAddLibraryDoc {
  AppVersion?: string;
  AppUser?: string;
}
