// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_setTaskPerformance = (path: TSpecPathSetTaskPerformance) => {
  let res = "/tasks/:id/performance";
  Object.entries(path).map(([key, value]) => {
    res = res.replace(':' + key, value);
  });
  return res;
};

export type TSpecHandler_setTaskPerformance = RequestHandler<TSpecPathSetTaskPerformance, any, TSpecBodySetTaskPerformance, TSpecQuerySetTaskPerformance, {}>;

export interface TSpecPathSetTaskPerformance { id: string; }

export interface TSpecBodySetTaskPerformance {
  quantity?: number;
  startTime?: string | null;
  completeTime?: string;
  status: TaskPerformanceStatus;
  taskAppearTime: string;
  note?: {
    body: string;
    attachments: string[];
  };
  user: string;
  isLastCriticalTask?: boolean;
}

export enum TaskPerformanceStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  COMPLETE = "COMPLETE",
  SKIPPED = "SKIPPED"
}

export interface TSpecQuerySetTaskPerformance {
  AppVersion?: string;
  AppUser?: string;
}
